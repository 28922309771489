import { useEffect } from "react"
import { message } from "antd"
import { observer, useLocalObservable } from "mobx-react-lite"
import errorCodeStores from "../../stores/errCodeStores"

const MessageTips = () => {
    const errCodeStores = useLocalObservable(() => errorCodeStores)
    const [messageApi, contextHolder] = message.useMessage();
    
    useEffect(() => {
        if (errCodeStores.errorCode) {
            messageApi.open({
                type: 'error',
                content: 'This is an error message',
                duration: 5,
            });
        }
    }, [errCodeStores.errorCode, messageApi])
    return <>
        {contextHolder}
    </>
}

export default observer(MessageTips);