import { makeAutoObservable } from "mobx";

export interface IThemeStoresType {}

class ThemeStores implements IThemeStoresType {
    public theme: string = "dark"
    constructor() {
        makeAutoObservable(this);
    }

    public changeTheme = (theme: string) => {
        this.theme = theme;
    }
}

const themeStores = new ThemeStores();
export default themeStores;