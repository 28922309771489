import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
// import wsStores from "../../stores/wsStores";
// import { sendGuestLoginToServer } from "../../api/requst";
import MessageTips from "../../components/messageTips/MessageTips";
import lcss from "./layout.module.css";

const LayoutFrame = () => {
    // useEffect(() => {
    //     wsStores.initWs().then((res) => {
    //         if (res === 'ok') {
    //             sendGuestLoginToServer({deviceId: 'f6453451a9c14280'})
    //         }
    //     })
    // }, []);
    return (
        <Suspense fallback={<span>Loading...</span>}>
            <MessageTips />
            <div className={lcss.aContainer} id="App-container">
                <Outlet />
            </div>
        </Suspense>
    );
};

export default LayoutFrame;
