import tradeStores, {ITradeStoresType} from "./tradeStores";
import themeStores, {IThemeStoresType} from "./themeStores";
import userStores, {IUserStoresType} from "./userStores";

class RootStores {
    public tradeStores: ITradeStoresType;
    public themeStores: IThemeStoresType;
    public userStores: IUserStoresType;
    constructor() {
        this.tradeStores = tradeStores;
        this.themeStores = themeStores;
        this.userStores = userStores;
    }
}

const rootStores = new RootStores();
export default rootStores