import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import { lineInitialData } from "../components/charts/config/lineData";
import { msg as msg_hall } from "../tools/proto/msg_hall_client";
import userStores from "./userStores";
import {
    sentInstrumentHistoryToServer,
    sentBtcxHistoryToServer,
    sendBtcxCandleInfosToServer,
    // sendBitxCandleInfoToServer,
    sendInstrumentCandleRecordToServer,
} from "../api/requst";
import {
    formatCandleHisTimeInterval,
    formatDataBySecond,
} from "../tools/utils/chattools";
dayjs.extend(utc);

export interface ITradeStoresType {
    getTradeBtcxSettlementData(data: any): unknown;
    getTradeBtxData(data: any): unknown;
    getTradeBtcxStartData(data: any): unknown;
    getInstrumentRecordData(data: any): unknown;
    getInstrumentData(data: any): unknown;
    getTrendRecordData(data: any): unknown;
    getBitxInfoData(data: any): unknown;
    getBitxCandleRecordData(data: any): unknown;
    getBitxCandleInfoData(data: any): unknown;
    getInstrumentCandleRecordData(data: any): unknown;
}

class TradeStores implements ITradeStoresType {
    public chartType: string = "";
    public mt4Type: string | number = 4000;
    public cckTimer: number = 1;
    public realTrendData: any = null; // 走势图数据
    public trendRecord: any = []; // 走势图数据历史
    public tradeBitxInfo: any = null; // 下单点数据
    public tradeBitxInfoRes: any = null; // 下单点数据结算结果
    public holdTrendRecord: any = []; // 走势图数据-持仓历史
    public realCckData: any = []; // K线走势数据
    public realCandleInfo: any = null; // K线实时数据
    public cckRecord: any = []; // K线走势数据历史
    public takeTradeBitxs: any = []; // 持仓数据
    public winTradeBitxs: any = []; // 交易赢的集合
    public timeCharts: any = []; // 交易时间
    public loading: boolean = true;
    public tradeDisabled: boolean = false;
    public tradePrice: number = 10;
    private lastCandle: any = null;
    private lastCandleTime: number = 0;
    public showCkInfo: any = null;
    private showCkInfoTime: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    changeTradePrice = (price: number) => {
        this.tradePrice = price;
    };

    changetradeBtnDisabled = (disabled: boolean) => {
        this.tradeDisabled = disabled;
    };

    changeChartType = async (type: string) => {
        // if (this.chartType === type) {
        //     return;
        // }
        this.chartType = type;
        this.showCkInfoTime = "";
        this.showCkInfo = null;
        this.loading = true;
        if (type === "k") {
            if (this.mt4Type == "4000") {
                const params = this.sendBitxCandleInfos(this.cckTimer);
                await sendBtcxCandleInfosToServer(params);
            } else {
                const params = this.sendBitxCandleInfos(this.cckTimer);
                await sendInstrumentCandleRecordToServer({
                    ...params,
                    instId: this.mt4Type,
                });
            }
        } else {
            if (this.mt4Type == "4000") {
                await sentBtcxHistoryToServer({ count: 2000 });
            } else {
                await sentInstrumentHistoryToServer({ instId: this.mt4Type });
            }
        }
    };

    changeMt4Type = async (type: string | number) => {
        console.log("===type:", type);
        if (!type) return;
        if (this.mt4Type == type) {
            this.loading = false;
            return;
        }
        this.mt4Type = type;
        this.loading = true;
        //init data
        this.realTrendData = null;
        this.trendRecord = [];
        this.realCandleInfo = null;
        this.cckRecord = [];
        this.lastCandleTime = 0;
        this.lastCandle = null;
        //
        if (type == 4000) {
            this.tradeDisabled = false;
            userStores.changeTradeState(true);
            if (!this.chartType) {
                await sentBtcxHistoryToServer({ count: 2000 });
            } else {
                const params = this.sendBitxCandleInfos(this.cckTimer);
                await sendBtcxCandleInfosToServer({ ...params, instId: type });
            }
        } else {
            this.tradeDisabled = true;
            userStores.changeTradeState(false);
            if (!this.chartType) {
                await sentInstrumentHistoryToServer({ instId: type });
            } else {
                const params = this.sendBitxCandleInfos(this.cckTimer);
                await sendInstrumentCandleRecordToServer({
                    ...params,
                    instId: type,
                });
            }
        }
    };

    changeShowCkInfo = async (data: any) => {
        if (!data) {
            return;
        }
        if (this.showCkInfoTime !== data.time) {
            this.showCkInfoTime = data.time;
            this.showCkInfo = data;
        }
    };

    changeCckTimer = async (type: string) => {
        if (this.cckTimer === Number(type)) {
            return;
        }
        this.loading = true;
        this.cckTimer = Number(type);
        const params = this.sendBitxCandleInfos(Number(type));
        if (this.mt4Type == 4000) {
            await sendBtcxCandleInfosToServer({
                ...params,
                instId: this.mt4Type,
            });
        } else {
            await sendInstrumentCandleRecordToServer({
                ...params,
                instId: this.mt4Type,
            });
        }
    };

    sendBitxCandleInfos = (interval: number) => {
        const obj = formatCandleHisTimeInterval(interval);
        const params = {
            ...obj,
            interval: interval,
        };
        return params;
    };

    getTrendRecordData = (payload: any) => {
        if (!payload) {
            this.trendRecord = [];
        }
        const data = msg_hall.FindBtcxHistoryA.deserialize(payload).toObject();
        if (data && data.btcxInfos?.length) {
            if (this.mt4Type == "4000") {
                this.trendRecord = formatDataBySecond(data.btcxInfos);
                this.timeCharts = this.trendRecord.map((item: any) => {
                    return { time: item.time };
                });
            }
            this.loading = false;
        }
        console.log("====----:", data);
        if (data && data.tradeInfos?.length) {
            this.takeTradeBitxs = data.tradeInfos.map((item: any) => {
                return {
                    ...item,
                    tradeTime: dayjs
                        .utc(item.startTime as string)
                        .local()
                        .valueOf(),
                };
            });
        }
    };

    getInstrumentRecordData = (payload: any) => {
        if (!payload) return;
        const data =
            msg_hall.FindInstrumentHistoryA.deserialize(payload).toObject();
        if (data && data.instruments?.length && this.mt4Type != "4000") {
            this.trendRecord = [];
            this.trendRecord = data.instruments.reduce(
                (cur: any, next: any) => {
                    const time = Math.floor(next.timestamp / 1000) * 1000;
                    const index = cur.findIndex(
                        (item: any) => item.time === time
                    );
                    if (index === -1) {
                        cur.push({
                            time,
                            value: next.buyPrice * 100,
                        });
                    } else {
                        cur[index].value = next.buyPrice * 100;
                    }
                    return cur;
                },
                []
            );
            this.loading = false;
        }
    };

    getBitxCandleRecordData = (payload: any) => {
        if (!payload) return;

        const data =
            msg_hall.FindBtcxCandleInfosA.deserialize(payload).toObject();
        // console.log("getBitxCandleRecordData:", data.infos);
        if (data.errCode === 0 && data.infos?.length) {
            // let obj = {}
            this.cckRecord = data.infos?.reduce((pre: any, cur: any, index: number) => {
                    // const isExit = pre.find(
                    //     // (item: any) => item.time === (new Date(cur.startTime).getTime() / 1000) * 1000
                    //     (item: any) =>
                    //         item.time ===
                    //         dayjs.utc(cur.startTime).local().valueOf()
                    // );
                    // if (!isExit) {
                        return [
                            ...pre,
                            {
                                // time: new Date(cur.startTime).getTime(),
                                time: dayjs
                                    .utc(cur.startTime)
                                    .local()
                                    .valueOf(),
                                close: cur.closePrice,
                                open: cur.openPrice,
                                low: cur.lowestPrice,
                                high: cur.highestPrice,
                            },
                        ];
                    // }
                    // return pre;
            }, []);
            const last = data.infos[data.infos.length - 1];
            if (last) {
                // this.lastCandleTime = dayjs(
                //     dayjs(last.endTime).add(this.cckTimer, "m")
                // ).valueOf();
                this.lastCandleTime = dayjs.utc(last.endTime).local().valueOf();
                // console.log('this.lastCandleTime00_:', this.lastCandleTime)
                this.lastCandle = {
                    time: dayjs.utc(last.startTime).local().valueOf(),
                    close: last.closePrice,
                    open: last.openPrice,
                    low: last.lowestPrice,
                    high: last.highestPrice,
                };
            }
            // console.log('============02')
            this.loading = false;
        }
    };

    /**
     * la zhu tu shi shi shu ju--> btcx
     * @param payload
     */
    getBitxCandleInfoData = (payload: any) => {
        if (!payload) return;

        const data =
            msg_hall.GetLatestBtcxCandleInfoA.deserialize(payload).toObject();
        if (data.errCode === 0 && data.info) {
            const info = data.info;
            const startTimestamp = new Date(info.startTime as string).getTime();
            if (this.lastCandle.time === startTimestamp) {
                // this.realCandleInfo = {
                //     time: new Date(info.startTime as string).getTime(),
                //     close: info.closePrice,
                //     open: this.lastCandle.open,
                //     low: Math.min(Number(info.lowestPrice), this.lastCandle.low),
                //     high: Math.max(Number(info.highestPrice), this.lastCandle.high),
                // }
            } else {
                // this.lastCandle = {
                //     time: new Date(info.startTime as string).getTime(),
                //     close: info.closePrice,
                //     open: info.openPrice,
                //     low: info.lowestPrice,
                //     high: info.highestPrice,
                // }
                // this.realCandleInfo = {
                //     time: new Date(info.startTime as string).getTime(),
                //     close: info.closePrice,
                //     open: info.openPrice,
                //     low: info.lowestPrice,
                //     high: info.highestPrice,
                // }
            }
        }
        // console.log('==--=-=---getBitxCandleInfoData02:', JSON.stringify(this.realCandleInfo))
    };

    getBitxInfoData = (payload: any) => {
        const data = msg_hall.SendBitxInfoN.deserialize(payload).toObject();
        if (data && data.bitxInfo) {
            const info = data.bitxInfo;
            if (this.mt4Type == "4000") {
                if (this.chartType === "k") {
                    if (
                        info.timestamp &&
                        this.lastCandleTime >= info.timestamp
                    ) {
                        this.realCandleInfo = {
                            time: this.lastCandle.time,
                            close: info.price,
                            open: this.lastCandle.open,
                            low: Math.min(
                                Number(info.price),
                                this.lastCandle.low
                            ),
                            high: Math.max(
                                Number(info.price),
                                this.lastCandle.high
                            ),
                        };
                        this.lastCandle = this.realCandleInfo;
                    } else {
                        this.lastCandle = {
                            time: this.lastCandleTime,
                            close: info.price,
                            open: info.price,
                            low: info.price,
                            high: info.price,
                        };
                        this.lastCandleTime = dayjs(this.lastCandleTime)
                            .add(this.cckTimer, "m")
                            .valueOf();
                    }
                } else {
                    this.realTrendData = {
                        time:
                            Math.floor(
                                Number(data.bitxInfo?.timestamp) / 1000
                            ) * 1000,
                        value: data.bitxInfo?.price,
                    };
                }
                // this.realTrendData = this.chartType === 'k' ? {
                //     time: new Date(info.startTime as string).getTime(),
                //     close: info.closePrice,
                //     open: info.openPrice,
                //     low: info.lowestPrice,
                //     high: info.highestPrice,
                // } :
                // this.realTrendData =  {
                //     time:
                //         Math.floor(Number(data.bitxInfo?.timestamp) / 1000) * 1000,
                //     value: data.bitxInfo?.price,
                // };
            }
        }
    };

    getInstrumentData = (payload: any) => {
        const data = msg_hall.SendInstrumentN.deserialize(payload).toObject();
        // console.log('getInstrumentData:', data)
        if (data && data.instrument) {
            const info = data.instrument;
            if (this.mt4Type == info.id) {
                if (this.chartType === "k") {
                    if (
                        info.timestamp &&
                        this.lastCandleTime >= info.timestamp
                    ) {
                        this.realCandleInfo = {
                            time: this.lastCandle.time,
                            close: info.buyPrice,
                            open: this.lastCandle.open,
                            low: Math.min(
                                Number(info.buyPrice),
                                this.lastCandle.low
                            ),
                            high: Math.max(
                                Number(info.buyPrice),
                                this.lastCandle.high
                            ),
                        };
                        this.lastCandle = this.realCandleInfo;
                    } else {
                        // this.lastCandleTime = dayjs.utc(dayjs(info.timestamp).add(this.cckTimer, "m")).local().valueOf();
                        if (info.timestamp) {
                            this.lastCandle = {
                                time: this.lastCandleTime,
                                close: info.buyPrice,
                                open: info.buyPrice,
                                low: info.buyPrice,
                                high: info.buyPrice,
                            };
                            this.lastCandleTime = dayjs
                                .utc(
                                    dayjs(this.lastCandleTime).add(
                                        this.cckTimer,
                                        "m"
                                    )
                                )
                                .local()
                                .valueOf();
                        }
                    }
                    // console.log('7-->this.realCandleInfo:', JSON.stringify(this.realCandleInfo))
                } else {
                    this.realTrendData = {
                        time: Math.floor(Number(info.timestamp) / 1000) * 1000,
                        value: info.buyPrice,
                    };
                }
            }
        }
    };

    getTradeBtxData = (payload: any) => {
        if (!payload) return;
        const data = msg_hall.TradeBtxA.deserialize(payload).toObject();
        console.log("-----下订单扣费-getTradeBtxData:", data);
        if (data.errCode === 0) {
            if (userStores.userInfo.isDemo) {
                userStores.changeDemoAmount(data.curAmount as number);
            } else {
                userStores.changeRealAmount(data.curAmount as number);
            }
        }
    };

    getTradeBtcxStartData = (payload: any) => {
        if (!payload) return;
        const data = msg_hall.TradeBtcxStartN.deserialize(payload).toObject();
        console.log("--下单点-getTradeBtcxStartData:", data);
        if (data && data.tradeInfo) {
            this.tradeBitxInfo = {
                ...data.tradeInfo,
                // tradeTime: new Date(
                //     data.tradeInfo.startTime as string
                // ).getTime(),
                tradeTime: dayjs
                    .utc(data.tradeInfo.startTime as string)
                    .local()
                    .valueOf(),
            };
            // if (this.takeTradeBitxs.length < 5) {
            this.takeTradeBitxs.push({
                ...data.tradeInfo,
                tradeTime: dayjs
                    .utc(data.tradeInfo.startTime as string)
                    .local()
                    .valueOf(),
                // tradeTime: new Date(
                //     data.tradeInfo.startTime as string
                // ).getTime(),
                // tradeTime: dayjs(data.tradeInfo.startTime).valueOf(),
            });
            console.log("this.takeTradeBitxs.push:", this.takeTradeBitxs);
            // }
        }
    };

    getTradeBtcxSettlementData = (payload: any) => {
        if (!payload) return;
        const data =
            msg_hall.TradeBtcxSettlementN.deserialize(payload).toObject();
        console.log("--结算-getTradeBtcxSettlementData:", data);
        if (data) {
            this.tradeBitxInfoRes = data;
            if (userStores.userInfo.isDemo) {
                userStores.changeDemoAmount(data.curDemoAmount as number);
            } else {
                userStores.changeRealAmount(data.curAmount as number);
            }
            const lists = this.takeTradeBitxs.filter(
                (item: any) => item.id !== data.tradeInfoId
            );
            this.takeTradeBitxs = lists;
            if (data.result === 1 && this.winTradeBitxs.length < 5) {
                this.winTradeBitxs.push(data);
            }
        }
    };
    /**
     * k-in-data
     */
    getInstrumentCandleRecordData = (payload: any) => {
        if (!payload) return;
        this.cckRecord = [];
        const data =
            msg_hall.FindInstrumentCandleInfosA.deserialize(payload).toObject();
        if (data.errCode === 0 && data.infos?.length) {
            console.log("getInstrumentCandleRecordData-7个产品:", data.infos);
            this.cckRecord = data.infos.reduce((unique: any, item: any) => {
                return unique.some(
                    (obj: any) => obj.time === dayjs.utc(item.startTime as string).valueOf()
                ) ? unique : [...unique, {
                    time: dayjs
                        .utc(item.startTime as string)
                        .valueOf(),
                    close: item.closePrice * 100,
                    open: item.openPrice * 100,
                    low: item.lowestPrice * 100,
                    high: item.highestPrice * 100,
                }];
            }, []);
            // this.cckRecord = data.infos?.reduce(
            //     (pre: any, cur: any, index: number) => {
            //         const isExit = pre.find(
            //             (item: any) =>
            //                 item.time ===
            //                 dayjs.utc(item.startTime as string).valueOf()
            //         );
            //         if (!isExit) {
            //             return [
            //                 ...pre,
            //                 {
            //                     time: dayjs
            //                         .utc(cur.startTime as string)
            //                         .valueOf(),
            //                     close: cur.closePrice * 100,
            //                     open: cur.openPrice * 100,
            //                     low: cur.lowestPrice * 100,
            //                     high: cur.highestPrice * 100,
            //                 },
            //             ];
            //         }
            //         return pre;
            //     },
            //     []
            // );
            console.log("=======this.cckRecord:", this.cckRecord);
            const last = data.infos[data.infos?.length - 1];
            if (last) {
                // this.lastCandleTime = dayjs.utc(
                //     dayjs(last.endTime).add(this.cckTimer, "m")
                // ).local().valueOf();
                this.lastCandleTime = dayjs
                    .utc(last.endTime as string)
                    .valueOf();
                this.lastCandle = {
                    time: dayjs.utc(last.startTime as string).valueOf(),
                    close: last.closePrice,
                    open: last.openPrice,
                    low: last.lowestPrice,
                    high: last.highestPrice,
                };
            }
            this.loading = false;
        }
    };

    changeWinTradeBitxs = () => {
        if (this.winTradeBitxs && this.winTradeBitxs.length) {
            this.winTradeBitxs.pop();
        }
    };
}

const tradeStores = new TradeStores();
export default tradeStores;
