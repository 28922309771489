/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.27.2
 * source: msg_hall_client.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./msg_error";
import * as pb_1 from "google-protobuf";
export namespace msg {
    export enum TradeResult {
        NotFinished = 0,
        Win = 1,
        Lose = 2,
        Tie = 3,
        Abnormal = 4
    }
    export enum PersonalInfoDocType {
        Other = 0,
        IdentityCard = 1
    }
    export enum PurchaseChannelId {
        PC_None = 0,
        PC_CaPay = 1,
        PC_SafePay = 2
    }
    export class HeartBeat extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): HeartBeat {
            const message = new HeartBeat({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HeartBeat {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HeartBeat();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): HeartBeat {
            return HeartBeat.deserialize(bytes);
        }
    }
    export class HelloTestR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            hello?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("hello" in data && data.hello != undefined) {
                    this.hello = data.hello;
                }
            }
        }
        get hello() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set hello(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            hello?: string;
        }): HelloTestR {
            const message = new HelloTestR({});
            if (data.hello != null) {
                message.hello = data.hello;
            }
            return message;
        }
        toObject() {
            const data: {
                hello?: string;
            } = {};
            if (this.hello != null) {
                data.hello = this.hello;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hello.length)
                writer.writeString(1, this.hello);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HelloTestR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HelloTestR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.hello = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): HelloTestR {
            return HelloTestR.deserialize(bytes);
        }
    }
    export class HelloTestA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            hello?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("hello" in data && data.hello != undefined) {
                    this.hello = data.hello;
                }
            }
        }
        get hello() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set hello(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            hello?: string;
        }): HelloTestA {
            const message = new HelloTestA({});
            if (data.hello != null) {
                message.hello = data.hello;
            }
            return message;
        }
        toObject() {
            const data: {
                hello?: string;
            } = {};
            if (this.hello != null) {
                data.hello = this.hello;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hello.length)
                writer.writeString(1, this.hello);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HelloTestA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HelloTestA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.hello = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): HelloTestA {
            return HelloTestA.deserialize(bytes);
        }
    }
    export class UserInfoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: number;
            afId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("afId" in data && data.afId != undefined) {
                    this.afId = data.afId;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get afId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set afId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            id?: number;
            afId?: string;
        }): UserInfoR {
            const message = new UserInfoR({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.afId != null) {
                message.afId = data.afId;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: number;
                afId?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.afId != null) {
                data.afId = this.afId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id != 0)
                writer.writeUint64(1, this.id);
            if (this.afId.length)
                writer.writeString(2, this.afId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserInfoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserInfoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readUint64();
                        break;
                    case 2:
                        message.afId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): UserInfoR {
            return UserInfoR.deserialize(bytes);
        }
    }
    export class UserInfoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            user?: User;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("user" in data && data.user != undefined) {
                    this.user = data.user;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get user() {
            return pb_1.Message.getWrapperField(this, User, 2) as User;
        }
        set user(value: User) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_user() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            user?: ReturnType<typeof User.prototype.toObject>;
        }): UserInfoA {
            const message = new UserInfoA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.user != null) {
                message.user = User.fromObject(data.user);
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                user?: ReturnType<typeof User.prototype.toObject>;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.user != null) {
                data.user = this.user.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.has_user)
                writer.writeMessage(2, this.user, () => this.user.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UserInfoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UserInfoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.user, () => message.user = User.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): UserInfoA {
            return UserInfoA.deserialize(bytes);
        }
    }
    export class BitxInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            price?: number;
            timestamp?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("price" in data && data.price != undefined) {
                    this.price = data.price;
                }
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
            }
        }
        get price() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set price(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set timestamp(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            price?: number;
            timestamp?: number;
        }): BitxInfo {
            const message = new BitxInfo({});
            if (data.price != null) {
                message.price = data.price;
            }
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            return message;
        }
        toObject() {
            const data: {
                price?: number;
                timestamp?: number;
            } = {};
            if (this.price != null) {
                data.price = this.price;
            }
            if (this.timestamp != null) {
                data.timestamp = this.timestamp;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.price != 0)
                writer.writeDouble(1, this.price);
            if (this.timestamp != 0)
                writer.writeUint64(2, this.timestamp);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BitxInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BitxInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.price = reader.readDouble();
                        break;
                    case 2:
                        message.timestamp = reader.readUint64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): BitxInfo {
            return BitxInfo.deserialize(bytes);
        }
    }
    export class SendBitxInfoN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            bitxInfo?: BitxInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("bitxInfo" in data && data.bitxInfo != undefined) {
                    this.bitxInfo = data.bitxInfo;
                }
            }
        }
        get bitxInfo() {
            return pb_1.Message.getWrapperField(this, BitxInfo, 1) as BitxInfo;
        }
        set bitxInfo(value: BitxInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_bitxInfo() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            bitxInfo?: ReturnType<typeof BitxInfo.prototype.toObject>;
        }): SendBitxInfoN {
            const message = new SendBitxInfoN({});
            if (data.bitxInfo != null) {
                message.bitxInfo = BitxInfo.fromObject(data.bitxInfo);
            }
            return message;
        }
        toObject() {
            const data: {
                bitxInfo?: ReturnType<typeof BitxInfo.prototype.toObject>;
            } = {};
            if (this.bitxInfo != null) {
                data.bitxInfo = this.bitxInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_bitxInfo)
                writer.writeMessage(1, this.bitxInfo, () => this.bitxInfo.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SendBitxInfoN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SendBitxInfoN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.bitxInfo, () => message.bitxInfo = BitxInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SendBitxInfoN {
            return SendBitxInfoN.deserialize(bytes);
        }
    }
    export class Instrument extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            name?: string;
            sellPrice?: number;
            buyPrice?: number;
            timestamp?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("sellPrice" in data && data.sellPrice != undefined) {
                    this.sellPrice = data.sellPrice;
                }
                if ("buyPrice" in data && data.buyPrice != undefined) {
                    this.buyPrice = data.buyPrice;
                }
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get sellPrice() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set sellPrice(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get buyPrice() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set buyPrice(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set timestamp(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            id?: string;
            name?: string;
            sellPrice?: number;
            buyPrice?: number;
            timestamp?: number;
        }): Instrument {
            const message = new Instrument({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.sellPrice != null) {
                message.sellPrice = data.sellPrice;
            }
            if (data.buyPrice != null) {
                message.buyPrice = data.buyPrice;
            }
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                name?: string;
                sellPrice?: number;
                buyPrice?: number;
                timestamp?: number;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.sellPrice != null) {
                data.sellPrice = this.sellPrice;
            }
            if (this.buyPrice != null) {
                data.buyPrice = this.buyPrice;
            }
            if (this.timestamp != null) {
                data.timestamp = this.timestamp;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.sellPrice != 0)
                writer.writeDouble(3, this.sellPrice);
            if (this.buyPrice != 0)
                writer.writeDouble(4, this.buyPrice);
            if (this.timestamp != 0)
                writer.writeUint64(5, this.timestamp);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Instrument {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Instrument();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.sellPrice = reader.readDouble();
                        break;
                    case 4:
                        message.buyPrice = reader.readDouble();
                        break;
                    case 5:
                        message.timestamp = reader.readUint64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Instrument {
            return Instrument.deserialize(bytes);
        }
    }
    export class SendInstrumentN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            instrument?: Instrument;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("instrument" in data && data.instrument != undefined) {
                    this.instrument = data.instrument;
                }
            }
        }
        get instrument() {
            return pb_1.Message.getWrapperField(this, Instrument, 1) as Instrument;
        }
        set instrument(value: Instrument) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_instrument() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            instrument?: ReturnType<typeof Instrument.prototype.toObject>;
        }): SendInstrumentN {
            const message = new SendInstrumentN({});
            if (data.instrument != null) {
                message.instrument = Instrument.fromObject(data.instrument);
            }
            return message;
        }
        toObject() {
            const data: {
                instrument?: ReturnType<typeof Instrument.prototype.toObject>;
            } = {};
            if (this.instrument != null) {
                data.instrument = this.instrument.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_instrument)
                writer.writeMessage(1, this.instrument, () => this.instrument.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SendInstrumentN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SendInstrumentN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.instrument, () => message.instrument = Instrument.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SendInstrumentN {
            return SendInstrumentN.deserialize(bytes);
        }
    }
    export class TradeBtcxR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            riseOrFall?: number;
            betAmount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("riseOrFall" in data && data.riseOrFall != undefined) {
                    this.riseOrFall = data.riseOrFall;
                }
                if ("betAmount" in data && data.betAmount != undefined) {
                    this.betAmount = data.betAmount;
                }
            }
        }
        get riseOrFall() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set riseOrFall(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get betAmount() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set betAmount(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            riseOrFall?: number;
            betAmount?: number;
        }): TradeBtcxR {
            const message = new TradeBtcxR({});
            if (data.riseOrFall != null) {
                message.riseOrFall = data.riseOrFall;
            }
            if (data.betAmount != null) {
                message.betAmount = data.betAmount;
            }
            return message;
        }
        toObject() {
            const data: {
                riseOrFall?: number;
                betAmount?: number;
            } = {};
            if (this.riseOrFall != null) {
                data.riseOrFall = this.riseOrFall;
            }
            if (this.betAmount != null) {
                data.betAmount = this.betAmount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.riseOrFall != 0)
                writer.writeInt32(1, this.riseOrFall);
            if (this.betAmount != 0)
                writer.writeDouble(2, this.betAmount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TradeBtcxR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TradeBtcxR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.riseOrFall = reader.readInt32();
                        break;
                    case 2:
                        message.betAmount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TradeBtcxR {
            return TradeBtcxR.deserialize(bytes);
        }
    }
    export class TradeBtxA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            curAmount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("curAmount" in data && data.curAmount != undefined) {
                    this.curAmount = data.curAmount;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get curAmount() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set curAmount(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            curAmount?: number;
        }): TradeBtxA {
            const message = new TradeBtxA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.curAmount != null) {
                message.curAmount = data.curAmount;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                curAmount?: number;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.curAmount != null) {
                data.curAmount = this.curAmount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.curAmount != 0)
                writer.writeDouble(2, this.curAmount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TradeBtxA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TradeBtxA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.curAmount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TradeBtxA {
            return TradeBtxA.deserialize(bytes);
        }
    }
    export class TradeBtcxSettlementN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            result?: TradeResult;
            tradeInfoId?: number;
            curAmount?: number;
            curDemoAmount?: number;
            settlementTimestamp?: number;
            wonMoney?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("result" in data && data.result != undefined) {
                    this.result = data.result;
                }
                if ("tradeInfoId" in data && data.tradeInfoId != undefined) {
                    this.tradeInfoId = data.tradeInfoId;
                }
                if ("curAmount" in data && data.curAmount != undefined) {
                    this.curAmount = data.curAmount;
                }
                if ("curDemoAmount" in data && data.curDemoAmount != undefined) {
                    this.curDemoAmount = data.curDemoAmount;
                }
                if ("settlementTimestamp" in data && data.settlementTimestamp != undefined) {
                    this.settlementTimestamp = data.settlementTimestamp;
                }
                if ("wonMoney" in data && data.wonMoney != undefined) {
                    this.wonMoney = data.wonMoney;
                }
            }
        }
        get result() {
            return pb_1.Message.getFieldWithDefault(this, 1, TradeResult.NotFinished) as TradeResult;
        }
        set result(value: TradeResult) {
            pb_1.Message.setField(this, 1, value);
        }
        get tradeInfoId() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set tradeInfoId(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get curAmount() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set curAmount(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get curDemoAmount() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set curDemoAmount(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get settlementTimestamp() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set settlementTimestamp(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get wonMoney() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set wonMoney(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            result?: TradeResult;
            tradeInfoId?: number;
            curAmount?: number;
            curDemoAmount?: number;
            settlementTimestamp?: number;
            wonMoney?: number;
        }): TradeBtcxSettlementN {
            const message = new TradeBtcxSettlementN({});
            if (data.result != null) {
                message.result = data.result;
            }
            if (data.tradeInfoId != null) {
                message.tradeInfoId = data.tradeInfoId;
            }
            if (data.curAmount != null) {
                message.curAmount = data.curAmount;
            }
            if (data.curDemoAmount != null) {
                message.curDemoAmount = data.curDemoAmount;
            }
            if (data.settlementTimestamp != null) {
                message.settlementTimestamp = data.settlementTimestamp;
            }
            if (data.wonMoney != null) {
                message.wonMoney = data.wonMoney;
            }
            return message;
        }
        toObject() {
            const data: {
                result?: TradeResult;
                tradeInfoId?: number;
                curAmount?: number;
                curDemoAmount?: number;
                settlementTimestamp?: number;
                wonMoney?: number;
            } = {};
            if (this.result != null) {
                data.result = this.result;
            }
            if (this.tradeInfoId != null) {
                data.tradeInfoId = this.tradeInfoId;
            }
            if (this.curAmount != null) {
                data.curAmount = this.curAmount;
            }
            if (this.curDemoAmount != null) {
                data.curDemoAmount = this.curDemoAmount;
            }
            if (this.settlementTimestamp != null) {
                data.settlementTimestamp = this.settlementTimestamp;
            }
            if (this.wonMoney != null) {
                data.wonMoney = this.wonMoney;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.result != TradeResult.NotFinished)
                writer.writeEnum(1, this.result);
            if (this.tradeInfoId != 0)
                writer.writeUint64(2, this.tradeInfoId);
            if (this.curAmount != 0)
                writer.writeDouble(3, this.curAmount);
            if (this.curDemoAmount != 0)
                writer.writeDouble(4, this.curDemoAmount);
            if (this.settlementTimestamp != 0)
                writer.writeUint64(5, this.settlementTimestamp);
            if (this.wonMoney != 0)
                writer.writeDouble(6, this.wonMoney);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TradeBtcxSettlementN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TradeBtcxSettlementN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.result = reader.readEnum();
                        break;
                    case 2:
                        message.tradeInfoId = reader.readUint64();
                        break;
                    case 3:
                        message.curAmount = reader.readDouble();
                        break;
                    case 4:
                        message.curDemoAmount = reader.readDouble();
                        break;
                    case 5:
                        message.settlementTimestamp = reader.readUint64();
                        break;
                    case 6:
                        message.wonMoney = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TradeBtcxSettlementN {
            return TradeBtcxSettlementN.deserialize(bytes);
        }
    }
    export class User extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: number;
            name?: string;
            amount?: number;
            demoAmount?: number;
            lastLogin?: string;
            createdAt?: string;
            isDemo?: boolean;
            finishedGuide?: boolean;
            enable?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("amount" in data && data.amount != undefined) {
                    this.amount = data.amount;
                }
                if ("demoAmount" in data && data.demoAmount != undefined) {
                    this.demoAmount = data.demoAmount;
                }
                if ("lastLogin" in data && data.lastLogin != undefined) {
                    this.lastLogin = data.lastLogin;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("isDemo" in data && data.isDemo != undefined) {
                    this.isDemo = data.isDemo;
                }
                if ("finishedGuide" in data && data.finishedGuide != undefined) {
                    this.finishedGuide = data.finishedGuide;
                }
                if ("enable" in data && data.enable != undefined) {
                    this.enable = data.enable;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get amount() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set amount(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get demoAmount() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set demoAmount(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get lastLogin() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set lastLogin(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get createdAt() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set createdAt(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get isDemo() {
            return pb_1.Message.getFieldWithDefault(this, 7, false) as boolean;
        }
        set isDemo(value: boolean) {
            pb_1.Message.setField(this, 7, value);
        }
        get finishedGuide() {
            return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
        }
        set finishedGuide(value: boolean) {
            pb_1.Message.setField(this, 8, value);
        }
        get enable() {
            return pb_1.Message.getFieldWithDefault(this, 9, false) as boolean;
        }
        set enable(value: boolean) {
            pb_1.Message.setField(this, 9, value);
        }
        static fromObject(data: {
            id?: number;
            name?: string;
            amount?: number;
            demoAmount?: number;
            lastLogin?: string;
            createdAt?: string;
            isDemo?: boolean;
            finishedGuide?: boolean;
            enable?: boolean;
        }): User {
            const message = new User({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.amount != null) {
                message.amount = data.amount;
            }
            if (data.demoAmount != null) {
                message.demoAmount = data.demoAmount;
            }
            if (data.lastLogin != null) {
                message.lastLogin = data.lastLogin;
            }
            if (data.createdAt != null) {
                message.createdAt = data.createdAt;
            }
            if (data.isDemo != null) {
                message.isDemo = data.isDemo;
            }
            if (data.finishedGuide != null) {
                message.finishedGuide = data.finishedGuide;
            }
            if (data.enable != null) {
                message.enable = data.enable;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: number;
                name?: string;
                amount?: number;
                demoAmount?: number;
                lastLogin?: string;
                createdAt?: string;
                isDemo?: boolean;
                finishedGuide?: boolean;
                enable?: boolean;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.amount != null) {
                data.amount = this.amount;
            }
            if (this.demoAmount != null) {
                data.demoAmount = this.demoAmount;
            }
            if (this.lastLogin != null) {
                data.lastLogin = this.lastLogin;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt;
            }
            if (this.isDemo != null) {
                data.isDemo = this.isDemo;
            }
            if (this.finishedGuide != null) {
                data.finishedGuide = this.finishedGuide;
            }
            if (this.enable != null) {
                data.enable = this.enable;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id != 0)
                writer.writeUint64(1, this.id);
            if (this.name.length)
                writer.writeString(2, this.name);
            if (this.amount != 0)
                writer.writeDouble(3, this.amount);
            if (this.demoAmount != 0)
                writer.writeDouble(4, this.demoAmount);
            if (this.lastLogin.length)
                writer.writeString(5, this.lastLogin);
            if (this.createdAt.length)
                writer.writeString(6, this.createdAt);
            if (this.isDemo != false)
                writer.writeBool(7, this.isDemo);
            if (this.finishedGuide != false)
                writer.writeBool(8, this.finishedGuide);
            if (this.enable != false)
                writer.writeBool(9, this.enable);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): User {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new User();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readUint64();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.amount = reader.readDouble();
                        break;
                    case 4:
                        message.demoAmount = reader.readDouble();
                        break;
                    case 5:
                        message.lastLogin = reader.readString();
                        break;
                    case 6:
                        message.createdAt = reader.readString();
                        break;
                    case 7:
                        message.isDemo = reader.readBool();
                        break;
                    case 8:
                        message.finishedGuide = reader.readBool();
                        break;
                    case 9:
                        message.enable = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): User {
            return User.deserialize(bytes);
        }
    }
    export class TradeInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: number;
            userId?: number;
            tradePrice?: number;
            settlementPrice?: number;
            riseOrFall?: number;
            startTime?: string;
            settlementTime?: string;
            betAmount?: number;
            commission?: number;
            fee?: number;
            instrument?: string;
            orderNo?: string;
            isDemo?: boolean;
            profit?: number;
            result?: number;
            status?: number;
            returnRate?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("userId" in data && data.userId != undefined) {
                    this.userId = data.userId;
                }
                if ("tradePrice" in data && data.tradePrice != undefined) {
                    this.tradePrice = data.tradePrice;
                }
                if ("settlementPrice" in data && data.settlementPrice != undefined) {
                    this.settlementPrice = data.settlementPrice;
                }
                if ("riseOrFall" in data && data.riseOrFall != undefined) {
                    this.riseOrFall = data.riseOrFall;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("settlementTime" in data && data.settlementTime != undefined) {
                    this.settlementTime = data.settlementTime;
                }
                if ("betAmount" in data && data.betAmount != undefined) {
                    this.betAmount = data.betAmount;
                }
                if ("commission" in data && data.commission != undefined) {
                    this.commission = data.commission;
                }
                if ("fee" in data && data.fee != undefined) {
                    this.fee = data.fee;
                }
                if ("instrument" in data && data.instrument != undefined) {
                    this.instrument = data.instrument;
                }
                if ("orderNo" in data && data.orderNo != undefined) {
                    this.orderNo = data.orderNo;
                }
                if ("isDemo" in data && data.isDemo != undefined) {
                    this.isDemo = data.isDemo;
                }
                if ("profit" in data && data.profit != undefined) {
                    this.profit = data.profit;
                }
                if ("result" in data && data.result != undefined) {
                    this.result = data.result;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("returnRate" in data && data.returnRate != undefined) {
                    this.returnRate = data.returnRate;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get userId() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set userId(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get tradePrice() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set tradePrice(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get settlementPrice() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set settlementPrice(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get riseOrFall() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set riseOrFall(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set startTime(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get settlementTime() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set settlementTime(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get betAmount() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set betAmount(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get commission() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set commission(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get fee() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set fee(value: number) {
            pb_1.Message.setField(this, 10, value);
        }
        get instrument() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set instrument(value: string) {
            pb_1.Message.setField(this, 11, value);
        }
        get orderNo() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set orderNo(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get isDemo() {
            return pb_1.Message.getFieldWithDefault(this, 13, false) as boolean;
        }
        set isDemo(value: boolean) {
            pb_1.Message.setField(this, 13, value);
        }
        get profit() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set profit(value: number) {
            pb_1.Message.setField(this, 14, value);
        }
        get result() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set result(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
        }
        set status(value: number) {
            pb_1.Message.setField(this, 16, value);
        }
        get returnRate() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set returnRate(value: number) {
            pb_1.Message.setField(this, 17, value);
        }
        static fromObject(data: {
            id?: number;
            userId?: number;
            tradePrice?: number;
            settlementPrice?: number;
            riseOrFall?: number;
            startTime?: string;
            settlementTime?: string;
            betAmount?: number;
            commission?: number;
            fee?: number;
            instrument?: string;
            orderNo?: string;
            isDemo?: boolean;
            profit?: number;
            result?: number;
            status?: number;
            returnRate?: number;
        }): TradeInfo {
            const message = new TradeInfo({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.userId != null) {
                message.userId = data.userId;
            }
            if (data.tradePrice != null) {
                message.tradePrice = data.tradePrice;
            }
            if (data.settlementPrice != null) {
                message.settlementPrice = data.settlementPrice;
            }
            if (data.riseOrFall != null) {
                message.riseOrFall = data.riseOrFall;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.settlementTime != null) {
                message.settlementTime = data.settlementTime;
            }
            if (data.betAmount != null) {
                message.betAmount = data.betAmount;
            }
            if (data.commission != null) {
                message.commission = data.commission;
            }
            if (data.fee != null) {
                message.fee = data.fee;
            }
            if (data.instrument != null) {
                message.instrument = data.instrument;
            }
            if (data.orderNo != null) {
                message.orderNo = data.orderNo;
            }
            if (data.isDemo != null) {
                message.isDemo = data.isDemo;
            }
            if (data.profit != null) {
                message.profit = data.profit;
            }
            if (data.result != null) {
                message.result = data.result;
            }
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.returnRate != null) {
                message.returnRate = data.returnRate;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: number;
                userId?: number;
                tradePrice?: number;
                settlementPrice?: number;
                riseOrFall?: number;
                startTime?: string;
                settlementTime?: string;
                betAmount?: number;
                commission?: number;
                fee?: number;
                instrument?: string;
                orderNo?: string;
                isDemo?: boolean;
                profit?: number;
                result?: number;
                status?: number;
                returnRate?: number;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.userId != null) {
                data.userId = this.userId;
            }
            if (this.tradePrice != null) {
                data.tradePrice = this.tradePrice;
            }
            if (this.settlementPrice != null) {
                data.settlementPrice = this.settlementPrice;
            }
            if (this.riseOrFall != null) {
                data.riseOrFall = this.riseOrFall;
            }
            if (this.startTime != null) {
                data.startTime = this.startTime;
            }
            if (this.settlementTime != null) {
                data.settlementTime = this.settlementTime;
            }
            if (this.betAmount != null) {
                data.betAmount = this.betAmount;
            }
            if (this.commission != null) {
                data.commission = this.commission;
            }
            if (this.fee != null) {
                data.fee = this.fee;
            }
            if (this.instrument != null) {
                data.instrument = this.instrument;
            }
            if (this.orderNo != null) {
                data.orderNo = this.orderNo;
            }
            if (this.isDemo != null) {
                data.isDemo = this.isDemo;
            }
            if (this.profit != null) {
                data.profit = this.profit;
            }
            if (this.result != null) {
                data.result = this.result;
            }
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.returnRate != null) {
                data.returnRate = this.returnRate;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id != 0)
                writer.writeUint64(1, this.id);
            if (this.userId != 0)
                writer.writeUint64(2, this.userId);
            if (this.tradePrice != 0)
                writer.writeDouble(3, this.tradePrice);
            if (this.settlementPrice != 0)
                writer.writeDouble(4, this.settlementPrice);
            if (this.riseOrFall != 0)
                writer.writeInt32(5, this.riseOrFall);
            if (this.startTime.length)
                writer.writeString(6, this.startTime);
            if (this.settlementTime.length)
                writer.writeString(7, this.settlementTime);
            if (this.betAmount != 0)
                writer.writeDouble(8, this.betAmount);
            if (this.commission != 0)
                writer.writeDouble(9, this.commission);
            if (this.fee != 0)
                writer.writeDouble(10, this.fee);
            if (this.instrument.length)
                writer.writeString(11, this.instrument);
            if (this.orderNo.length)
                writer.writeString(12, this.orderNo);
            if (this.isDemo != false)
                writer.writeBool(13, this.isDemo);
            if (this.profit != 0)
                writer.writeDouble(14, this.profit);
            if (this.result != 0)
                writer.writeInt32(15, this.result);
            if (this.status != 0)
                writer.writeInt32(16, this.status);
            if (this.returnRate != 0)
                writer.writeDouble(17, this.returnRate);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TradeInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TradeInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readUint64();
                        break;
                    case 2:
                        message.userId = reader.readUint64();
                        break;
                    case 3:
                        message.tradePrice = reader.readDouble();
                        break;
                    case 4:
                        message.settlementPrice = reader.readDouble();
                        break;
                    case 5:
                        message.riseOrFall = reader.readInt32();
                        break;
                    case 6:
                        message.startTime = reader.readString();
                        break;
                    case 7:
                        message.settlementTime = reader.readString();
                        break;
                    case 8:
                        message.betAmount = reader.readDouble();
                        break;
                    case 9:
                        message.commission = reader.readDouble();
                        break;
                    case 10:
                        message.fee = reader.readDouble();
                        break;
                    case 11:
                        message.instrument = reader.readString();
                        break;
                    case 12:
                        message.orderNo = reader.readString();
                        break;
                    case 13:
                        message.isDemo = reader.readBool();
                        break;
                    case 14:
                        message.profit = reader.readDouble();
                        break;
                    case 15:
                        message.result = reader.readInt32();
                        break;
                    case 16:
                        message.status = reader.readInt32();
                        break;
                    case 17:
                        message.returnRate = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TradeInfo {
            return TradeInfo.deserialize(bytes);
        }
    }
    export class Pagination extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            totalCount?: number;
            totalPage?: number;
            curPage?: number;
            limit?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("totalCount" in data && data.totalCount != undefined) {
                    this.totalCount = data.totalCount;
                }
                if ("totalPage" in data && data.totalPage != undefined) {
                    this.totalPage = data.totalPage;
                }
                if ("curPage" in data && data.curPage != undefined) {
                    this.curPage = data.curPage;
                }
                if ("limit" in data && data.limit != undefined) {
                    this.limit = data.limit;
                }
            }
        }
        get totalCount() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set totalCount(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get totalPage() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set totalPage(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get curPage() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set curPage(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get limit() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set limit(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            totalCount?: number;
            totalPage?: number;
            curPage?: number;
            limit?: number;
        }): Pagination {
            const message = new Pagination({});
            if (data.totalCount != null) {
                message.totalCount = data.totalCount;
            }
            if (data.totalPage != null) {
                message.totalPage = data.totalPage;
            }
            if (data.curPage != null) {
                message.curPage = data.curPage;
            }
            if (data.limit != null) {
                message.limit = data.limit;
            }
            return message;
        }
        toObject() {
            const data: {
                totalCount?: number;
                totalPage?: number;
                curPage?: number;
                limit?: number;
            } = {};
            if (this.totalCount != null) {
                data.totalCount = this.totalCount;
            }
            if (this.totalPage != null) {
                data.totalPage = this.totalPage;
            }
            if (this.curPage != null) {
                data.curPage = this.curPage;
            }
            if (this.limit != null) {
                data.limit = this.limit;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.totalCount != 0)
                writer.writeInt64(1, this.totalCount);
            if (this.totalPage != 0)
                writer.writeInt64(2, this.totalPage);
            if (this.curPage != 0)
                writer.writeInt64(3, this.curPage);
            if (this.limit != 0)
                writer.writeInt64(4, this.limit);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Pagination {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Pagination();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.totalCount = reader.readInt64();
                        break;
                    case 2:
                        message.totalPage = reader.readInt64();
                        break;
                    case 3:
                        message.curPage = reader.readInt64();
                        break;
                    case 4:
                        message.limit = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Pagination {
            return Pagination.deserialize(bytes);
        }
    }
    export class CloseTradeN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            instrument?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("instrument" in data && data.instrument != undefined) {
                    this.instrument = data.instrument;
                }
            }
        }
        get instrument() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set instrument(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            instrument?: string;
        }): CloseTradeN {
            const message = new CloseTradeN({});
            if (data.instrument != null) {
                message.instrument = data.instrument;
            }
            return message;
        }
        toObject() {
            const data: {
                instrument?: string;
            } = {};
            if (this.instrument != null) {
                data.instrument = this.instrument;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.instrument.length)
                writer.writeString(1, this.instrument);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CloseTradeN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CloseTradeN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.instrument = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): CloseTradeN {
            return CloseTradeN.deserialize(bytes);
        }
    }
    export class OpenTradeN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            instrument?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("instrument" in data && data.instrument != undefined) {
                    this.instrument = data.instrument;
                }
            }
        }
        get instrument() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set instrument(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            instrument?: string;
        }): OpenTradeN {
            const message = new OpenTradeN({});
            if (data.instrument != null) {
                message.instrument = data.instrument;
            }
            return message;
        }
        toObject() {
            const data: {
                instrument?: string;
            } = {};
            if (this.instrument != null) {
                data.instrument = this.instrument;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.instrument.length)
                writer.writeString(1, this.instrument);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OpenTradeN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OpenTradeN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.instrument = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): OpenTradeN {
            return OpenTradeN.deserialize(bytes);
        }
    }
    export class TradeConfig extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tradeLimitPerRound?: number;
            returnRate?: number;
            tradeFeePercent?: number;
            unilateralPoolDiffTreshold?: number;
            roundTime?: number;
            settlementDur?: number;
            waitTradeQueueLimit?: number;
            isClosed?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tradeLimitPerRound" in data && data.tradeLimitPerRound != undefined) {
                    this.tradeLimitPerRound = data.tradeLimitPerRound;
                }
                if ("returnRate" in data && data.returnRate != undefined) {
                    this.returnRate = data.returnRate;
                }
                if ("tradeFeePercent" in data && data.tradeFeePercent != undefined) {
                    this.tradeFeePercent = data.tradeFeePercent;
                }
                if ("unilateralPoolDiffTreshold" in data && data.unilateralPoolDiffTreshold != undefined) {
                    this.unilateralPoolDiffTreshold = data.unilateralPoolDiffTreshold;
                }
                if ("roundTime" in data && data.roundTime != undefined) {
                    this.roundTime = data.roundTime;
                }
                if ("settlementDur" in data && data.settlementDur != undefined) {
                    this.settlementDur = data.settlementDur;
                }
                if ("waitTradeQueueLimit" in data && data.waitTradeQueueLimit != undefined) {
                    this.waitTradeQueueLimit = data.waitTradeQueueLimit;
                }
                if ("isClosed" in data && data.isClosed != undefined) {
                    this.isClosed = data.isClosed;
                }
            }
        }
        get tradeLimitPerRound() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set tradeLimitPerRound(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get returnRate() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set returnRate(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get tradeFeePercent() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set tradeFeePercent(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get unilateralPoolDiffTreshold() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set unilateralPoolDiffTreshold(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get roundTime() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set roundTime(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get settlementDur() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set settlementDur(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get waitTradeQueueLimit() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set waitTradeQueueLimit(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get isClosed() {
            return pb_1.Message.getFieldWithDefault(this, 8, false) as boolean;
        }
        set isClosed(value: boolean) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data: {
            tradeLimitPerRound?: number;
            returnRate?: number;
            tradeFeePercent?: number;
            unilateralPoolDiffTreshold?: number;
            roundTime?: number;
            settlementDur?: number;
            waitTradeQueueLimit?: number;
            isClosed?: boolean;
        }): TradeConfig {
            const message = new TradeConfig({});
            if (data.tradeLimitPerRound != null) {
                message.tradeLimitPerRound = data.tradeLimitPerRound;
            }
            if (data.returnRate != null) {
                message.returnRate = data.returnRate;
            }
            if (data.tradeFeePercent != null) {
                message.tradeFeePercent = data.tradeFeePercent;
            }
            if (data.unilateralPoolDiffTreshold != null) {
                message.unilateralPoolDiffTreshold = data.unilateralPoolDiffTreshold;
            }
            if (data.roundTime != null) {
                message.roundTime = data.roundTime;
            }
            if (data.settlementDur != null) {
                message.settlementDur = data.settlementDur;
            }
            if (data.waitTradeQueueLimit != null) {
                message.waitTradeQueueLimit = data.waitTradeQueueLimit;
            }
            if (data.isClosed != null) {
                message.isClosed = data.isClosed;
            }
            return message;
        }
        toObject() {
            const data: {
                tradeLimitPerRound?: number;
                returnRate?: number;
                tradeFeePercent?: number;
                unilateralPoolDiffTreshold?: number;
                roundTime?: number;
                settlementDur?: number;
                waitTradeQueueLimit?: number;
                isClosed?: boolean;
            } = {};
            if (this.tradeLimitPerRound != null) {
                data.tradeLimitPerRound = this.tradeLimitPerRound;
            }
            if (this.returnRate != null) {
                data.returnRate = this.returnRate;
            }
            if (this.tradeFeePercent != null) {
                data.tradeFeePercent = this.tradeFeePercent;
            }
            if (this.unilateralPoolDiffTreshold != null) {
                data.unilateralPoolDiffTreshold = this.unilateralPoolDiffTreshold;
            }
            if (this.roundTime != null) {
                data.roundTime = this.roundTime;
            }
            if (this.settlementDur != null) {
                data.settlementDur = this.settlementDur;
            }
            if (this.waitTradeQueueLimit != null) {
                data.waitTradeQueueLimit = this.waitTradeQueueLimit;
            }
            if (this.isClosed != null) {
                data.isClosed = this.isClosed;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tradeLimitPerRound != 0)
                writer.writeInt64(1, this.tradeLimitPerRound);
            if (this.returnRate != 0)
                writer.writeDouble(2, this.returnRate);
            if (this.tradeFeePercent != 0)
                writer.writeDouble(3, this.tradeFeePercent);
            if (this.unilateralPoolDiffTreshold != 0)
                writer.writeDouble(4, this.unilateralPoolDiffTreshold);
            if (this.roundTime != 0)
                writer.writeInt32(5, this.roundTime);
            if (this.settlementDur != 0)
                writer.writeInt32(6, this.settlementDur);
            if (this.waitTradeQueueLimit != 0)
                writer.writeInt64(7, this.waitTradeQueueLimit);
            if (this.isClosed != false)
                writer.writeBool(8, this.isClosed);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TradeConfig {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TradeConfig();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.tradeLimitPerRound = reader.readInt64();
                        break;
                    case 2:
                        message.returnRate = reader.readDouble();
                        break;
                    case 3:
                        message.tradeFeePercent = reader.readDouble();
                        break;
                    case 4:
                        message.unilateralPoolDiffTreshold = reader.readDouble();
                        break;
                    case 5:
                        message.roundTime = reader.readInt32();
                        break;
                    case 6:
                        message.settlementDur = reader.readInt32();
                        break;
                    case 7:
                        message.waitTradeQueueLimit = reader.readInt64();
                        break;
                    case 8:
                        message.isClosed = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TradeConfig {
            return TradeConfig.deserialize(bytes);
        }
    }
    export class GetTradeConfigR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): GetTradeConfigR {
            const message = new GetTradeConfigR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTradeConfigR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTradeConfigR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetTradeConfigR {
            return GetTradeConfigR.deserialize(bytes);
        }
    }
    export class GetTradeConfigA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tradeConfig?: TradeConfig;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tradeConfig" in data && data.tradeConfig != undefined) {
                    this.tradeConfig = data.tradeConfig;
                }
            }
        }
        get tradeConfig() {
            return pb_1.Message.getWrapperField(this, TradeConfig, 1) as TradeConfig;
        }
        set tradeConfig(value: TradeConfig) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_tradeConfig() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            tradeConfig?: ReturnType<typeof TradeConfig.prototype.toObject>;
        }): GetTradeConfigA {
            const message = new GetTradeConfigA({});
            if (data.tradeConfig != null) {
                message.tradeConfig = TradeConfig.fromObject(data.tradeConfig);
            }
            return message;
        }
        toObject() {
            const data: {
                tradeConfig?: ReturnType<typeof TradeConfig.prototype.toObject>;
            } = {};
            if (this.tradeConfig != null) {
                data.tradeConfig = this.tradeConfig.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_tradeConfig)
                writer.writeMessage(1, this.tradeConfig, () => this.tradeConfig.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetTradeConfigA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetTradeConfigA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.tradeConfig, () => message.tradeConfig = TradeConfig.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetTradeConfigA {
            return GetTradeConfigA.deserialize(bytes);
        }
    }
    export class TradeBtcxStartN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tradeInfo?: TradeInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tradeInfo" in data && data.tradeInfo != undefined) {
                    this.tradeInfo = data.tradeInfo;
                }
            }
        }
        get tradeInfo() {
            return pb_1.Message.getWrapperField(this, TradeInfo, 1) as TradeInfo;
        }
        set tradeInfo(value: TradeInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_tradeInfo() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            tradeInfo?: ReturnType<typeof TradeInfo.prototype.toObject>;
        }): TradeBtcxStartN {
            const message = new TradeBtcxStartN({});
            if (data.tradeInfo != null) {
                message.tradeInfo = TradeInfo.fromObject(data.tradeInfo);
            }
            return message;
        }
        toObject() {
            const data: {
                tradeInfo?: ReturnType<typeof TradeInfo.prototype.toObject>;
            } = {};
            if (this.tradeInfo != null) {
                data.tradeInfo = this.tradeInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_tradeInfo)
                writer.writeMessage(1, this.tradeInfo, () => this.tradeInfo.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TradeBtcxStartN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TradeBtcxStartN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.tradeInfo, () => message.tradeInfo = TradeInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TradeBtcxStartN {
            return TradeBtcxStartN.deserialize(bytes);
        }
    }
    export class FindBtcxHistoryR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            count?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("count" in data && data.count != undefined) {
                    this.count = data.count;
                }
            }
        }
        get count() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set count(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            count?: number;
        }): FindBtcxHistoryR {
            const message = new FindBtcxHistoryR({});
            if (data.count != null) {
                message.count = data.count;
            }
            return message;
        }
        toObject() {
            const data: {
                count?: number;
            } = {};
            if (this.count != null) {
                data.count = this.count;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.count != 0)
                writer.writeInt32(1, this.count);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindBtcxHistoryR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindBtcxHistoryR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.count = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindBtcxHistoryR {
            return FindBtcxHistoryR.deserialize(bytes);
        }
    }
    export class FindBtcxHistoryA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            btcxInfos?: BitxInfo[];
            holdingIdxs?: number[];
            tradeInfos?: TradeInfo[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 2, 3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("btcxInfos" in data && data.btcxInfos != undefined) {
                    this.btcxInfos = data.btcxInfos;
                }
                if ("holdingIdxs" in data && data.holdingIdxs != undefined) {
                    this.holdingIdxs = data.holdingIdxs;
                }
                if ("tradeInfos" in data && data.tradeInfos != undefined) {
                    this.tradeInfos = data.tradeInfos;
                }
            }
        }
        get btcxInfos() {
            return pb_1.Message.getRepeatedWrapperField(this, BitxInfo, 1) as BitxInfo[];
        }
        set btcxInfos(value: BitxInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        get holdingIdxs() {
            return pb_1.Message.getFieldWithDefault(this, 2, []) as number[];
        }
        set holdingIdxs(value: number[]) {
            pb_1.Message.setField(this, 2, value);
        }
        get tradeInfos() {
            return pb_1.Message.getRepeatedWrapperField(this, TradeInfo, 3) as TradeInfo[];
        }
        set tradeInfos(value: TradeInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            btcxInfos?: ReturnType<typeof BitxInfo.prototype.toObject>[];
            holdingIdxs?: number[];
            tradeInfos?: ReturnType<typeof TradeInfo.prototype.toObject>[];
        }): FindBtcxHistoryA {
            const message = new FindBtcxHistoryA({});
            if (data.btcxInfos != null) {
                message.btcxInfos = data.btcxInfos.map(item => BitxInfo.fromObject(item));
            }
            if (data.holdingIdxs != null) {
                message.holdingIdxs = data.holdingIdxs;
            }
            if (data.tradeInfos != null) {
                message.tradeInfos = data.tradeInfos.map(item => TradeInfo.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                btcxInfos?: ReturnType<typeof BitxInfo.prototype.toObject>[];
                holdingIdxs?: number[];
                tradeInfos?: ReturnType<typeof TradeInfo.prototype.toObject>[];
            } = {};
            if (this.btcxInfos != null) {
                data.btcxInfos = this.btcxInfos.map((item: BitxInfo) => item.toObject());
            }
            if (this.holdingIdxs != null) {
                data.holdingIdxs = this.holdingIdxs;
            }
            if (this.tradeInfos != null) {
                data.tradeInfos = this.tradeInfos.map((item: TradeInfo) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.btcxInfos.length)
                writer.writeRepeatedMessage(1, this.btcxInfos, (item: BitxInfo) => item.serialize(writer));
            if (this.holdingIdxs.length)
                writer.writePackedInt32(2, this.holdingIdxs);
            if (this.tradeInfos.length)
                writer.writeRepeatedMessage(3, this.tradeInfos, (item: TradeInfo) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindBtcxHistoryA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindBtcxHistoryA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.btcxInfos, () => pb_1.Message.addToRepeatedWrapperField(message, 1, BitxInfo.deserialize(reader), BitxInfo));
                        break;
                    case 2:
                        message.holdingIdxs = reader.readPackedInt32();
                        break;
                    case 3:
                        reader.readMessage(message.tradeInfos, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TradeInfo.deserialize(reader), TradeInfo));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindBtcxHistoryA {
            return FindBtcxHistoryA.deserialize(bytes);
        }
    }
    export class FindInstrumentHistoryR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            instId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("instId" in data && data.instId != undefined) {
                    this.instId = data.instId;
                }
            }
        }
        get instId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set instId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            instId?: string;
        }): FindInstrumentHistoryR {
            const message = new FindInstrumentHistoryR({});
            if (data.instId != null) {
                message.instId = data.instId;
            }
            return message;
        }
        toObject() {
            const data: {
                instId?: string;
            } = {};
            if (this.instId != null) {
                data.instId = this.instId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.instId.length)
                writer.writeString(1, this.instId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindInstrumentHistoryR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindInstrumentHistoryR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.instId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindInstrumentHistoryR {
            return FindInstrumentHistoryR.deserialize(bytes);
        }
    }
    export class FindInstrumentHistoryA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            instruments?: Instrument[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("instruments" in data && data.instruments != undefined) {
                    this.instruments = data.instruments;
                }
            }
        }
        get instruments() {
            return pb_1.Message.getRepeatedWrapperField(this, Instrument, 1) as Instrument[];
        }
        set instruments(value: Instrument[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            instruments?: ReturnType<typeof Instrument.prototype.toObject>[];
        }): FindInstrumentHistoryA {
            const message = new FindInstrumentHistoryA({});
            if (data.instruments != null) {
                message.instruments = data.instruments.map(item => Instrument.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                instruments?: ReturnType<typeof Instrument.prototype.toObject>[];
            } = {};
            if (this.instruments != null) {
                data.instruments = this.instruments.map((item: Instrument) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.instruments.length)
                writer.writeRepeatedMessage(1, this.instruments, (item: Instrument) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindInstrumentHistoryA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindInstrumentHistoryA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.instruments, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Instrument.deserialize(reader), Instrument));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindInstrumentHistoryA {
            return FindInstrumentHistoryA.deserialize(bytes);
        }
    }
    export class FindTradeInfosR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            page?: number;
            limit?: number;
            startTime?: string;
            endTime?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("page" in data && data.page != undefined) {
                    this.page = data.page;
                }
                if ("limit" in data && data.limit != undefined) {
                    this.limit = data.limit;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
            }
        }
        get page() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set page(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get limit() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set limit(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set startTime(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set endTime(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            page?: number;
            limit?: number;
            startTime?: string;
            endTime?: string;
        }): FindTradeInfosR {
            const message = new FindTradeInfosR({});
            if (data.page != null) {
                message.page = data.page;
            }
            if (data.limit != null) {
                message.limit = data.limit;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            return message;
        }
        toObject() {
            const data: {
                page?: number;
                limit?: number;
                startTime?: string;
                endTime?: string;
            } = {};
            if (this.page != null) {
                data.page = this.page;
            }
            if (this.limit != null) {
                data.limit = this.limit;
            }
            if (this.startTime != null) {
                data.startTime = this.startTime;
            }
            if (this.endTime != null) {
                data.endTime = this.endTime;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.page != 0)
                writer.writeInt32(1, this.page);
            if (this.limit != 0)
                writer.writeInt32(2, this.limit);
            if (this.startTime.length)
                writer.writeString(3, this.startTime);
            if (this.endTime.length)
                writer.writeString(4, this.endTime);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindTradeInfosR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindTradeInfosR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.page = reader.readInt32();
                        break;
                    case 2:
                        message.limit = reader.readInt32();
                        break;
                    case 3:
                        message.startTime = reader.readString();
                        break;
                    case 4:
                        message.endTime = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindTradeInfosR {
            return FindTradeInfosR.deserialize(bytes);
        }
    }
    export class FindTradeInfosA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tradeInfos?: TradeInfo[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tradeInfos" in data && data.tradeInfos != undefined) {
                    this.tradeInfos = data.tradeInfos;
                }
            }
        }
        get tradeInfos() {
            return pb_1.Message.getRepeatedWrapperField(this, TradeInfo, 1) as TradeInfo[];
        }
        set tradeInfos(value: TradeInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            tradeInfos?: ReturnType<typeof TradeInfo.prototype.toObject>[];
        }): FindTradeInfosA {
            const message = new FindTradeInfosA({});
            if (data.tradeInfos != null) {
                message.tradeInfos = data.tradeInfos.map(item => TradeInfo.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                tradeInfos?: ReturnType<typeof TradeInfo.prototype.toObject>[];
            } = {};
            if (this.tradeInfos != null) {
                data.tradeInfos = this.tradeInfos.map((item: TradeInfo) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tradeInfos.length)
                writer.writeRepeatedMessage(1, this.tradeInfos, (item: TradeInfo) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindTradeInfosA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindTradeInfosA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.tradeInfos, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TradeInfo.deserialize(reader), TradeInfo));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindTradeInfosA {
            return FindTradeInfosA.deserialize(bytes);
        }
    }
    export class FindHoldingOrdersR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): FindHoldingOrdersR {
            const message = new FindHoldingOrdersR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindHoldingOrdersR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindHoldingOrdersR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindHoldingOrdersR {
            return FindHoldingOrdersR.deserialize(bytes);
        }
    }
    export class FindHoldingOrdersA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tradeInfos?: TradeInfo[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tradeInfos" in data && data.tradeInfos != undefined) {
                    this.tradeInfos = data.tradeInfos;
                }
            }
        }
        get tradeInfos() {
            return pb_1.Message.getRepeatedWrapperField(this, TradeInfo, 1) as TradeInfo[];
        }
        set tradeInfos(value: TradeInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            tradeInfos?: ReturnType<typeof TradeInfo.prototype.toObject>[];
        }): FindHoldingOrdersA {
            const message = new FindHoldingOrdersA({});
            if (data.tradeInfos != null) {
                message.tradeInfos = data.tradeInfos.map(item => TradeInfo.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                tradeInfos?: ReturnType<typeof TradeInfo.prototype.toObject>[];
            } = {};
            if (this.tradeInfos != null) {
                data.tradeInfos = this.tradeInfos.map((item: TradeInfo) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tradeInfos.length)
                writer.writeRepeatedMessage(1, this.tradeInfos, (item: TradeInfo) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindHoldingOrdersA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindHoldingOrdersA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.tradeInfos, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TradeInfo.deserialize(reader), TradeInfo));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindHoldingOrdersA {
            return FindHoldingOrdersA.deserialize(bytes);
        }
    }
    export class PersonalInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            fullName?: string;
            birth?: string;
            docType?: number;
            iDNumber?: string;
            picture?: Uint8Array;
            picName?: string;
            picBase64?: string;
            picUrl?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("fullName" in data && data.fullName != undefined) {
                    this.fullName = data.fullName;
                }
                if ("birth" in data && data.birth != undefined) {
                    this.birth = data.birth;
                }
                if ("docType" in data && data.docType != undefined) {
                    this.docType = data.docType;
                }
                if ("iDNumber" in data && data.iDNumber != undefined) {
                    this.iDNumber = data.iDNumber;
                }
                if ("picture" in data && data.picture != undefined) {
                    this.picture = data.picture;
                }
                if ("picName" in data && data.picName != undefined) {
                    this.picName = data.picName;
                }
                if ("picBase64" in data && data.picBase64 != undefined) {
                    this.picBase64 = data.picBase64;
                }
                if ("picUrl" in data && data.picUrl != undefined) {
                    this.picUrl = data.picUrl;
                }
            }
        }
        get fullName() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set fullName(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get birth() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set birth(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get docType() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set docType(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get iDNumber() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set iDNumber(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get picture() {
            return pb_1.Message.getFieldWithDefault(this, 5, new Uint8Array(0)) as Uint8Array;
        }
        set picture(value: Uint8Array) {
            pb_1.Message.setField(this, 5, value);
        }
        get picName() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set picName(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get picBase64() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set picBase64(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get picUrl() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set picUrl(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data: {
            fullName?: string;
            birth?: string;
            docType?: number;
            iDNumber?: string;
            picture?: Uint8Array;
            picName?: string;
            picBase64?: string;
            picUrl?: string;
        }): PersonalInfo {
            const message = new PersonalInfo({});
            if (data.fullName != null) {
                message.fullName = data.fullName;
            }
            if (data.birth != null) {
                message.birth = data.birth;
            }
            if (data.docType != null) {
                message.docType = data.docType;
            }
            if (data.iDNumber != null) {
                message.iDNumber = data.iDNumber;
            }
            if (data.picture != null) {
                message.picture = data.picture;
            }
            if (data.picName != null) {
                message.picName = data.picName;
            }
            if (data.picBase64 != null) {
                message.picBase64 = data.picBase64;
            }
            if (data.picUrl != null) {
                message.picUrl = data.picUrl;
            }
            return message;
        }
        toObject() {
            const data: {
                fullName?: string;
                birth?: string;
                docType?: number;
                iDNumber?: string;
                picture?: Uint8Array;
                picName?: string;
                picBase64?: string;
                picUrl?: string;
            } = {};
            if (this.fullName != null) {
                data.fullName = this.fullName;
            }
            if (this.birth != null) {
                data.birth = this.birth;
            }
            if (this.docType != null) {
                data.docType = this.docType;
            }
            if (this.iDNumber != null) {
                data.iDNumber = this.iDNumber;
            }
            if (this.picture != null) {
                data.picture = this.picture;
            }
            if (this.picName != null) {
                data.picName = this.picName;
            }
            if (this.picBase64 != null) {
                data.picBase64 = this.picBase64;
            }
            if (this.picUrl != null) {
                data.picUrl = this.picUrl;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.fullName.length)
                writer.writeString(1, this.fullName);
            if (this.birth.length)
                writer.writeString(2, this.birth);
            if (this.docType != 0)
                writer.writeInt32(3, this.docType);
            if (this.iDNumber.length)
                writer.writeString(4, this.iDNumber);
            if (this.picture.length)
                writer.writeBytes(5, this.picture);
            if (this.picName.length)
                writer.writeString(6, this.picName);
            if (this.picBase64.length)
                writer.writeString(7, this.picBase64);
            if (this.picUrl.length)
                writer.writeString(8, this.picUrl);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PersonalInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PersonalInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.fullName = reader.readString();
                        break;
                    case 2:
                        message.birth = reader.readString();
                        break;
                    case 3:
                        message.docType = reader.readInt32();
                        break;
                    case 4:
                        message.iDNumber = reader.readString();
                        break;
                    case 5:
                        message.picture = reader.readBytes();
                        break;
                    case 6:
                        message.picName = reader.readString();
                        break;
                    case 7:
                        message.picBase64 = reader.readString();
                        break;
                    case 8:
                        message.picUrl = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): PersonalInfo {
            return PersonalInfo.deserialize(bytes);
        }
    }
    export class SubmitPersonalInfoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            info?: PersonalInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get info() {
            return pb_1.Message.getWrapperField(this, PersonalInfo, 1) as PersonalInfo;
        }
        set info(value: PersonalInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            info?: ReturnType<typeof PersonalInfo.prototype.toObject>;
        }): SubmitPersonalInfoR {
            const message = new SubmitPersonalInfoR({});
            if (data.info != null) {
                message.info = PersonalInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                info?: ReturnType<typeof PersonalInfo.prototype.toObject>;
            } = {};
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_info)
                writer.writeMessage(1, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitPersonalInfoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitPersonalInfoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.info, () => message.info = PersonalInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitPersonalInfoR {
            return SubmitPersonalInfoR.deserialize(bytes);
        }
    }
    export class SubmitPersonalInfoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): SubmitPersonalInfoA {
            const message = new SubmitPersonalInfoA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitPersonalInfoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitPersonalInfoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitPersonalInfoA {
            return SubmitPersonalInfoA.deserialize(bytes);
        }
    }
    export class ResidenceInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            country?: string;
            city?: string;
            zipCode?: string;
            fullAddress?: string;
            picture?: Uint8Array;
            picName?: string;
            picBase64?: string;
            picUrl?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("country" in data && data.country != undefined) {
                    this.country = data.country;
                }
                if ("city" in data && data.city != undefined) {
                    this.city = data.city;
                }
                if ("zipCode" in data && data.zipCode != undefined) {
                    this.zipCode = data.zipCode;
                }
                if ("fullAddress" in data && data.fullAddress != undefined) {
                    this.fullAddress = data.fullAddress;
                }
                if ("picture" in data && data.picture != undefined) {
                    this.picture = data.picture;
                }
                if ("picName" in data && data.picName != undefined) {
                    this.picName = data.picName;
                }
                if ("picBase64" in data && data.picBase64 != undefined) {
                    this.picBase64 = data.picBase64;
                }
                if ("picUrl" in data && data.picUrl != undefined) {
                    this.picUrl = data.picUrl;
                }
            }
        }
        get country() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set country(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get city() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set city(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get zipCode() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set zipCode(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get fullAddress() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set fullAddress(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get picture() {
            return pb_1.Message.getFieldWithDefault(this, 5, new Uint8Array(0)) as Uint8Array;
        }
        set picture(value: Uint8Array) {
            pb_1.Message.setField(this, 5, value);
        }
        get picName() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set picName(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get picBase64() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set picBase64(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get picUrl() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set picUrl(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data: {
            country?: string;
            city?: string;
            zipCode?: string;
            fullAddress?: string;
            picture?: Uint8Array;
            picName?: string;
            picBase64?: string;
            picUrl?: string;
        }): ResidenceInfo {
            const message = new ResidenceInfo({});
            if (data.country != null) {
                message.country = data.country;
            }
            if (data.city != null) {
                message.city = data.city;
            }
            if (data.zipCode != null) {
                message.zipCode = data.zipCode;
            }
            if (data.fullAddress != null) {
                message.fullAddress = data.fullAddress;
            }
            if (data.picture != null) {
                message.picture = data.picture;
            }
            if (data.picName != null) {
                message.picName = data.picName;
            }
            if (data.picBase64 != null) {
                message.picBase64 = data.picBase64;
            }
            if (data.picUrl != null) {
                message.picUrl = data.picUrl;
            }
            return message;
        }
        toObject() {
            const data: {
                country?: string;
                city?: string;
                zipCode?: string;
                fullAddress?: string;
                picture?: Uint8Array;
                picName?: string;
                picBase64?: string;
                picUrl?: string;
            } = {};
            if (this.country != null) {
                data.country = this.country;
            }
            if (this.city != null) {
                data.city = this.city;
            }
            if (this.zipCode != null) {
                data.zipCode = this.zipCode;
            }
            if (this.fullAddress != null) {
                data.fullAddress = this.fullAddress;
            }
            if (this.picture != null) {
                data.picture = this.picture;
            }
            if (this.picName != null) {
                data.picName = this.picName;
            }
            if (this.picBase64 != null) {
                data.picBase64 = this.picBase64;
            }
            if (this.picUrl != null) {
                data.picUrl = this.picUrl;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.country.length)
                writer.writeString(1, this.country);
            if (this.city.length)
                writer.writeString(2, this.city);
            if (this.zipCode.length)
                writer.writeString(3, this.zipCode);
            if (this.fullAddress.length)
                writer.writeString(4, this.fullAddress);
            if (this.picture.length)
                writer.writeBytes(5, this.picture);
            if (this.picName.length)
                writer.writeString(6, this.picName);
            if (this.picBase64.length)
                writer.writeString(7, this.picBase64);
            if (this.picUrl.length)
                writer.writeString(8, this.picUrl);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResidenceInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResidenceInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.country = reader.readString();
                        break;
                    case 2:
                        message.city = reader.readString();
                        break;
                    case 3:
                        message.zipCode = reader.readString();
                        break;
                    case 4:
                        message.fullAddress = reader.readString();
                        break;
                    case 5:
                        message.picture = reader.readBytes();
                        break;
                    case 6:
                        message.picName = reader.readString();
                        break;
                    case 7:
                        message.picBase64 = reader.readString();
                        break;
                    case 8:
                        message.picUrl = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ResidenceInfo {
            return ResidenceInfo.deserialize(bytes);
        }
    }
    export class SubmitResidenceInfoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            info?: ResidenceInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get info() {
            return pb_1.Message.getWrapperField(this, ResidenceInfo, 1) as ResidenceInfo;
        }
        set info(value: ResidenceInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            info?: ReturnType<typeof ResidenceInfo.prototype.toObject>;
        }): SubmitResidenceInfoR {
            const message = new SubmitResidenceInfoR({});
            if (data.info != null) {
                message.info = ResidenceInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                info?: ReturnType<typeof ResidenceInfo.prototype.toObject>;
            } = {};
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_info)
                writer.writeMessage(1, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitResidenceInfoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitResidenceInfoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.info, () => message.info = ResidenceInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitResidenceInfoR {
            return SubmitResidenceInfoR.deserialize(bytes);
        }
    }
    export class SubmitResidenceInfoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): SubmitResidenceInfoA {
            const message = new SubmitResidenceInfoA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitResidenceInfoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitResidenceInfoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitResidenceInfoA {
            return SubmitResidenceInfoA.deserialize(bytes);
        }
    }
    export class RevenueInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            currency?: string;
            revenueStreams?: string;
            employStatus?: string;
            industry?: string;
            position?: string;
            company?: string;
            annualIncome?: string;
            picture?: Uint8Array;
            picName?: string;
            picBase64?: string;
            picUrl?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("currency" in data && data.currency != undefined) {
                    this.currency = data.currency;
                }
                if ("revenueStreams" in data && data.revenueStreams != undefined) {
                    this.revenueStreams = data.revenueStreams;
                }
                if ("employStatus" in data && data.employStatus != undefined) {
                    this.employStatus = data.employStatus;
                }
                if ("industry" in data && data.industry != undefined) {
                    this.industry = data.industry;
                }
                if ("position" in data && data.position != undefined) {
                    this.position = data.position;
                }
                if ("company" in data && data.company != undefined) {
                    this.company = data.company;
                }
                if ("annualIncome" in data && data.annualIncome != undefined) {
                    this.annualIncome = data.annualIncome;
                }
                if ("picture" in data && data.picture != undefined) {
                    this.picture = data.picture;
                }
                if ("picName" in data && data.picName != undefined) {
                    this.picName = data.picName;
                }
                if ("picBase64" in data && data.picBase64 != undefined) {
                    this.picBase64 = data.picBase64;
                }
                if ("picUrl" in data && data.picUrl != undefined) {
                    this.picUrl = data.picUrl;
                }
            }
        }
        get currency() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set currency(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get revenueStreams() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set revenueStreams(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get employStatus() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set employStatus(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get industry() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set industry(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get position() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set position(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get company() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set company(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get annualIncome() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set annualIncome(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get picture() {
            return pb_1.Message.getFieldWithDefault(this, 8, new Uint8Array(0)) as Uint8Array;
        }
        set picture(value: Uint8Array) {
            pb_1.Message.setField(this, 8, value);
        }
        get picName() {
            return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
        }
        set picName(value: string) {
            pb_1.Message.setField(this, 9, value);
        }
        get picBase64() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set picBase64(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get picUrl() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set picUrl(value: string) {
            pb_1.Message.setField(this, 11, value);
        }
        static fromObject(data: {
            currency?: string;
            revenueStreams?: string;
            employStatus?: string;
            industry?: string;
            position?: string;
            company?: string;
            annualIncome?: string;
            picture?: Uint8Array;
            picName?: string;
            picBase64?: string;
            picUrl?: string;
        }): RevenueInfo {
            const message = new RevenueInfo({});
            if (data.currency != null) {
                message.currency = data.currency;
            }
            if (data.revenueStreams != null) {
                message.revenueStreams = data.revenueStreams;
            }
            if (data.employStatus != null) {
                message.employStatus = data.employStatus;
            }
            if (data.industry != null) {
                message.industry = data.industry;
            }
            if (data.position != null) {
                message.position = data.position;
            }
            if (data.company != null) {
                message.company = data.company;
            }
            if (data.annualIncome != null) {
                message.annualIncome = data.annualIncome;
            }
            if (data.picture != null) {
                message.picture = data.picture;
            }
            if (data.picName != null) {
                message.picName = data.picName;
            }
            if (data.picBase64 != null) {
                message.picBase64 = data.picBase64;
            }
            if (data.picUrl != null) {
                message.picUrl = data.picUrl;
            }
            return message;
        }
        toObject() {
            const data: {
                currency?: string;
                revenueStreams?: string;
                employStatus?: string;
                industry?: string;
                position?: string;
                company?: string;
                annualIncome?: string;
                picture?: Uint8Array;
                picName?: string;
                picBase64?: string;
                picUrl?: string;
            } = {};
            if (this.currency != null) {
                data.currency = this.currency;
            }
            if (this.revenueStreams != null) {
                data.revenueStreams = this.revenueStreams;
            }
            if (this.employStatus != null) {
                data.employStatus = this.employStatus;
            }
            if (this.industry != null) {
                data.industry = this.industry;
            }
            if (this.position != null) {
                data.position = this.position;
            }
            if (this.company != null) {
                data.company = this.company;
            }
            if (this.annualIncome != null) {
                data.annualIncome = this.annualIncome;
            }
            if (this.picture != null) {
                data.picture = this.picture;
            }
            if (this.picName != null) {
                data.picName = this.picName;
            }
            if (this.picBase64 != null) {
                data.picBase64 = this.picBase64;
            }
            if (this.picUrl != null) {
                data.picUrl = this.picUrl;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.currency.length)
                writer.writeString(1, this.currency);
            if (this.revenueStreams.length)
                writer.writeString(2, this.revenueStreams);
            if (this.employStatus.length)
                writer.writeString(3, this.employStatus);
            if (this.industry.length)
                writer.writeString(4, this.industry);
            if (this.position.length)
                writer.writeString(5, this.position);
            if (this.company.length)
                writer.writeString(6, this.company);
            if (this.annualIncome.length)
                writer.writeString(7, this.annualIncome);
            if (this.picture.length)
                writer.writeBytes(8, this.picture);
            if (this.picName.length)
                writer.writeString(9, this.picName);
            if (this.picBase64.length)
                writer.writeString(10, this.picBase64);
            if (this.picUrl.length)
                writer.writeString(11, this.picUrl);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RevenueInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RevenueInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.currency = reader.readString();
                        break;
                    case 2:
                        message.revenueStreams = reader.readString();
                        break;
                    case 3:
                        message.employStatus = reader.readString();
                        break;
                    case 4:
                        message.industry = reader.readString();
                        break;
                    case 5:
                        message.position = reader.readString();
                        break;
                    case 6:
                        message.company = reader.readString();
                        break;
                    case 7:
                        message.annualIncome = reader.readString();
                        break;
                    case 8:
                        message.picture = reader.readBytes();
                        break;
                    case 9:
                        message.picName = reader.readString();
                        break;
                    case 10:
                        message.picBase64 = reader.readString();
                        break;
                    case 11:
                        message.picUrl = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RevenueInfo {
            return RevenueInfo.deserialize(bytes);
        }
    }
    export class SubmitRevenueInfoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            info?: RevenueInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get info() {
            return pb_1.Message.getWrapperField(this, RevenueInfo, 1) as RevenueInfo;
        }
        set info(value: RevenueInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            info?: ReturnType<typeof RevenueInfo.prototype.toObject>;
        }): SubmitRevenueInfoR {
            const message = new SubmitRevenueInfoR({});
            if (data.info != null) {
                message.info = RevenueInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                info?: ReturnType<typeof RevenueInfo.prototype.toObject>;
            } = {};
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_info)
                writer.writeMessage(1, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitRevenueInfoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitRevenueInfoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.info, () => message.info = RevenueInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitRevenueInfoR {
            return SubmitRevenueInfoR.deserialize(bytes);
        }
    }
    export class SubmitRevenueInfoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): SubmitRevenueInfoA {
            const message = new SubmitRevenueInfoA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitRevenueInfoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubmitRevenueInfoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitRevenueInfoA {
            return SubmitRevenueInfoA.deserialize(bytes);
        }
    }
    export class SearchAvailableCountryR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            letter?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("letter" in data && data.letter != undefined) {
                    this.letter = data.letter;
                }
            }
        }
        get letter() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set letter(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            letter?: string;
        }): SearchAvailableCountryR {
            const message = new SearchAvailableCountryR({});
            if (data.letter != null) {
                message.letter = data.letter;
            }
            return message;
        }
        toObject() {
            const data: {
                letter?: string;
            } = {};
            if (this.letter != null) {
                data.letter = this.letter;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.letter.length)
                writer.writeString(1, this.letter);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchAvailableCountryR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchAvailableCountryR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.letter = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SearchAvailableCountryR {
            return SearchAvailableCountryR.deserialize(bytes);
        }
    }
    export class SearchAvailableCountryA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            country?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("country" in data && data.country != undefined) {
                    this.country = data.country;
                }
            }
        }
        get country() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set country(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            country?: string[];
        }): SearchAvailableCountryA {
            const message = new SearchAvailableCountryA({});
            if (data.country != null) {
                message.country = data.country;
            }
            return message;
        }
        toObject() {
            const data: {
                country?: string[];
            } = {};
            if (this.country != null) {
                data.country = this.country;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.country.length)
                writer.writeRepeatedString(1, this.country);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchAvailableCountryA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchAvailableCountryA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SearchAvailableCountryA {
            return SearchAvailableCountryA.deserialize(bytes);
        }
    }
    export class SearchCountryIdentityInfoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            country?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("country" in data && data.country != undefined) {
                    this.country = data.country;
                }
            }
        }
        get country() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set country(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            country?: string;
        }): SearchCountryIdentityInfoR {
            const message = new SearchCountryIdentityInfoR({});
            if (data.country != null) {
                message.country = data.country;
            }
            return message;
        }
        toObject() {
            const data: {
                country?: string;
            } = {};
            if (this.country != null) {
                data.country = this.country;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.country.length)
                writer.writeString(1, this.country);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchCountryIdentityInfoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchCountryIdentityInfoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.country = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SearchCountryIdentityInfoR {
            return SearchCountryIdentityInfoR.deserialize(bytes);
        }
    }
    export class SearchCountryIdentityInfoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            identityInfo?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("identityInfo" in data && data.identityInfo != undefined) {
                    this.identityInfo = data.identityInfo;
                }
            }
        }
        get identityInfo() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set identityInfo(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            identityInfo?: string[];
        }): SearchCountryIdentityInfoA {
            const message = new SearchCountryIdentityInfoA({});
            if (data.identityInfo != null) {
                message.identityInfo = data.identityInfo;
            }
            return message;
        }
        toObject() {
            const data: {
                identityInfo?: string[];
            } = {};
            if (this.identityInfo != null) {
                data.identityInfo = this.identityInfo;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.identityInfo.length)
                writer.writeRepeatedString(1, this.identityInfo);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchCountryIdentityInfoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchCountryIdentityInfoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SearchCountryIdentityInfoA {
            return SearchCountryIdentityInfoA.deserialize(bytes);
        }
    }
    export class GetPersonalInfoHandlerR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): GetPersonalInfoHandlerR {
            const message = new GetPersonalInfoHandlerR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetPersonalInfoHandlerR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetPersonalInfoHandlerR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetPersonalInfoHandlerR {
            return GetPersonalInfoHandlerR.deserialize(bytes);
        }
    }
    export class GetPersonalInfoHandlerA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            info?: PersonalInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get info() {
            return pb_1.Message.getWrapperField(this, PersonalInfo, 1) as PersonalInfo;
        }
        set info(value: PersonalInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            info?: ReturnType<typeof PersonalInfo.prototype.toObject>;
        }): GetPersonalInfoHandlerA {
            const message = new GetPersonalInfoHandlerA({});
            if (data.info != null) {
                message.info = PersonalInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                info?: ReturnType<typeof PersonalInfo.prototype.toObject>;
            } = {};
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_info)
                writer.writeMessage(1, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetPersonalInfoHandlerA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetPersonalInfoHandlerA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.info, () => message.info = PersonalInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetPersonalInfoHandlerA {
            return GetPersonalInfoHandlerA.deserialize(bytes);
        }
    }
    export class GetResidenceInfoHandlerR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): GetResidenceInfoHandlerR {
            const message = new GetResidenceInfoHandlerR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetResidenceInfoHandlerR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetResidenceInfoHandlerR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetResidenceInfoHandlerR {
            return GetResidenceInfoHandlerR.deserialize(bytes);
        }
    }
    export class GetResidenceInfoHandlerA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            info?: ResidenceInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get info() {
            return pb_1.Message.getWrapperField(this, ResidenceInfo, 1) as ResidenceInfo;
        }
        set info(value: ResidenceInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            info?: ReturnType<typeof ResidenceInfo.prototype.toObject>;
        }): GetResidenceInfoHandlerA {
            const message = new GetResidenceInfoHandlerA({});
            if (data.info != null) {
                message.info = ResidenceInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                info?: ReturnType<typeof ResidenceInfo.prototype.toObject>;
            } = {};
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_info)
                writer.writeMessage(1, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetResidenceInfoHandlerA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetResidenceInfoHandlerA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.info, () => message.info = ResidenceInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetResidenceInfoHandlerA {
            return GetResidenceInfoHandlerA.deserialize(bytes);
        }
    }
    export class GetRevenueInfoHandlerR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): GetRevenueInfoHandlerR {
            const message = new GetRevenueInfoHandlerR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetRevenueInfoHandlerR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetRevenueInfoHandlerR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetRevenueInfoHandlerR {
            return GetRevenueInfoHandlerR.deserialize(bytes);
        }
    }
    export class GetRevenueInfoHandlerA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            info?: RevenueInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get info() {
            return pb_1.Message.getWrapperField(this, RevenueInfo, 1) as RevenueInfo;
        }
        set info(value: RevenueInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            info?: ReturnType<typeof RevenueInfo.prototype.toObject>;
        }): GetRevenueInfoHandlerA {
            const message = new GetRevenueInfoHandlerA({});
            if (data.info != null) {
                message.info = RevenueInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                info?: ReturnType<typeof RevenueInfo.prototype.toObject>;
            } = {};
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_info)
                writer.writeMessage(1, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetRevenueInfoHandlerA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetRevenueInfoHandlerA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.info, () => message.info = RevenueInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetRevenueInfoHandlerA {
            return GetRevenueInfoHandlerA.deserialize(bytes);
        }
    }
    export class BindAccountR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            email?: string;
            phone?: string;
            verifyCode?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("verifyCode" in data && data.verifyCode != undefined) {
                    this.verifyCode = data.verifyCode;
                }
            }
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get verifyCode() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set verifyCode(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            email?: string;
            phone?: string;
            verifyCode?: string;
        }): BindAccountR {
            const message = new BindAccountR({});
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.verifyCode != null) {
                message.verifyCode = data.verifyCode;
            }
            return message;
        }
        toObject() {
            const data: {
                email?: string;
                phone?: string;
                verifyCode?: string;
            } = {};
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.verifyCode != null) {
                data.verifyCode = this.verifyCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.email.length)
                writer.writeString(1, this.email);
            if (this.phone.length)
                writer.writeString(2, this.phone);
            if (this.verifyCode.length)
                writer.writeString(3, this.verifyCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BindAccountR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BindAccountR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.email = reader.readString();
                        break;
                    case 2:
                        message.phone = reader.readString();
                        break;
                    case 3:
                        message.verifyCode = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): BindAccountR {
            return BindAccountR.deserialize(bytes);
        }
    }
    export class BindAccountA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): BindAccountA {
            const message = new BindAccountA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BindAccountA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BindAccountA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): BindAccountA {
            return BindAccountA.deserialize(bytes);
        }
    }
    export class ChangeLoginPasswordR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            newPassword?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("newPassword" in data && data.newPassword != undefined) {
                    this.newPassword = data.newPassword;
                }
            }
        }
        get newPassword() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set newPassword(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            newPassword?: string;
        }): ChangeLoginPasswordR {
            const message = new ChangeLoginPasswordR({});
            if (data.newPassword != null) {
                message.newPassword = data.newPassword;
            }
            return message;
        }
        toObject() {
            const data: {
                newPassword?: string;
            } = {};
            if (this.newPassword != null) {
                data.newPassword = this.newPassword;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.newPassword.length)
                writer.writeString(1, this.newPassword);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ChangeLoginPasswordR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ChangeLoginPasswordR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.newPassword = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ChangeLoginPasswordR {
            return ChangeLoginPasswordR.deserialize(bytes);
        }
    }
    export class ChangeLoginPasswordA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): ChangeLoginPasswordA {
            const message = new ChangeLoginPasswordA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ChangeLoginPasswordA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ChangeLoginPasswordA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ChangeLoginPasswordA {
            return ChangeLoginPasswordA.deserialize(bytes);
        }
    }
    export class SendVerifyCodeInHallR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            email?: string;
            phone?: string;
            recvMethod?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("recvMethod" in data && data.recvMethod != undefined) {
                    this.recvMethod = data.recvMethod;
                }
            }
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get recvMethod() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set recvMethod(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            email?: string;
            phone?: string;
            recvMethod?: number;
        }): SendVerifyCodeInHallR {
            const message = new SendVerifyCodeInHallR({});
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.recvMethod != null) {
                message.recvMethod = data.recvMethod;
            }
            return message;
        }
        toObject() {
            const data: {
                email?: string;
                phone?: string;
                recvMethod?: number;
            } = {};
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.recvMethod != null) {
                data.recvMethod = this.recvMethod;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.email.length)
                writer.writeString(1, this.email);
            if (this.phone.length)
                writer.writeString(2, this.phone);
            if (this.recvMethod != 0)
                writer.writeInt32(3, this.recvMethod);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SendVerifyCodeInHallR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SendVerifyCodeInHallR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.email = reader.readString();
                        break;
                    case 2:
                        message.phone = reader.readString();
                        break;
                    case 3:
                        message.recvMethod = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SendVerifyCodeInHallR {
            return SendVerifyCodeInHallR.deserialize(bytes);
        }
    }
    export class SendVerifyCodeInHallA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): SendVerifyCodeInHallA {
            const message = new SendVerifyCodeInHallA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SendVerifyCodeInHallA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SendVerifyCodeInHallA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SendVerifyCodeInHallA {
            return SendVerifyCodeInHallA.deserialize(bytes);
        }
    }
    export class ResendVerifyCodeInHallR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            email?: string;
            phone?: string;
            recvMethod?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("recvMethod" in data && data.recvMethod != undefined) {
                    this.recvMethod = data.recvMethod;
                }
            }
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get recvMethod() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set recvMethod(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            email?: string;
            phone?: string;
            recvMethod?: number;
        }): ResendVerifyCodeInHallR {
            const message = new ResendVerifyCodeInHallR({});
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.recvMethod != null) {
                message.recvMethod = data.recvMethod;
            }
            return message;
        }
        toObject() {
            const data: {
                email?: string;
                phone?: string;
                recvMethod?: number;
            } = {};
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.recvMethod != null) {
                data.recvMethod = this.recvMethod;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.email.length)
                writer.writeString(1, this.email);
            if (this.phone.length)
                writer.writeString(2, this.phone);
            if (this.recvMethod != 0)
                writer.writeInt32(3, this.recvMethod);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResendVerifyCodeInHallR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResendVerifyCodeInHallR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.email = reader.readString();
                        break;
                    case 2:
                        message.phone = reader.readString();
                        break;
                    case 3:
                        message.recvMethod = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ResendVerifyCodeInHallR {
            return ResendVerifyCodeInHallR.deserialize(bytes);
        }
    }
    export class ResendVerifyCodeInHallA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): ResendVerifyCodeInHallA {
            const message = new ResendVerifyCodeInHallA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResendVerifyCodeInHallA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResendVerifyCodeInHallA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ResendVerifyCodeInHallA {
            return ResendVerifyCodeInHallA.deserialize(bytes);
        }
    }
    export class VerifyCodeInHallR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            verifyCode?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("verifyCode" in data && data.verifyCode != undefined) {
                    this.verifyCode = data.verifyCode;
                }
            }
        }
        get verifyCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set verifyCode(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            verifyCode?: string;
        }): VerifyCodeInHallR {
            const message = new VerifyCodeInHallR({});
            if (data.verifyCode != null) {
                message.verifyCode = data.verifyCode;
            }
            return message;
        }
        toObject() {
            const data: {
                verifyCode?: string;
            } = {};
            if (this.verifyCode != null) {
                data.verifyCode = this.verifyCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.verifyCode.length)
                writer.writeString(1, this.verifyCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): VerifyCodeInHallR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new VerifyCodeInHallR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.verifyCode = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): VerifyCodeInHallR {
            return VerifyCodeInHallR.deserialize(bytes);
        }
    }
    export class VerifyCodeInHallA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): VerifyCodeInHallA {
            const message = new VerifyCodeInHallA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): VerifyCodeInHallA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new VerifyCodeInHallA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): VerifyCodeInHallA {
            return VerifyCodeInHallA.deserialize(bytes);
        }
    }
    export class SearchAvailableCurrencyR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            letter?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("letter" in data && data.letter != undefined) {
                    this.letter = data.letter;
                }
            }
        }
        get letter() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set letter(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            letter?: string;
        }): SearchAvailableCurrencyR {
            const message = new SearchAvailableCurrencyR({});
            if (data.letter != null) {
                message.letter = data.letter;
            }
            return message;
        }
        toObject() {
            const data: {
                letter?: string;
            } = {};
            if (this.letter != null) {
                data.letter = this.letter;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.letter.length)
                writer.writeString(1, this.letter);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchAvailableCurrencyR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchAvailableCurrencyR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.letter = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SearchAvailableCurrencyR {
            return SearchAvailableCurrencyR.deserialize(bytes);
        }
    }
    export class SearchAvailableCurrencyA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            currencies?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("currencies" in data && data.currencies != undefined) {
                    this.currencies = data.currencies;
                }
            }
        }
        get currencies() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set currencies(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            currencies?: string[];
        }): SearchAvailableCurrencyA {
            const message = new SearchAvailableCurrencyA({});
            if (data.currencies != null) {
                message.currencies = data.currencies;
            }
            return message;
        }
        toObject() {
            const data: {
                currencies?: string[];
            } = {};
            if (this.currencies != null) {
                data.currencies = this.currencies;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.currencies.length)
                writer.writeRepeatedString(1, this.currencies);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SearchAvailableCurrencyA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SearchAvailableCurrencyA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SearchAvailableCurrencyA {
            return SearchAvailableCurrencyA.deserialize(bytes);
        }
    }
    export class MaintenanceN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            maintenanceTime?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("maintenanceTime" in data && data.maintenanceTime != undefined) {
                    this.maintenanceTime = data.maintenanceTime;
                }
            }
        }
        get maintenanceTime() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set maintenanceTime(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            maintenanceTime?: string;
        }): MaintenanceN {
            const message = new MaintenanceN({});
            if (data.maintenanceTime != null) {
                message.maintenanceTime = data.maintenanceTime;
            }
            return message;
        }
        toObject() {
            const data: {
                maintenanceTime?: string;
            } = {};
            if (this.maintenanceTime != null) {
                data.maintenanceTime = this.maintenanceTime;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.maintenanceTime.length)
                writer.writeString(1, this.maintenanceTime);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): MaintenanceN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new MaintenanceN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.maintenanceTime = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): MaintenanceN {
            return MaintenanceN.deserialize(bytes);
        }
    }
    export class TestDepositR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): TestDepositR {
            const message = new TestDepositR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TestDepositR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TestDepositR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TestDepositR {
            return TestDepositR.deserialize(bytes);
        }
    }
    export class TestDepositA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            curAmount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("curAmount" in data && data.curAmount != undefined) {
                    this.curAmount = data.curAmount;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get curAmount() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set curAmount(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            curAmount?: number;
        }): TestDepositA {
            const message = new TestDepositA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.curAmount != null) {
                message.curAmount = data.curAmount;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                curAmount?: number;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.curAmount != null) {
                data.curAmount = this.curAmount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.curAmount != 0)
                writer.writeDouble(2, this.curAmount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TestDepositA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TestDepositA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.curAmount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TestDepositA {
            return TestDepositA.deserialize(bytes);
        }
    }
    export class WithdrawResult extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            order?: WithdrawOrder;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("order" in data && data.order != undefined) {
                    this.order = data.order;
                }
            }
        }
        get order() {
            return pb_1.Message.getWrapperField(this, WithdrawOrder, 1) as WithdrawOrder;
        }
        set order(value: WithdrawOrder) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_order() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            order?: ReturnType<typeof WithdrawOrder.prototype.toObject>;
        }): WithdrawResult {
            const message = new WithdrawResult({});
            if (data.order != null) {
                message.order = WithdrawOrder.fromObject(data.order);
            }
            return message;
        }
        toObject() {
            const data: {
                order?: ReturnType<typeof WithdrawOrder.prototype.toObject>;
            } = {};
            if (this.order != null) {
                data.order = this.order.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_order)
                writer.writeMessage(1, this.order, () => this.order.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WithdrawResult {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WithdrawResult();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.order, () => message.order = WithdrawOrder.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): WithdrawResult {
            return WithdrawResult.deserialize(bytes);
        }
    }
    export class RegisterGuideN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): RegisterGuideN {
            const message = new RegisterGuideN({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RegisterGuideN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RegisterGuideN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RegisterGuideN {
            return RegisterGuideN.deserialize(bytes);
        }
    }
    export class PurchaseN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            payMoney?: number;
            failedReason?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("payMoney" in data && data.payMoney != undefined) {
                    this.payMoney = data.payMoney;
                }
                if ("failedReason" in data && data.failedReason != undefined) {
                    this.failedReason = data.failedReason;
                }
            }
        }
        get payMoney() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set payMoney(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get failedReason() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set failedReason(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            payMoney?: number;
            failedReason?: string;
        }): PurchaseN {
            const message = new PurchaseN({});
            if (data.payMoney != null) {
                message.payMoney = data.payMoney;
            }
            if (data.failedReason != null) {
                message.failedReason = data.failedReason;
            }
            return message;
        }
        toObject() {
            const data: {
                payMoney?: number;
                failedReason?: string;
            } = {};
            if (this.payMoney != null) {
                data.payMoney = this.payMoney;
            }
            if (this.failedReason != null) {
                data.failedReason = this.failedReason;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.payMoney != 0)
                writer.writeDouble(1, this.payMoney);
            if (this.failedReason.length)
                writer.writeString(2, this.failedReason);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PurchaseN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PurchaseN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.payMoney = reader.readDouble();
                        break;
                    case 2:
                        message.failedReason = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): PurchaseN {
            return PurchaseN.deserialize(bytes);
        }
    }
    export class ApplyWithdrawReq extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            amount?: number;
            bankCardNumber?: string;
            bankCode?: string;
            email?: string;
            mobile?: string;
            currency?: string;
            bankName?: string;
            bankUserName?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("amount" in data && data.amount != undefined) {
                    this.amount = data.amount;
                }
                if ("bankCardNumber" in data && data.bankCardNumber != undefined) {
                    this.bankCardNumber = data.bankCardNumber;
                }
                if ("bankCode" in data && data.bankCode != undefined) {
                    this.bankCode = data.bankCode;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("mobile" in data && data.mobile != undefined) {
                    this.mobile = data.mobile;
                }
                if ("currency" in data && data.currency != undefined) {
                    this.currency = data.currency;
                }
                if ("bankName" in data && data.bankName != undefined) {
                    this.bankName = data.bankName;
                }
                if ("bankUserName" in data && data.bankUserName != undefined) {
                    this.bankUserName = data.bankUserName;
                }
            }
        }
        get amount() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set amount(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get bankCardNumber() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set bankCardNumber(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get bankCode() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set bankCode(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get mobile() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set mobile(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get currency() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set currency(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get bankName() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set bankName(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get bankUserName() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set bankUserName(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data: {
            amount?: number;
            bankCardNumber?: string;
            bankCode?: string;
            email?: string;
            mobile?: string;
            currency?: string;
            bankName?: string;
            bankUserName?: string;
        }): ApplyWithdrawReq {
            const message = new ApplyWithdrawReq({});
            if (data.amount != null) {
                message.amount = data.amount;
            }
            if (data.bankCardNumber != null) {
                message.bankCardNumber = data.bankCardNumber;
            }
            if (data.bankCode != null) {
                message.bankCode = data.bankCode;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.mobile != null) {
                message.mobile = data.mobile;
            }
            if (data.currency != null) {
                message.currency = data.currency;
            }
            if (data.bankName != null) {
                message.bankName = data.bankName;
            }
            if (data.bankUserName != null) {
                message.bankUserName = data.bankUserName;
            }
            return message;
        }
        toObject() {
            const data: {
                amount?: number;
                bankCardNumber?: string;
                bankCode?: string;
                email?: string;
                mobile?: string;
                currency?: string;
                bankName?: string;
                bankUserName?: string;
            } = {};
            if (this.amount != null) {
                data.amount = this.amount;
            }
            if (this.bankCardNumber != null) {
                data.bankCardNumber = this.bankCardNumber;
            }
            if (this.bankCode != null) {
                data.bankCode = this.bankCode;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.mobile != null) {
                data.mobile = this.mobile;
            }
            if (this.currency != null) {
                data.currency = this.currency;
            }
            if (this.bankName != null) {
                data.bankName = this.bankName;
            }
            if (this.bankUserName != null) {
                data.bankUserName = this.bankUserName;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.amount != 0)
                writer.writeDouble(1, this.amount);
            if (this.bankCardNumber.length)
                writer.writeString(2, this.bankCardNumber);
            if (this.bankCode.length)
                writer.writeString(3, this.bankCode);
            if (this.email.length)
                writer.writeString(4, this.email);
            if (this.mobile.length)
                writer.writeString(5, this.mobile);
            if (this.currency.length)
                writer.writeString(6, this.currency);
            if (this.bankName.length)
                writer.writeString(7, this.bankName);
            if (this.bankUserName.length)
                writer.writeString(8, this.bankUserName);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplyWithdrawReq {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplyWithdrawReq();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.amount = reader.readDouble();
                        break;
                    case 2:
                        message.bankCardNumber = reader.readString();
                        break;
                    case 3:
                        message.bankCode = reader.readString();
                        break;
                    case 4:
                        message.email = reader.readString();
                        break;
                    case 5:
                        message.mobile = reader.readString();
                        break;
                    case 6:
                        message.currency = reader.readString();
                        break;
                    case 7:
                        message.bankName = reader.readString();
                        break;
                    case 8:
                        message.bankUserName = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ApplyWithdrawReq {
            return ApplyWithdrawReq.deserialize(bytes);
        }
    }
    export class ApplyWithdrawR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            channel?: number;
            req?: ApplyWithdrawReq;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("channel" in data && data.channel != undefined) {
                    this.channel = data.channel;
                }
                if ("req" in data && data.req != undefined) {
                    this.req = data.req;
                }
            }
        }
        get channel() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set channel(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get req() {
            return pb_1.Message.getWrapperField(this, ApplyWithdrawReq, 2) as ApplyWithdrawReq;
        }
        set req(value: ApplyWithdrawReq) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_req() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            channel?: number;
            req?: ReturnType<typeof ApplyWithdrawReq.prototype.toObject>;
        }): ApplyWithdrawR {
            const message = new ApplyWithdrawR({});
            if (data.channel != null) {
                message.channel = data.channel;
            }
            if (data.req != null) {
                message.req = ApplyWithdrawReq.fromObject(data.req);
            }
            return message;
        }
        toObject() {
            const data: {
                channel?: number;
                req?: ReturnType<typeof ApplyWithdrawReq.prototype.toObject>;
            } = {};
            if (this.channel != null) {
                data.channel = this.channel;
            }
            if (this.req != null) {
                data.req = this.req.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.channel != 0)
                writer.writeInt32(1, this.channel);
            if (this.has_req)
                writer.writeMessage(2, this.req, () => this.req.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplyWithdrawR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplyWithdrawR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.channel = reader.readInt32();
                        break;
                    case 2:
                        reader.readMessage(message.req, () => message.req = ApplyWithdrawReq.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ApplyWithdrawR {
            return ApplyWithdrawR.deserialize(bytes);
        }
    }
    export class ApplyWithdrawA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            curAmount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("curAmount" in data && data.curAmount != undefined) {
                    this.curAmount = data.curAmount;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get curAmount() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set curAmount(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            curAmount?: number;
        }): ApplyWithdrawA {
            const message = new ApplyWithdrawA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.curAmount != null) {
                message.curAmount = data.curAmount;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                curAmount?: number;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.curAmount != null) {
                data.curAmount = this.curAmount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.curAmount != 0)
                writer.writeDouble(2, this.curAmount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ApplyWithdrawA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ApplyWithdrawA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.curAmount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ApplyWithdrawA {
            return ApplyWithdrawA.deserialize(bytes);
        }
    }
    export class WithdrawN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            withdrawMoney?: number;
            failedReason?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("withdrawMoney" in data && data.withdrawMoney != undefined) {
                    this.withdrawMoney = data.withdrawMoney;
                }
                if ("failedReason" in data && data.failedReason != undefined) {
                    this.failedReason = data.failedReason;
                }
            }
        }
        get withdrawMoney() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set withdrawMoney(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get failedReason() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set failedReason(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            withdrawMoney?: number;
            failedReason?: string;
        }): WithdrawN {
            const message = new WithdrawN({});
            if (data.withdrawMoney != null) {
                message.withdrawMoney = data.withdrawMoney;
            }
            if (data.failedReason != null) {
                message.failedReason = data.failedReason;
            }
            return message;
        }
        toObject() {
            const data: {
                withdrawMoney?: number;
                failedReason?: string;
            } = {};
            if (this.withdrawMoney != null) {
                data.withdrawMoney = this.withdrawMoney;
            }
            if (this.failedReason != null) {
                data.failedReason = this.failedReason;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.withdrawMoney != 0)
                writer.writeDouble(1, this.withdrawMoney);
            if (this.failedReason.length)
                writer.writeString(2, this.failedReason);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WithdrawN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WithdrawN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.withdrawMoney = reader.readDouble();
                        break;
                    case 2:
                        message.failedReason = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): WithdrawN {
            return WithdrawN.deserialize(bytes);
        }
    }
    export class FinishGuideR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): FinishGuideR {
            const message = new FinishGuideR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FinishGuideR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FinishGuideR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FinishGuideR {
            return FinishGuideR.deserialize(bytes);
        }
    }
    export class FinishGuideA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): FinishGuideA {
            const message = new FinishGuideA({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FinishGuideA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FinishGuideA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FinishGuideA {
            return FinishGuideA.deserialize(bytes);
        }
    }
    export class GetExchangeRateR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            rateType?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("rateType" in data && data.rateType != undefined) {
                    this.rateType = data.rateType;
                }
            }
        }
        get rateType() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set rateType(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            rateType?: number;
        }): GetExchangeRateR {
            const message = new GetExchangeRateR({});
            if (data.rateType != null) {
                message.rateType = data.rateType;
            }
            return message;
        }
        toObject() {
            const data: {
                rateType?: number;
            } = {};
            if (this.rateType != null) {
                data.rateType = this.rateType;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.rateType != 0)
                writer.writeInt32(1, this.rateType);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetExchangeRateR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetExchangeRateR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.rateType = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetExchangeRateR {
            return GetExchangeRateR.deserialize(bytes);
        }
    }
    export class GetExchangeRateA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            exchangeRate?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("exchangeRate" in data && data.exchangeRate != undefined) {
                    this.exchangeRate = data.exchangeRate;
                }
            }
        }
        get exchangeRate() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set exchangeRate(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            exchangeRate?: number;
        }): GetExchangeRateA {
            const message = new GetExchangeRateA({});
            if (data.exchangeRate != null) {
                message.exchangeRate = data.exchangeRate;
            }
            return message;
        }
        toObject() {
            const data: {
                exchangeRate?: number;
            } = {};
            if (this.exchangeRate != null) {
                data.exchangeRate = this.exchangeRate;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.exchangeRate != 0)
                writer.writeDouble(1, this.exchangeRate);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetExchangeRateA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetExchangeRateA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.exchangeRate = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetExchangeRateA {
            return GetExchangeRateA.deserialize(bytes);
        }
    }
    export class WithdrawOrder extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            userId?: number;
            orderNo?: string;
            platformOrderNo?: string;
            status?: string;
            amount?: number;
            finalAmount?: number;
            channel?: string;
            withdrawTime?: string;
            failReason?: string;
            verifyStatus?: string;
            verifyMsg?: string;
            applyTime?: string;
            id?: number;
            bankCode?: string;
            bankName?: string;
            bankCardNum?: string;
            bankUserName?: string;
            commission?: number;
            beforeUsAmount?: number;
            verifyTime?: string;
            submitIp?: string;
            applyAmount?: number;
            phone?: string;
            email?: string;
            applyUsAmount?: number;
            usAmount?: number;
            exchangeRate?: number;
            afterUsAmount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("userId" in data && data.userId != undefined) {
                    this.userId = data.userId;
                }
                if ("orderNo" in data && data.orderNo != undefined) {
                    this.orderNo = data.orderNo;
                }
                if ("platformOrderNo" in data && data.platformOrderNo != undefined) {
                    this.platformOrderNo = data.platformOrderNo;
                }
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
                if ("amount" in data && data.amount != undefined) {
                    this.amount = data.amount;
                }
                if ("finalAmount" in data && data.finalAmount != undefined) {
                    this.finalAmount = data.finalAmount;
                }
                if ("channel" in data && data.channel != undefined) {
                    this.channel = data.channel;
                }
                if ("withdrawTime" in data && data.withdrawTime != undefined) {
                    this.withdrawTime = data.withdrawTime;
                }
                if ("failReason" in data && data.failReason != undefined) {
                    this.failReason = data.failReason;
                }
                if ("verifyStatus" in data && data.verifyStatus != undefined) {
                    this.verifyStatus = data.verifyStatus;
                }
                if ("verifyMsg" in data && data.verifyMsg != undefined) {
                    this.verifyMsg = data.verifyMsg;
                }
                if ("applyTime" in data && data.applyTime != undefined) {
                    this.applyTime = data.applyTime;
                }
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("bankCode" in data && data.bankCode != undefined) {
                    this.bankCode = data.bankCode;
                }
                if ("bankName" in data && data.bankName != undefined) {
                    this.bankName = data.bankName;
                }
                if ("bankCardNum" in data && data.bankCardNum != undefined) {
                    this.bankCardNum = data.bankCardNum;
                }
                if ("bankUserName" in data && data.bankUserName != undefined) {
                    this.bankUserName = data.bankUserName;
                }
                if ("commission" in data && data.commission != undefined) {
                    this.commission = data.commission;
                }
                if ("beforeUsAmount" in data && data.beforeUsAmount != undefined) {
                    this.beforeUsAmount = data.beforeUsAmount;
                }
                if ("verifyTime" in data && data.verifyTime != undefined) {
                    this.verifyTime = data.verifyTime;
                }
                if ("submitIp" in data && data.submitIp != undefined) {
                    this.submitIp = data.submitIp;
                }
                if ("applyAmount" in data && data.applyAmount != undefined) {
                    this.applyAmount = data.applyAmount;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("applyUsAmount" in data && data.applyUsAmount != undefined) {
                    this.applyUsAmount = data.applyUsAmount;
                }
                if ("usAmount" in data && data.usAmount != undefined) {
                    this.usAmount = data.usAmount;
                }
                if ("exchangeRate" in data && data.exchangeRate != undefined) {
                    this.exchangeRate = data.exchangeRate;
                }
                if ("afterUsAmount" in data && data.afterUsAmount != undefined) {
                    this.afterUsAmount = data.afterUsAmount;
                }
            }
        }
        get userId() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set userId(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get orderNo() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set orderNo(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get platformOrderNo() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set platformOrderNo(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set status(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get amount() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set amount(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get finalAmount() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set finalAmount(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get channel() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set channel(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        get withdrawTime() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set withdrawTime(value: string) {
            pb_1.Message.setField(this, 8, value);
        }
        get failReason() {
            return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
        }
        set failReason(value: string) {
            pb_1.Message.setField(this, 9, value);
        }
        get verifyStatus() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set verifyStatus(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get verifyMsg() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set verifyMsg(value: string) {
            pb_1.Message.setField(this, 11, value);
        }
        get applyTime() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set applyTime(value: string) {
            pb_1.Message.setField(this, 12, value);
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
        }
        set id(value: number) {
            pb_1.Message.setField(this, 13, value);
        }
        get bankCode() {
            return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
        }
        set bankCode(value: string) {
            pb_1.Message.setField(this, 14, value);
        }
        get bankName() {
            return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
        }
        set bankName(value: string) {
            pb_1.Message.setField(this, 15, value);
        }
        get bankCardNum() {
            return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
        }
        set bankCardNum(value: string) {
            pb_1.Message.setField(this, 16, value);
        }
        get bankUserName() {
            return pb_1.Message.getFieldWithDefault(this, 17, "") as string;
        }
        set bankUserName(value: string) {
            pb_1.Message.setField(this, 17, value);
        }
        get commission() {
            return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
        }
        set commission(value: number) {
            pb_1.Message.setField(this, 18, value);
        }
        get beforeUsAmount() {
            return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
        }
        set beforeUsAmount(value: number) {
            pb_1.Message.setField(this, 19, value);
        }
        get verifyTime() {
            return pb_1.Message.getFieldWithDefault(this, 20, "") as string;
        }
        set verifyTime(value: string) {
            pb_1.Message.setField(this, 20, value);
        }
        get submitIp() {
            return pb_1.Message.getFieldWithDefault(this, 21, "") as string;
        }
        set submitIp(value: string) {
            pb_1.Message.setField(this, 21, value);
        }
        get applyAmount() {
            return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
        }
        set applyAmount(value: number) {
            pb_1.Message.setField(this, 22, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 23, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 23, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 24, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 24, value);
        }
        get applyUsAmount() {
            return pb_1.Message.getFieldWithDefault(this, 25, 0) as number;
        }
        set applyUsAmount(value: number) {
            pb_1.Message.setField(this, 25, value);
        }
        get usAmount() {
            return pb_1.Message.getFieldWithDefault(this, 26, 0) as number;
        }
        set usAmount(value: number) {
            pb_1.Message.setField(this, 26, value);
        }
        get exchangeRate() {
            return pb_1.Message.getFieldWithDefault(this, 27, 0) as number;
        }
        set exchangeRate(value: number) {
            pb_1.Message.setField(this, 27, value);
        }
        get afterUsAmount() {
            return pb_1.Message.getFieldWithDefault(this, 28, 0) as number;
        }
        set afterUsAmount(value: number) {
            pb_1.Message.setField(this, 28, value);
        }
        static fromObject(data: {
            userId?: number;
            orderNo?: string;
            platformOrderNo?: string;
            status?: string;
            amount?: number;
            finalAmount?: number;
            channel?: string;
            withdrawTime?: string;
            failReason?: string;
            verifyStatus?: string;
            verifyMsg?: string;
            applyTime?: string;
            id?: number;
            bankCode?: string;
            bankName?: string;
            bankCardNum?: string;
            bankUserName?: string;
            commission?: number;
            beforeUsAmount?: number;
            verifyTime?: string;
            submitIp?: string;
            applyAmount?: number;
            phone?: string;
            email?: string;
            applyUsAmount?: number;
            usAmount?: number;
            exchangeRate?: number;
            afterUsAmount?: number;
        }): WithdrawOrder {
            const message = new WithdrawOrder({});
            if (data.userId != null) {
                message.userId = data.userId;
            }
            if (data.orderNo != null) {
                message.orderNo = data.orderNo;
            }
            if (data.platformOrderNo != null) {
                message.platformOrderNo = data.platformOrderNo;
            }
            if (data.status != null) {
                message.status = data.status;
            }
            if (data.amount != null) {
                message.amount = data.amount;
            }
            if (data.finalAmount != null) {
                message.finalAmount = data.finalAmount;
            }
            if (data.channel != null) {
                message.channel = data.channel;
            }
            if (data.withdrawTime != null) {
                message.withdrawTime = data.withdrawTime;
            }
            if (data.failReason != null) {
                message.failReason = data.failReason;
            }
            if (data.verifyStatus != null) {
                message.verifyStatus = data.verifyStatus;
            }
            if (data.verifyMsg != null) {
                message.verifyMsg = data.verifyMsg;
            }
            if (data.applyTime != null) {
                message.applyTime = data.applyTime;
            }
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.bankCode != null) {
                message.bankCode = data.bankCode;
            }
            if (data.bankName != null) {
                message.bankName = data.bankName;
            }
            if (data.bankCardNum != null) {
                message.bankCardNum = data.bankCardNum;
            }
            if (data.bankUserName != null) {
                message.bankUserName = data.bankUserName;
            }
            if (data.commission != null) {
                message.commission = data.commission;
            }
            if (data.beforeUsAmount != null) {
                message.beforeUsAmount = data.beforeUsAmount;
            }
            if (data.verifyTime != null) {
                message.verifyTime = data.verifyTime;
            }
            if (data.submitIp != null) {
                message.submitIp = data.submitIp;
            }
            if (data.applyAmount != null) {
                message.applyAmount = data.applyAmount;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.applyUsAmount != null) {
                message.applyUsAmount = data.applyUsAmount;
            }
            if (data.usAmount != null) {
                message.usAmount = data.usAmount;
            }
            if (data.exchangeRate != null) {
                message.exchangeRate = data.exchangeRate;
            }
            if (data.afterUsAmount != null) {
                message.afterUsAmount = data.afterUsAmount;
            }
            return message;
        }
        toObject() {
            const data: {
                userId?: number;
                orderNo?: string;
                platformOrderNo?: string;
                status?: string;
                amount?: number;
                finalAmount?: number;
                channel?: string;
                withdrawTime?: string;
                failReason?: string;
                verifyStatus?: string;
                verifyMsg?: string;
                applyTime?: string;
                id?: number;
                bankCode?: string;
                bankName?: string;
                bankCardNum?: string;
                bankUserName?: string;
                commission?: number;
                beforeUsAmount?: number;
                verifyTime?: string;
                submitIp?: string;
                applyAmount?: number;
                phone?: string;
                email?: string;
                applyUsAmount?: number;
                usAmount?: number;
                exchangeRate?: number;
                afterUsAmount?: number;
            } = {};
            if (this.userId != null) {
                data.userId = this.userId;
            }
            if (this.orderNo != null) {
                data.orderNo = this.orderNo;
            }
            if (this.platformOrderNo != null) {
                data.platformOrderNo = this.platformOrderNo;
            }
            if (this.status != null) {
                data.status = this.status;
            }
            if (this.amount != null) {
                data.amount = this.amount;
            }
            if (this.finalAmount != null) {
                data.finalAmount = this.finalAmount;
            }
            if (this.channel != null) {
                data.channel = this.channel;
            }
            if (this.withdrawTime != null) {
                data.withdrawTime = this.withdrawTime;
            }
            if (this.failReason != null) {
                data.failReason = this.failReason;
            }
            if (this.verifyStatus != null) {
                data.verifyStatus = this.verifyStatus;
            }
            if (this.verifyMsg != null) {
                data.verifyMsg = this.verifyMsg;
            }
            if (this.applyTime != null) {
                data.applyTime = this.applyTime;
            }
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.bankCode != null) {
                data.bankCode = this.bankCode;
            }
            if (this.bankName != null) {
                data.bankName = this.bankName;
            }
            if (this.bankCardNum != null) {
                data.bankCardNum = this.bankCardNum;
            }
            if (this.bankUserName != null) {
                data.bankUserName = this.bankUserName;
            }
            if (this.commission != null) {
                data.commission = this.commission;
            }
            if (this.beforeUsAmount != null) {
                data.beforeUsAmount = this.beforeUsAmount;
            }
            if (this.verifyTime != null) {
                data.verifyTime = this.verifyTime;
            }
            if (this.submitIp != null) {
                data.submitIp = this.submitIp;
            }
            if (this.applyAmount != null) {
                data.applyAmount = this.applyAmount;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.applyUsAmount != null) {
                data.applyUsAmount = this.applyUsAmount;
            }
            if (this.usAmount != null) {
                data.usAmount = this.usAmount;
            }
            if (this.exchangeRate != null) {
                data.exchangeRate = this.exchangeRate;
            }
            if (this.afterUsAmount != null) {
                data.afterUsAmount = this.afterUsAmount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.userId != 0)
                writer.writeUint64(1, this.userId);
            if (this.orderNo.length)
                writer.writeString(2, this.orderNo);
            if (this.platformOrderNo.length)
                writer.writeString(3, this.platformOrderNo);
            if (this.status.length)
                writer.writeString(4, this.status);
            if (this.amount != 0)
                writer.writeDouble(5, this.amount);
            if (this.finalAmount != 0)
                writer.writeDouble(6, this.finalAmount);
            if (this.channel.length)
                writer.writeString(7, this.channel);
            if (this.withdrawTime.length)
                writer.writeString(8, this.withdrawTime);
            if (this.failReason.length)
                writer.writeString(9, this.failReason);
            if (this.verifyStatus.length)
                writer.writeString(10, this.verifyStatus);
            if (this.verifyMsg.length)
                writer.writeString(11, this.verifyMsg);
            if (this.applyTime.length)
                writer.writeString(12, this.applyTime);
            if (this.id != 0)
                writer.writeUint64(13, this.id);
            if (this.bankCode.length)
                writer.writeString(14, this.bankCode);
            if (this.bankName.length)
                writer.writeString(15, this.bankName);
            if (this.bankCardNum.length)
                writer.writeString(16, this.bankCardNum);
            if (this.bankUserName.length)
                writer.writeString(17, this.bankUserName);
            if (this.commission != 0)
                writer.writeDouble(18, this.commission);
            if (this.beforeUsAmount != 0)
                writer.writeDouble(19, this.beforeUsAmount);
            if (this.verifyTime.length)
                writer.writeString(20, this.verifyTime);
            if (this.submitIp.length)
                writer.writeString(21, this.submitIp);
            if (this.applyAmount != 0)
                writer.writeDouble(22, this.applyAmount);
            if (this.phone.length)
                writer.writeString(23, this.phone);
            if (this.email.length)
                writer.writeString(24, this.email);
            if (this.applyUsAmount != 0)
                writer.writeDouble(25, this.applyUsAmount);
            if (this.usAmount != 0)
                writer.writeDouble(26, this.usAmount);
            if (this.exchangeRate != 0)
                writer.writeDouble(27, this.exchangeRate);
            if (this.afterUsAmount != 0)
                writer.writeDouble(28, this.afterUsAmount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WithdrawOrder {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WithdrawOrder();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.userId = reader.readUint64();
                        break;
                    case 2:
                        message.orderNo = reader.readString();
                        break;
                    case 3:
                        message.platformOrderNo = reader.readString();
                        break;
                    case 4:
                        message.status = reader.readString();
                        break;
                    case 5:
                        message.amount = reader.readDouble();
                        break;
                    case 6:
                        message.finalAmount = reader.readDouble();
                        break;
                    case 7:
                        message.channel = reader.readString();
                        break;
                    case 8:
                        message.withdrawTime = reader.readString();
                        break;
                    case 9:
                        message.failReason = reader.readString();
                        break;
                    case 10:
                        message.verifyStatus = reader.readString();
                        break;
                    case 11:
                        message.verifyMsg = reader.readString();
                        break;
                    case 12:
                        message.applyTime = reader.readString();
                        break;
                    case 13:
                        message.id = reader.readUint64();
                        break;
                    case 14:
                        message.bankCode = reader.readString();
                        break;
                    case 15:
                        message.bankName = reader.readString();
                        break;
                    case 16:
                        message.bankCardNum = reader.readString();
                        break;
                    case 17:
                        message.bankUserName = reader.readString();
                        break;
                    case 18:
                        message.commission = reader.readDouble();
                        break;
                    case 19:
                        message.beforeUsAmount = reader.readDouble();
                        break;
                    case 20:
                        message.verifyTime = reader.readString();
                        break;
                    case 21:
                        message.submitIp = reader.readString();
                        break;
                    case 22:
                        message.applyAmount = reader.readDouble();
                        break;
                    case 23:
                        message.phone = reader.readString();
                        break;
                    case 24:
                        message.email = reader.readString();
                        break;
                    case 25:
                        message.applyUsAmount = reader.readDouble();
                        break;
                    case 26:
                        message.usAmount = reader.readDouble();
                        break;
                    case 27:
                        message.exchangeRate = reader.readDouble();
                        break;
                    case 28:
                        message.afterUsAmount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): WithdrawOrder {
            return WithdrawOrder.deserialize(bytes);
        }
    }
    export class FindWithdrawOrdersR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            status?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("status" in data && data.status != undefined) {
                    this.status = data.status;
                }
            }
        }
        get status() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set status(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            status?: string;
        }): FindWithdrawOrdersR {
            const message = new FindWithdrawOrdersR({});
            if (data.status != null) {
                message.status = data.status;
            }
            return message;
        }
        toObject() {
            const data: {
                status?: string;
            } = {};
            if (this.status != null) {
                data.status = this.status;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.status.length)
                writer.writeString(1, this.status);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindWithdrawOrdersR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindWithdrawOrdersR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.status = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindWithdrawOrdersR {
            return FindWithdrawOrdersR.deserialize(bytes);
        }
    }
    export class FindWithdrawOrdersA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            orders?: WithdrawOrder[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("orders" in data && data.orders != undefined) {
                    this.orders = data.orders;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get orders() {
            return pb_1.Message.getRepeatedWrapperField(this, WithdrawOrder, 2) as WithdrawOrder[];
        }
        set orders(value: WithdrawOrder[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            orders?: ReturnType<typeof WithdrawOrder.prototype.toObject>[];
        }): FindWithdrawOrdersA {
            const message = new FindWithdrawOrdersA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.orders != null) {
                message.orders = data.orders.map(item => WithdrawOrder.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                orders?: ReturnType<typeof WithdrawOrder.prototype.toObject>[];
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.orders != null) {
                data.orders = this.orders.map((item: WithdrawOrder) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.orders.length)
                writer.writeRepeatedMessage(2, this.orders, (item: WithdrawOrder) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindWithdrawOrdersA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindWithdrawOrdersA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.orders, () => pb_1.Message.addToRepeatedWrapperField(message, 2, WithdrawOrder.deserialize(reader), WithdrawOrder));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindWithdrawOrdersA {
            return FindWithdrawOrdersA.deserialize(bytes);
        }
    }
    export class ChannelPayType extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            name?: string;
            code?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("code" in data && data.code != undefined) {
                    this.code = data.code;
                }
            }
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get code() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set code(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            name?: string;
            code?: string;
        }): ChannelPayType {
            const message = new ChannelPayType({});
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.code != null) {
                message.code = data.code;
            }
            return message;
        }
        toObject() {
            const data: {
                name?: string;
                code?: string;
            } = {};
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.code != null) {
                data.code = this.code;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.name.length)
                writer.writeString(1, this.name);
            if (this.code.length)
                writer.writeString(2, this.code);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ChannelPayType {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ChannelPayType();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.name = reader.readString();
                        break;
                    case 2:
                        message.code = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ChannelPayType {
            return ChannelPayType.deserialize(bytes);
        }
    }
    export class FindChannelPayTypesR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            channel?: number;
            currency?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("channel" in data && data.channel != undefined) {
                    this.channel = data.channel;
                }
                if ("currency" in data && data.currency != undefined) {
                    this.currency = data.currency;
                }
            }
        }
        get channel() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set channel(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get currency() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set currency(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            channel?: number;
            currency?: string;
        }): FindChannelPayTypesR {
            const message = new FindChannelPayTypesR({});
            if (data.channel != null) {
                message.channel = data.channel;
            }
            if (data.currency != null) {
                message.currency = data.currency;
            }
            return message;
        }
        toObject() {
            const data: {
                channel?: number;
                currency?: string;
            } = {};
            if (this.channel != null) {
                data.channel = this.channel;
            }
            if (this.currency != null) {
                data.currency = this.currency;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.channel != 0)
                writer.writeInt32(1, this.channel);
            if (this.currency.length)
                writer.writeString(2, this.currency);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindChannelPayTypesR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindChannelPayTypesR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.channel = reader.readInt32();
                        break;
                    case 2:
                        message.currency = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindChannelPayTypesR {
            return FindChannelPayTypesR.deserialize(bytes);
        }
    }
    export class FindChannelPayTypesA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            payTypes?: ChannelPayType[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("payTypes" in data && data.payTypes != undefined) {
                    this.payTypes = data.payTypes;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get payTypes() {
            return pb_1.Message.getRepeatedWrapperField(this, ChannelPayType, 2) as ChannelPayType[];
        }
        set payTypes(value: ChannelPayType[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            payTypes?: ReturnType<typeof ChannelPayType.prototype.toObject>[];
        }): FindChannelPayTypesA {
            const message = new FindChannelPayTypesA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.payTypes != null) {
                message.payTypes = data.payTypes.map(item => ChannelPayType.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                payTypes?: ReturnType<typeof ChannelPayType.prototype.toObject>[];
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.payTypes != null) {
                data.payTypes = this.payTypes.map((item: ChannelPayType) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.payTypes.length)
                writer.writeRepeatedMessage(2, this.payTypes, (item: ChannelPayType) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindChannelPayTypesA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindChannelPayTypesA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.payTypes, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ChannelPayType.deserialize(reader), ChannelPayType));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindChannelPayTypesA {
            return FindChannelPayTypesA.deserialize(bytes);
        }
    }
    export class WithdrawBank extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            name?: string;
            code?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("code" in data && data.code != undefined) {
                    this.code = data.code;
                }
            }
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get code() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set code(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            name?: string;
            code?: string;
        }): WithdrawBank {
            const message = new WithdrawBank({});
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.code != null) {
                message.code = data.code;
            }
            return message;
        }
        toObject() {
            const data: {
                name?: string;
                code?: string;
            } = {};
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.code != null) {
                data.code = this.code;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.name.length)
                writer.writeString(1, this.name);
            if (this.code.length)
                writer.writeString(2, this.code);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WithdrawBank {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WithdrawBank();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.name = reader.readString();
                        break;
                    case 2:
                        message.code = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): WithdrawBank {
            return WithdrawBank.deserialize(bytes);
        }
    }
    export class FindWithdrawBanksR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            channel?: number;
            currency?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("channel" in data && data.channel != undefined) {
                    this.channel = data.channel;
                }
                if ("currency" in data && data.currency != undefined) {
                    this.currency = data.currency;
                }
            }
        }
        get channel() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set channel(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get currency() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set currency(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            channel?: number;
            currency?: string;
        }): FindWithdrawBanksR {
            const message = new FindWithdrawBanksR({});
            if (data.channel != null) {
                message.channel = data.channel;
            }
            if (data.currency != null) {
                message.currency = data.currency;
            }
            return message;
        }
        toObject() {
            const data: {
                channel?: number;
                currency?: string;
            } = {};
            if (this.channel != null) {
                data.channel = this.channel;
            }
            if (this.currency != null) {
                data.currency = this.currency;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.channel != 0)
                writer.writeInt32(1, this.channel);
            if (this.currency.length)
                writer.writeString(2, this.currency);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindWithdrawBanksR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindWithdrawBanksR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.channel = reader.readInt32();
                        break;
                    case 2:
                        message.currency = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindWithdrawBanksR {
            return FindWithdrawBanksR.deserialize(bytes);
        }
    }
    export class FindWithdrawBanksA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            banks?: WithdrawBank[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("banks" in data && data.banks != undefined) {
                    this.banks = data.banks;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get banks() {
            return pb_1.Message.getRepeatedWrapperField(this, WithdrawBank, 2) as WithdrawBank[];
        }
        set banks(value: WithdrawBank[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            banks?: ReturnType<typeof WithdrawBank.prototype.toObject>[];
        }): FindWithdrawBanksA {
            const message = new FindWithdrawBanksA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.banks != null) {
                message.banks = data.banks.map(item => WithdrawBank.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                banks?: ReturnType<typeof WithdrawBank.prototype.toObject>[];
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.banks != null) {
                data.banks = this.banks.map((item: WithdrawBank) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.banks.length)
                writer.writeRepeatedMessage(2, this.banks, (item: WithdrawBank) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindWithdrawBanksA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindWithdrawBanksA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.banks, () => pb_1.Message.addToRepeatedWrapperField(message, 2, WithdrawBank.deserialize(reader), WithdrawBank));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindWithdrawBanksA {
            return FindWithdrawBanksA.deserialize(bytes);
        }
    }
    export class BtcxCandleInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            openPrice?: number;
            closePrice?: number;
            highestPrice?: number;
            lowestPrice?: number;
            startTime?: string;
            endTime?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("openPrice" in data && data.openPrice != undefined) {
                    this.openPrice = data.openPrice;
                }
                if ("closePrice" in data && data.closePrice != undefined) {
                    this.closePrice = data.closePrice;
                }
                if ("highestPrice" in data && data.highestPrice != undefined) {
                    this.highestPrice = data.highestPrice;
                }
                if ("lowestPrice" in data && data.lowestPrice != undefined) {
                    this.lowestPrice = data.lowestPrice;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
            }
        }
        get openPrice() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set openPrice(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get closePrice() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set closePrice(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get highestPrice() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set highestPrice(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get lowestPrice() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set lowestPrice(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set startTime(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set endTime(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            openPrice?: number;
            closePrice?: number;
            highestPrice?: number;
            lowestPrice?: number;
            startTime?: string;
            endTime?: string;
        }): BtcxCandleInfo {
            const message = new BtcxCandleInfo({});
            if (data.openPrice != null) {
                message.openPrice = data.openPrice;
            }
            if (data.closePrice != null) {
                message.closePrice = data.closePrice;
            }
            if (data.highestPrice != null) {
                message.highestPrice = data.highestPrice;
            }
            if (data.lowestPrice != null) {
                message.lowestPrice = data.lowestPrice;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            return message;
        }
        toObject() {
            const data: {
                openPrice?: number;
                closePrice?: number;
                highestPrice?: number;
                lowestPrice?: number;
                startTime?: string;
                endTime?: string;
            } = {};
            if (this.openPrice != null) {
                data.openPrice = this.openPrice;
            }
            if (this.closePrice != null) {
                data.closePrice = this.closePrice;
            }
            if (this.highestPrice != null) {
                data.highestPrice = this.highestPrice;
            }
            if (this.lowestPrice != null) {
                data.lowestPrice = this.lowestPrice;
            }
            if (this.startTime != null) {
                data.startTime = this.startTime;
            }
            if (this.endTime != null) {
                data.endTime = this.endTime;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.openPrice != 0)
                writer.writeDouble(1, this.openPrice);
            if (this.closePrice != 0)
                writer.writeDouble(2, this.closePrice);
            if (this.highestPrice != 0)
                writer.writeDouble(3, this.highestPrice);
            if (this.lowestPrice != 0)
                writer.writeDouble(4, this.lowestPrice);
            if (this.startTime.length)
                writer.writeString(5, this.startTime);
            if (this.endTime.length)
                writer.writeString(6, this.endTime);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BtcxCandleInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BtcxCandleInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.openPrice = reader.readDouble();
                        break;
                    case 2:
                        message.closePrice = reader.readDouble();
                        break;
                    case 3:
                        message.highestPrice = reader.readDouble();
                        break;
                    case 4:
                        message.lowestPrice = reader.readDouble();
                        break;
                    case 5:
                        message.startTime = reader.readString();
                        break;
                    case 6:
                        message.endTime = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): BtcxCandleInfo {
            return BtcxCandleInfo.deserialize(bytes);
        }
    }
    export class FindBtcxCandleInfosR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            startTime?: string;
            endTime?: string;
            interval?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
                if ("interval" in data && data.interval != undefined) {
                    this.interval = data.interval;
                }
            }
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set startTime(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set endTime(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get interval() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set interval(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            startTime?: string;
            endTime?: string;
            interval?: number;
        }): FindBtcxCandleInfosR {
            const message = new FindBtcxCandleInfosR({});
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            if (data.interval != null) {
                message.interval = data.interval;
            }
            return message;
        }
        toObject() {
            const data: {
                startTime?: string;
                endTime?: string;
                interval?: number;
            } = {};
            if (this.startTime != null) {
                data.startTime = this.startTime;
            }
            if (this.endTime != null) {
                data.endTime = this.endTime;
            }
            if (this.interval != null) {
                data.interval = this.interval;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.startTime.length)
                writer.writeString(1, this.startTime);
            if (this.endTime.length)
                writer.writeString(2, this.endTime);
            if (this.interval != 0)
                writer.writeInt64(3, this.interval);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindBtcxCandleInfosR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindBtcxCandleInfosR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.startTime = reader.readString();
                        break;
                    case 2:
                        message.endTime = reader.readString();
                        break;
                    case 3:
                        message.interval = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindBtcxCandleInfosR {
            return FindBtcxCandleInfosR.deserialize(bytes);
        }
    }
    export class FindBtcxCandleInfosA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            infos?: BtcxCandleInfo[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("infos" in data && data.infos != undefined) {
                    this.infos = data.infos;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get infos() {
            return pb_1.Message.getRepeatedWrapperField(this, BtcxCandleInfo, 2) as BtcxCandleInfo[];
        }
        set infos(value: BtcxCandleInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            infos?: ReturnType<typeof BtcxCandleInfo.prototype.toObject>[];
        }): FindBtcxCandleInfosA {
            const message = new FindBtcxCandleInfosA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.infos != null) {
                message.infos = data.infos.map(item => BtcxCandleInfo.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                infos?: ReturnType<typeof BtcxCandleInfo.prototype.toObject>[];
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.infos != null) {
                data.infos = this.infos.map((item: BtcxCandleInfo) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.infos.length)
                writer.writeRepeatedMessage(2, this.infos, (item: BtcxCandleInfo) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindBtcxCandleInfosA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindBtcxCandleInfosA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.infos, () => pb_1.Message.addToRepeatedWrapperField(message, 2, BtcxCandleInfo.deserialize(reader), BtcxCandleInfo));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindBtcxCandleInfosA {
            return FindBtcxCandleInfosA.deserialize(bytes);
        }
    }
    export class LatestBtcxCandleInfoN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            info?: BtcxCandleInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get info() {
            return pb_1.Message.getWrapperField(this, BtcxCandleInfo, 1) as BtcxCandleInfo;
        }
        set info(value: BtcxCandleInfo) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 1) != null;
        }
        static fromObject(data: {
            info?: ReturnType<typeof BtcxCandleInfo.prototype.toObject>;
        }): LatestBtcxCandleInfoN {
            const message = new LatestBtcxCandleInfoN({});
            if (data.info != null) {
                message.info = BtcxCandleInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                info?: ReturnType<typeof BtcxCandleInfo.prototype.toObject>;
            } = {};
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_info)
                writer.writeMessage(1, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LatestBtcxCandleInfoN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LatestBtcxCandleInfoN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.info, () => message.info = BtcxCandleInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): LatestBtcxCandleInfoN {
            return LatestBtcxCandleInfoN.deserialize(bytes);
        }
    }
    export class GetLatestBtcxCandleInfoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            interval?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("interval" in data && data.interval != undefined) {
                    this.interval = data.interval;
                }
            }
        }
        get interval() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set interval(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            interval?: number;
        }): GetLatestBtcxCandleInfoR {
            const message = new GetLatestBtcxCandleInfoR({});
            if (data.interval != null) {
                message.interval = data.interval;
            }
            return message;
        }
        toObject() {
            const data: {
                interval?: number;
            } = {};
            if (this.interval != null) {
                data.interval = this.interval;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.interval != 0)
                writer.writeInt64(1, this.interval);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetLatestBtcxCandleInfoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetLatestBtcxCandleInfoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.interval = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetLatestBtcxCandleInfoR {
            return GetLatestBtcxCandleInfoR.deserialize(bytes);
        }
    }
    export class GetLatestBtcxCandleInfoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            info?: BtcxCandleInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get info() {
            return pb_1.Message.getWrapperField(this, BtcxCandleInfo, 2) as BtcxCandleInfo;
        }
        set info(value: BtcxCandleInfo) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            info?: ReturnType<typeof BtcxCandleInfo.prototype.toObject>;
        }): GetLatestBtcxCandleInfoA {
            const message = new GetLatestBtcxCandleInfoA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.info != null) {
                message.info = BtcxCandleInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                info?: ReturnType<typeof BtcxCandleInfo.prototype.toObject>;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.has_info)
                writer.writeMessage(2, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetLatestBtcxCandleInfoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetLatestBtcxCandleInfoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.info, () => message.info = BtcxCandleInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetLatestBtcxCandleInfoA {
            return GetLatestBtcxCandleInfoA.deserialize(bytes);
        }
    }
    export class SwitchLiveDemoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): SwitchLiveDemoR {
            const message = new SwitchLiveDemoR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SwitchLiveDemoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SwitchLiveDemoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SwitchLiveDemoR {
            return SwitchLiveDemoR.deserialize(bytes);
        }
    }
    export class SwitchLiveDemoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("userId" in data && data.userId != undefined) {
                    this.userId = data.userId;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get userId() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set userId(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
        }): SwitchLiveDemoA {
            const message = new SwitchLiveDemoA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.userId != null) {
                message.userId = data.userId;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                userId?: number;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.userId != null) {
                data.userId = this.userId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.userId != 0)
                writer.writeUint64(2, this.userId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SwitchLiveDemoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SwitchLiveDemoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.userId = reader.readUint64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SwitchLiveDemoA {
            return SwitchLiveDemoA.deserialize(bytes);
        }
    }
    export class InstrumentCandleInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            openPrice?: number;
            closePrice?: number;
            highestPrice?: number;
            lowestPrice?: number;
            startTime?: string;
            endTime?: string;
            instId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("openPrice" in data && data.openPrice != undefined) {
                    this.openPrice = data.openPrice;
                }
                if ("closePrice" in data && data.closePrice != undefined) {
                    this.closePrice = data.closePrice;
                }
                if ("highestPrice" in data && data.highestPrice != undefined) {
                    this.highestPrice = data.highestPrice;
                }
                if ("lowestPrice" in data && data.lowestPrice != undefined) {
                    this.lowestPrice = data.lowestPrice;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
                if ("instId" in data && data.instId != undefined) {
                    this.instId = data.instId;
                }
            }
        }
        get openPrice() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set openPrice(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get closePrice() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set closePrice(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get highestPrice() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set highestPrice(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get lowestPrice() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set lowestPrice(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set startTime(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set endTime(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get instId() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set instId(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data: {
            openPrice?: number;
            closePrice?: number;
            highestPrice?: number;
            lowestPrice?: number;
            startTime?: string;
            endTime?: string;
            instId?: string;
        }): InstrumentCandleInfo {
            const message = new InstrumentCandleInfo({});
            if (data.openPrice != null) {
                message.openPrice = data.openPrice;
            }
            if (data.closePrice != null) {
                message.closePrice = data.closePrice;
            }
            if (data.highestPrice != null) {
                message.highestPrice = data.highestPrice;
            }
            if (data.lowestPrice != null) {
                message.lowestPrice = data.lowestPrice;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            if (data.instId != null) {
                message.instId = data.instId;
            }
            return message;
        }
        toObject() {
            const data: {
                openPrice?: number;
                closePrice?: number;
                highestPrice?: number;
                lowestPrice?: number;
                startTime?: string;
                endTime?: string;
                instId?: string;
            } = {};
            if (this.openPrice != null) {
                data.openPrice = this.openPrice;
            }
            if (this.closePrice != null) {
                data.closePrice = this.closePrice;
            }
            if (this.highestPrice != null) {
                data.highestPrice = this.highestPrice;
            }
            if (this.lowestPrice != null) {
                data.lowestPrice = this.lowestPrice;
            }
            if (this.startTime != null) {
                data.startTime = this.startTime;
            }
            if (this.endTime != null) {
                data.endTime = this.endTime;
            }
            if (this.instId != null) {
                data.instId = this.instId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.openPrice != 0)
                writer.writeDouble(1, this.openPrice);
            if (this.closePrice != 0)
                writer.writeDouble(2, this.closePrice);
            if (this.highestPrice != 0)
                writer.writeDouble(3, this.highestPrice);
            if (this.lowestPrice != 0)
                writer.writeDouble(4, this.lowestPrice);
            if (this.startTime.length)
                writer.writeString(5, this.startTime);
            if (this.endTime.length)
                writer.writeString(6, this.endTime);
            if (this.instId.length)
                writer.writeString(7, this.instId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InstrumentCandleInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InstrumentCandleInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.openPrice = reader.readDouble();
                        break;
                    case 2:
                        message.closePrice = reader.readDouble();
                        break;
                    case 3:
                        message.highestPrice = reader.readDouble();
                        break;
                    case 4:
                        message.lowestPrice = reader.readDouble();
                        break;
                    case 5:
                        message.startTime = reader.readString();
                        break;
                    case 6:
                        message.endTime = reader.readString();
                        break;
                    case 7:
                        message.instId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): InstrumentCandleInfo {
            return InstrumentCandleInfo.deserialize(bytes);
        }
    }
    export class FindInstrumentCandleInfosR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            startTime?: string;
            endTime?: string;
            interval?: number;
            instId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
                if ("interval" in data && data.interval != undefined) {
                    this.interval = data.interval;
                }
                if ("instId" in data && data.instId != undefined) {
                    this.instId = data.instId;
                }
            }
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set startTime(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set endTime(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get interval() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set interval(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get instId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set instId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            startTime?: string;
            endTime?: string;
            interval?: number;
            instId?: string;
        }): FindInstrumentCandleInfosR {
            const message = new FindInstrumentCandleInfosR({});
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            if (data.interval != null) {
                message.interval = data.interval;
            }
            if (data.instId != null) {
                message.instId = data.instId;
            }
            return message;
        }
        toObject() {
            const data: {
                startTime?: string;
                endTime?: string;
                interval?: number;
                instId?: string;
            } = {};
            if (this.startTime != null) {
                data.startTime = this.startTime;
            }
            if (this.endTime != null) {
                data.endTime = this.endTime;
            }
            if (this.interval != null) {
                data.interval = this.interval;
            }
            if (this.instId != null) {
                data.instId = this.instId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.startTime.length)
                writer.writeString(1, this.startTime);
            if (this.endTime.length)
                writer.writeString(2, this.endTime);
            if (this.interval != 0)
                writer.writeInt64(3, this.interval);
            if (this.instId.length)
                writer.writeString(4, this.instId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindInstrumentCandleInfosR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindInstrumentCandleInfosR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.startTime = reader.readString();
                        break;
                    case 2:
                        message.endTime = reader.readString();
                        break;
                    case 3:
                        message.interval = reader.readInt64();
                        break;
                    case 4:
                        message.instId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindInstrumentCandleInfosR {
            return FindInstrumentCandleInfosR.deserialize(bytes);
        }
    }
    export class FindInstrumentCandleInfosA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            infos?: InstrumentCandleInfo[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("infos" in data && data.infos != undefined) {
                    this.infos = data.infos;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get infos() {
            return pb_1.Message.getRepeatedWrapperField(this, InstrumentCandleInfo, 2) as InstrumentCandleInfo[];
        }
        set infos(value: InstrumentCandleInfo[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            infos?: ReturnType<typeof InstrumentCandleInfo.prototype.toObject>[];
        }): FindInstrumentCandleInfosA {
            const message = new FindInstrumentCandleInfosA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.infos != null) {
                message.infos = data.infos.map(item => InstrumentCandleInfo.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                infos?: ReturnType<typeof InstrumentCandleInfo.prototype.toObject>[];
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.infos != null) {
                data.infos = this.infos.map((item: InstrumentCandleInfo) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.infos.length)
                writer.writeRepeatedMessage(2, this.infos, (item: InstrumentCandleInfo) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FindInstrumentCandleInfosA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FindInstrumentCandleInfosA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.infos, () => pb_1.Message.addToRepeatedWrapperField(message, 2, InstrumentCandleInfo.deserialize(reader), InstrumentCandleInfo));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FindInstrumentCandleInfosA {
            return FindInstrumentCandleInfosA.deserialize(bytes);
        }
    }
    export class GetLatestInstrumentCandleInfoR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            instId?: string;
            interval?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("instId" in data && data.instId != undefined) {
                    this.instId = data.instId;
                }
                if ("interval" in data && data.interval != undefined) {
                    this.interval = data.interval;
                }
            }
        }
        get instId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set instId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get interval() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set interval(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            instId?: string;
            interval?: number;
        }): GetLatestInstrumentCandleInfoR {
            const message = new GetLatestInstrumentCandleInfoR({});
            if (data.instId != null) {
                message.instId = data.instId;
            }
            if (data.interval != null) {
                message.interval = data.interval;
            }
            return message;
        }
        toObject() {
            const data: {
                instId?: string;
                interval?: number;
            } = {};
            if (this.instId != null) {
                data.instId = this.instId;
            }
            if (this.interval != null) {
                data.interval = this.interval;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.instId.length)
                writer.writeString(1, this.instId);
            if (this.interval != 0)
                writer.writeInt64(2, this.interval);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetLatestInstrumentCandleInfoR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetLatestInstrumentCandleInfoR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.instId = reader.readString();
                        break;
                    case 2:
                        message.interval = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetLatestInstrumentCandleInfoR {
            return GetLatestInstrumentCandleInfoR.deserialize(bytes);
        }
    }
    export class GetLatestInstrumentCandleInfoA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            info?: InstrumentCandleInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("info" in data && data.info != undefined) {
                    this.info = data.info;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get info() {
            return pb_1.Message.getWrapperField(this, InstrumentCandleInfo, 2) as InstrumentCandleInfo;
        }
        set info(value: InstrumentCandleInfo) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_info() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            info?: ReturnType<typeof InstrumentCandleInfo.prototype.toObject>;
        }): GetLatestInstrumentCandleInfoA {
            const message = new GetLatestInstrumentCandleInfoA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.info != null) {
                message.info = InstrumentCandleInfo.fromObject(data.info);
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                info?: ReturnType<typeof InstrumentCandleInfo.prototype.toObject>;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.info != null) {
                data.info = this.info.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.has_info)
                writer.writeMessage(2, this.info, () => this.info.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetLatestInstrumentCandleInfoA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetLatestInstrumentCandleInfoA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        reader.readMessage(message.info, () => message.info = InstrumentCandleInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetLatestInstrumentCandleInfoA {
            return GetLatestInstrumentCandleInfoA.deserialize(bytes);
        }
    }
    export class EnableUserN extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            enable?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("enable" in data && data.enable != undefined) {
                    this.enable = data.enable;
                }
            }
        }
        get enable() {
            return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
        }
        set enable(value: boolean) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            enable?: boolean;
        }): EnableUserN {
            const message = new EnableUserN({});
            if (data.enable != null) {
                message.enable = data.enable;
            }
            return message;
        }
        toObject() {
            const data: {
                enable?: boolean;
            } = {};
            if (this.enable != null) {
                data.enable = this.enable;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.enable != false)
                writer.writeBool(1, this.enable);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EnableUserN {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EnableUserN();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.enable = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): EnableUserN {
            return EnableUserN.deserialize(bytes);
        }
    }
}
