export const XXTEA_KEY = 'kP/qg.v|!J7a=Utb';
export const baseUrl = {
    // host: 'ws://139.224.69.242:29203', // test webscokt
    // host: '18.132.100.158',  // idn
    // host: '139.224.69.242', // dev
    // host: '18.169.50.92', // php
    host: 'wss://18.132.100.158:29203',
    loginport: 29100,
    port: 29200,
    walletBase: '',
}