/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.27.2
 * source: msg_error.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace msg {
    export enum ErrCode {
        NoErr = 0,
        ERR_SystemErr = 1,
        ERR_InvalidIP = 2,
        ERR_WrongVerifyCode = 3,
        ERR_InvalidAccessToken = 4,
        ERR_RepeatedOrderNo = 5,
        ERR_SignError = 6,
        ERR_InvalidMerNo = 7,
        ERR_TransferNotAllow = 8,
        ERR_TradeLimitPerRound = 1001,
        ERR_UnilateralPoolAbnormal = 1002,
        ERR_TradeClosed = 1003,
        ERR_BetAmountInvalid = 1004,
        ERR_NotEnoughMoney = 1005,
        ERR_NotLiveUser = 1006,
        ERR_RepeatedEmail = 2001,
        ERR_RepeatedPhoneNumber = 2002,
        ERR_PassNotMatch = 2003,
        ERR_AccountNotExist = 2004,
        ERR_UserNotExist = 2005,
        ERR_EmailOrPhoneNotConsistent = 2006,
        ERR_EmailOrPhoneNotExist = 2007,
        ERR_InvalidAreaCode = 2008
    }
}
