import { lazy } from "react";
import LayoutFrame from "../views/layout/Layout";
import { Navigate } from "react-router-dom";
const HomePage = lazy(() => import("../views/home/Home"));
const TakeTradePage = lazy(() => import("../views/takeTrade/TakeTrade"));
const NoFoundPage = lazy(() => import("../views/noFound/NoFound"));
const IFramePage = lazy(() => import("../views/iframe/IFramePage"));
const TestCharts = lazy(() => import("../views/testCharts/TestCharts"));
const TestLinePage = lazy(() => import("../views/klinepage/TestLine"));

const routes = [
    {
        path: "/",
        element: <LayoutFrame />,
        children: [
            {
                path: '/',
                element: <Navigate to={'trade'} />
            },
            {
                path: "/trade",
                element: <HomePage />,
            },
            {
                path: "/takeTrade",
                element: <TakeTradePage />,
            },
            {
                path: "/iframe",
                element: <IFramePage />,
            },
            {
                path: "/testcharts",
                element: <TestCharts />,
            },
            {
                path: "/testline",
                element: <TestLinePage />,
            },
            {
                path: "/404",
                element: <NoFoundPage />,
            },
            {
                path: '*',
                element: <NoFoundPage />,
            }
        ],
    },
];

export default routes;
