/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.27.2
 * source: msg_type.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace msg {
    export enum MsgType {
        TheHeartBeat = 0,
        TheHelloTestR = 1,
        TheHelloTestA = 2,
        TheUserInfoR = 3,
        TheUserInfoA = 4,
        TheBitxInfo = 5,
        TheSendBitxInfoN = 6,
        TheInstrument = 7,
        TheSendInstrumentN = 8,
        TheTradeBtcxR = 9,
        TheTradeBtxA = 10,
        TheTradeBtcxSettlementN = 11,
        TheUser = 12,
        TheTradeInfo = 13,
        ThePagination = 14,
        TheCloseTradeN = 15,
        TheOpenTradeN = 16,
        TheTradeConfig = 17,
        TheGetTradeConfigR = 18,
        TheGetTradeConfigA = 19,
        TheTradeBtcxStartN = 20,
        TheFindBtcxHistoryR = 21,
        TheFindBtcxHistoryA = 22,
        TheFindInstrumentHistoryR = 23,
        TheFindInstrumentHistoryA = 24,
        TheFindTradeInfosR = 25,
        TheFindTradeInfosA = 26,
        TheFindHoldingOrdersR = 27,
        TheFindHoldingOrdersA = 28,
        ThePersonalInfo = 29,
        TheSubmitPersonalInfoR = 30,
        TheSubmitPersonalInfoA = 31,
        TheResidenceInfo = 32,
        TheSubmitResidenceInfoR = 33,
        TheSubmitResidenceInfoA = 34,
        TheRevenueInfo = 35,
        TheSubmitRevenueInfoR = 36,
        TheSubmitRevenueInfoA = 37,
        TheSearchAvailableCountryR = 38,
        TheSearchAvailableCountryA = 39,
        TheSearchCountryIdentityInfoR = 40,
        TheSearchCountryIdentityInfoA = 41,
        TheGetPersonalInfoHandlerR = 42,
        TheGetPersonalInfoHandlerA = 43,
        TheGetResidenceInfoHandlerR = 44,
        TheGetResidenceInfoHandlerA = 45,
        TheGetRevenueInfoHandlerR = 46,
        TheGetRevenueInfoHandlerA = 47,
        TheBindAccountR = 48,
        TheBindAccountA = 49,
        TheChangeLoginPasswordR = 50,
        TheChangeLoginPasswordA = 51,
        TheSendVerifyCodeInHallR = 52,
        TheSendVerifyCodeInHallA = 53,
        TheResendVerifyCodeInHallR = 54,
        TheResendVerifyCodeInHallA = 55,
        TheVerifyCodeInHallR = 56,
        TheVerifyCodeInHallA = 57,
        TheSearchAvailableCurrencyR = 58,
        TheSearchAvailableCurrencyA = 59,
        TheMaintenanceN = 60,
        TheTestDepositR = 61,
        TheTestDepositA = 62,
        TheWithdrawResult = 63,
        TheRegisterGuideN = 64,
        ThePurchaseN = 65,
        TheApplyWithdrawReq = 66,
        TheApplyWithdrawR = 67,
        TheApplyWithdrawA = 68,
        TheWithdrawN = 69,
        TheFinishGuideR = 70,
        TheFinishGuideA = 71,
        TheGetExchangeRateR = 72,
        TheGetExchangeRateA = 73,
        TheWithdrawOrder = 74,
        TheFindWithdrawOrdersR = 75,
        TheFindWithdrawOrdersA = 76,
        TheChannelPayType = 77,
        TheFindChannelPayTypesR = 78,
        TheFindChannelPayTypesA = 79,
        TheWithdrawBank = 80,
        TheFindWithdrawBanksR = 81,
        TheFindWithdrawBanksA = 82,
        TheBtcxCandleInfo = 83,
        TheFindBtcxCandleInfosR = 84,
        TheFindBtcxCandleInfosA = 85,
        TheLatestBtcxCandleInfoN = 86,
        TheGetLatestBtcxCandleInfoR = 87,
        TheGetLatestBtcxCandleInfoA = 88,
        TheSwitchLiveDemoR = 89,
        TheSwitchLiveDemoA = 90,
        TheInstrumentCandleInfo = 91,
        TheFindInstrumentCandleInfosR = 92,
        TheFindInstrumentCandleInfosA = 93,
        TheGetLatestInstrumentCandleInfoR = 94,
        TheGetLatestInstrumentCandleInfoA = 95,
        TheEnableUserN = 96,
        TheCheckUserIPR = 1000,
        TheCheckUserIPA = 1001,
        TheStartRegisterR = 1002,
        TheStartRegisterA = 1003,
        TheVerifyCodeR = 1004,
        TheVerifyCodeA = 1005,
        TheResendVerifyCodeR = 1006,
        TheResendVerifyCodeA = 1007,
        TheSetLoginPasswordR = 1008,
        TheSetLoginPasswordA = 1009,
        TheLoginR = 1010,
        TheLoginA = 1011,
        TheAutoLoginR = 1012,
        TheAutoLoginA = 1013,
        TheResetLoginPasswordR = 1014,
        TheResetLoginPasswordA = 1015,
        TheGuestLoginR = 1016,
        TheGuestLoginA = 1017
    }
}
