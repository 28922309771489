import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cn from "./locales/cn.json";
import en from "./locales/en.json";
import idn from "./locales/idn.json";

const resources = {
    en: {
        translation: en,
    },
    zh: {
        translation: cn,
    },
    idn: {
        translation: idn,
    },
};

i18n.use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
    });

export default i18n;
