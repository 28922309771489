import { makeAutoObservable } from "mobx";
import { msg as msg_hall } from "../tools/proto/msg_hall_client";
import { getUserDataToServer, sentBtcxHistoryToServer, sentGetTradeConfigToServer } from "../api/requst";
import wsStores from "./wsStores";

export interface IUserStoresType {
    getTradeConfigInfo(data: any): unknown;
    setUserReceivedData(data: any): unknown;
    getGuestUserInfo(data: any): unknown;
    getUesrIdData(data: any): unknown;
    isUserEnableTrade(data: any):unknown
    demoDeposit(data: any): unknown;
}

class UserStores implements IUserStoresType {
    public guestLoginInfo: any = null;
    public userInfo: any = null;
    public tradeConfig: any = null
    public codeError: number = 0
    public demoAmount: number = 0
    public realAmount: number = 0
    private userId: number = 0
    public accountState: string = "real"
    public isHasTradeState: boolean = true;

    constructor() {
        makeAutoObservable(this);
    }

    changeAccountState = (state: string) => {
        this.accountState = state
    }

    changeDemoAmount = (amount: number) => {
        if (!amount) return
        this.demoAmount = amount
    }

    changeRealAmount = (amount: number) => {
        if (!amount) return
        this.realAmount = amount
    }

    public setUserReceivedData = async (payload?: any) => {
        if (!payload) {
            this.userInfo = null;
        }
        const data = msg_hall.UserInfoA.deserialize(payload).toObject();
        console.log({ data });
        if (data.errCode === 0) {
            this.userInfo = data.user;
            if (data.user?.isDemo) {
                this.demoAmount = Number(data.user?.demoAmount || 0);
            } else {
                this.realAmount = Number(data.user?.amount || 0);
            }
            this.isHasTradeState = data.user?.enable || false
            await sentGetTradeConfigToServer()
            await sentBtcxHistoryToServer({count: 2000})
            wsStores.startHeartbeat()
        }
    };

    public getGuestUserInfo = async (payload: any) => {
        if (!payload) {
            return;
        }
        await getUserDataToServer({id: payload});
    };

    public getTradeConfigInfo = async (payload: any) => {
        if (!payload) {
            return;
        }
        const res = msg_hall.GetTradeConfigA.deserialize(payload).toObject();
        if (res) {
            this.tradeConfig = res.tradeConfig
        }
    };

    public getUesrIdData = async (payload: any) => {
        if (!payload) {
            return;
        }
        const res = msg_hall.SwitchLiveDemoA.deserialize(payload).toObject();
        console.log('switchUserAcStatus-->:', res)
        if (res.errCode === 0 && res.userId) {
            this.userId = res.userId
            this.getGuestUserInfo(res.userId)
            this.changeAccountState(this.accountState === 'real' ? 'demo' : 'real')
        }
    };

    public isUserEnableTrade = async (payload: any) => {
        if (!payload) {
            return;
        }
        const res = msg_hall.EnableUserN.deserialize(payload).toObject();
        this.isHasTradeState = res.enable || false;
        this.userInfo = {...this.userInfo, enable: res.enable}
    };

    public changeTradeState = async (payload: any) => {
        if (!this.userInfo.enable) {
            this.isHasTradeState = false;
            return
        }
        this.isHasTradeState = payload || false;
    }

    public demoDeposit = async (payload: any) => {
        if (!payload) {
            return;
        }
        const res = msg_hall.TestDepositA.deserialize(payload).toObject();
        console.log('res---------demoDeposit:', res)
        if (res.errCode === 0) {
            this.demoAmount = res.curAmount || 0
        }
    };
}

const userStores = new UserStores();
export default userStores;
