import wsStores from "../stores/wsStores";
import { msg as msg_type } from "../tools/proto/msg_type";
import { msg as msg_hall } from "../tools/proto/msg_hall_client";
import { msg as msg_login } from "../tools/proto/msg_login_client";

export const sendHeartBeat = () => {
    const params = {
        bodybufs: new msg_hall.HeartBeat({}).serialize(),
        code: msg_type?.MsgType?.TheHeartBeat,
    };
    wsStores.sendMessage(params);
};

export const heartBeatParams = {
    bodybufs: new msg_hall.HeartBeat({}).serialize(),
    code: msg_type?.MsgType?.TheHeartBeat,
};

/**
 * 跳过登录-游客
 * @param params
 */
export const sendGuestLoginParams = (params: any = {}) => {
    console.log("跳过登录-游客");
    return {
        bodybufs: new msg_login.GuestLoginR(params).serialize(),
        code: msg_type?.MsgType?.TheGuestLoginR,
    };
};
export const sendGuestLoginToServer = async (params: any = {}) => {
    console.log("跳过登录-游客");
    const serverParams = {
        bodybufs: new msg_login.GuestLoginR(params).serialize(),
        code: msg_type?.MsgType?.TheGuestLoginR,
    };
    await wsStores.sendMessage(serverParams);
};

/**
 * 获取用户信息
 * @param params
 */
export const getUserDataToServer = async (params: any = {}) => {
    console.log("=====ddd:", params);
    const userParams = {
        bodybufs: new msg_hall.UserInfoR({
            id: params.id,
            afId: "",
        }).serialize(),
        code: msg_type?.MsgType?.TheUserInfoR,
    };
    await wsStores.sendMessage(userParams);
};
/**
 * 获取交易配置
 * @param params
 */
export const sentGetTradeConfigToServer = async (params: any = {}) => {
    const getTradeConfigParams = {
        bodybufs: new msg_hall.GetTradeConfigR(params).serialize(),
        code: msg_type?.MsgType?.TheGetTradeConfigR,
    };
    await wsStores.sendMessage(getTradeConfigParams);
};

/**
 * 历史记录---图
 */
export const sentBtcxHistoryToServer = async (params: any = {}) => {
    console.log("历史记录---图--21");
    const serverParams = {
        bodybufs: new msg_hall.FindBtcxHistoryR(params).serialize(),
        code: msg_type?.MsgType?.TheFindBtcxHistoryR,
    };
    await wsStores.sendMessage(serverParams);
};

/**
 * 历史记录---图(7个产品)
 */
export const sentInstrumentHistoryToServer = async (params: any = {}) => {
    console.log("历史记录---图--23");
    const serverParams = {
        bodybufs: new msg_hall.FindInstrumentHistoryR(params).serialize(),
        code: msg_type?.MsgType?.TheFindInstrumentHistoryR,
    };
    await wsStores.sendMessage(serverParams);
};

/**
 * 下单
 * @param params
 */
export const sendTradeBtcxToServer = async (params: any = {}) => {
    console.log("下单---图");
    const tradeParams = {
        bodybufs: new msg_hall.TradeBtcxR(params).serialize(),
        code: msg_type?.MsgType?.TheTradeBtcxR,
    };
    await wsStores.sendMessage(tradeParams);
};

/**
 * k线数据
 * @param params
 */
export const sendBtcxCandleInfosToServer = async (params: any = {}) => {
    console.log("K线历史数据", params);
    const tradeParams = {
        bodybufs: new msg_hall.FindBtcxCandleInfosR(params).serialize(),
        code: msg_type?.MsgType?.TheFindBtcxCandleInfosR,
    };
    await wsStores.sendMessage(tradeParams);
};

/**
 * 心跳
 * @param params
 */
export const sendHeartbeatToServer = async (params: any = {}) => {
    console.log("K线历史数据", params);
    const tradeParams = {
        bodybufs: new msg_hall.HeartBeat(params).serialize(),
        code: msg_type?.MsgType?.TheHeartBeat,
    };
    await wsStores.sendMessage(tradeParams);
};

/**
 * k线图实时数据拉取
 * @param params
 */
export const sendBitxCandleInfoToServer = async (params: any = {}) => {
    // console.log('K线实时数据', params)
    const tradeParams = {
        bodybufs: new msg_hall.GetLatestBtcxCandleInfoR(params).serialize(),
        code: msg_type?.MsgType?.TheGetLatestBtcxCandleInfoR,
    };
    await wsStores.sendMessage(tradeParams);
};

export const sendSwitchAcToServer = async (params: any = {}) => {
    const userparams = {
        bodybufs: new msg_hall.SwitchLiveDemoR(params).serialize(),
        code: msg_type?.MsgType?.TheSwitchLiveDemoR,
    };
    await wsStores.sendMessage(userparams);
};

/**
 * k huilv
 */
export const sendInstrumentCandleRecordToServer = async (params: any = {}) => {
    console.log("K线汇率历史数据", params);
    const tradeParams = {
        bodybufs: new msg_hall.FindInstrumentCandleInfosR(params).serialize(),
        code: msg_type?.MsgType?.TheFindInstrumentCandleInfosR,
    };
    await wsStores.sendMessage(tradeParams);
};

export const sendTestDepositToServer = async (params: any = {}) => {
    console.log('统计钱包按钮--61---new demo Amount top-up')
    const serverParams = {
        bodybufs: new msg_hall.TestDepositR(params).serialize(),
        code: msg_type?.MsgType?.TheTestDepositR
    }
    // 将数据发送给服务器
    await wsStores.sendMessage(serverParams);
}