import { makeAutoObservable } from "mobx";

class ErrorCodeStores {
    public errorCode: number = 0
    constructor() {
        makeAutoObservable(this);
    }

    setErrCode = (errCode: number) => {
        this.errorCode = errCode;
    };
}

const errorCodeStores = new ErrorCodeStores();
export default errorCodeStores;