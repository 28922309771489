import React from "react";
import { RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import ThemeProvider from "./ThemeProvider";
import i18n from "../../tools/language";
import router from "../../links";
import rootStores from "../../stores";

export const StroeContext = React.createContext<any>(null);

const StoreProvider: React.FC = () => {
    const stores = React.useMemo(() => rootStores, []);
    return (
        <StroeContext.Provider value={stores}>
            {/* <ThemeProvider defaultTheme="system" storageKey="theme">
                <I18nextProvider i18n={i18n}>
                    <RouterProvider router={router} />
                </I18nextProvider>
            </ThemeProvider> */}
            <I18nextProvider i18n={i18n}>
                <RouterProvider router={router} />
            </I18nextProvider>
        </StroeContext.Provider>
    );
};

export default StoreProvider;
