/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.27.2
 * source: msg_login_client.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./msg_error";
import * as pb_1 from "google-protobuf";
export namespace msg {
    export class CheckUserIPR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): CheckUserIPR {
            const message = new CheckUserIPR({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CheckUserIPR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CheckUserIPR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): CheckUserIPR {
            return CheckUserIPR.deserialize(bytes);
        }
    }
    export class CheckUserIPA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): CheckUserIPA {
            const message = new CheckUserIPA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CheckUserIPA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CheckUserIPA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): CheckUserIPA {
            return CheckUserIPA.deserialize(bytes);
        }
    }
    export class StartRegisterR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            email?: string;
            phone?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
            }
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            email?: string;
            phone?: string;
        }): StartRegisterR {
            const message = new StartRegisterR({});
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            return message;
        }
        toObject() {
            const data: {
                email?: string;
                phone?: string;
            } = {};
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.email.length)
                writer.writeString(1, this.email);
            if (this.phone.length)
                writer.writeString(2, this.phone);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StartRegisterR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StartRegisterR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.email = reader.readString();
                        break;
                    case 2:
                        message.phone = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): StartRegisterR {
            return StartRegisterR.deserialize(bytes);
        }
    }
    export class StartRegisterA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): StartRegisterA {
            const message = new StartRegisterA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StartRegisterA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StartRegisterA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): StartRegisterA {
            return StartRegisterA.deserialize(bytes);
        }
    }
    export class VerifyCodeR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            code?: string;
            email?: string;
            phone?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("code" in data && data.code != undefined) {
                    this.code = data.code;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
            }
        }
        get code() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set code(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            code?: string;
            email?: string;
            phone?: string;
        }): VerifyCodeR {
            const message = new VerifyCodeR({});
            if (data.code != null) {
                message.code = data.code;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            return message;
        }
        toObject() {
            const data: {
                code?: string;
                email?: string;
                phone?: string;
            } = {};
            if (this.code != null) {
                data.code = this.code;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.code.length)
                writer.writeString(1, this.code);
            if (this.email.length)
                writer.writeString(2, this.email);
            if (this.phone.length)
                writer.writeString(3, this.phone);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): VerifyCodeR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new VerifyCodeR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.code = reader.readString();
                        break;
                    case 2:
                        message.email = reader.readString();
                        break;
                    case 3:
                        message.phone = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): VerifyCodeR {
            return VerifyCodeR.deserialize(bytes);
        }
    }
    export class VerifyCodeA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): VerifyCodeA {
            const message = new VerifyCodeA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): VerifyCodeA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new VerifyCodeA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): VerifyCodeA {
            return VerifyCodeA.deserialize(bytes);
        }
    }
    export class ResendVerifyCodeR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            email?: string;
            phone?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
            }
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            email?: string;
            phone?: string;
        }): ResendVerifyCodeR {
            const message = new ResendVerifyCodeR({});
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            return message;
        }
        toObject() {
            const data: {
                email?: string;
                phone?: string;
            } = {};
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.email.length)
                writer.writeString(1, this.email);
            if (this.phone.length)
                writer.writeString(2, this.phone);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResendVerifyCodeR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResendVerifyCodeR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.email = reader.readString();
                        break;
                    case 2:
                        message.phone = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ResendVerifyCodeR {
            return ResendVerifyCodeR.deserialize(bytes);
        }
    }
    export class ResendVerifyCodeA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): ResendVerifyCodeA {
            const message = new ResendVerifyCodeA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResendVerifyCodeA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResendVerifyCodeA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ResendVerifyCodeA {
            return ResendVerifyCodeA.deserialize(bytes);
        }
    }
    export class SetLoginPasswordR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            password?: string;
            email?: string;
            phone?: string;
            afId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("password" in data && data.password != undefined) {
                    this.password = data.password;
                }
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("afId" in data && data.afId != undefined) {
                    this.afId = data.afId;
                }
            }
        }
        get password() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set password(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get afId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set afId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            password?: string;
            email?: string;
            phone?: string;
            afId?: string;
        }): SetLoginPasswordR {
            const message = new SetLoginPasswordR({});
            if (data.password != null) {
                message.password = data.password;
            }
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.afId != null) {
                message.afId = data.afId;
            }
            return message;
        }
        toObject() {
            const data: {
                password?: string;
                email?: string;
                phone?: string;
                afId?: string;
            } = {};
            if (this.password != null) {
                data.password = this.password;
            }
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.afId != null) {
                data.afId = this.afId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.password.length)
                writer.writeString(1, this.password);
            if (this.email.length)
                writer.writeString(2, this.email);
            if (this.phone.length)
                writer.writeString(3, this.phone);
            if (this.afId.length)
                writer.writeString(4, this.afId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SetLoginPasswordR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SetLoginPasswordR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.password = reader.readString();
                        break;
                    case 2:
                        message.email = reader.readString();
                        break;
                    case 3:
                        message.phone = reader.readString();
                        break;
                    case 4:
                        message.afId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SetLoginPasswordR {
            return SetLoginPasswordR.deserialize(bytes);
        }
    }
    export class SetLoginPasswordA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("userId" in data && data.userId != undefined) {
                    this.userId = data.userId;
                }
                if ("hallsvrIp" in data && data.hallsvrIp != undefined) {
                    this.hallsvrIp = data.hallsvrIp;
                }
                if ("hallsvrPort" in data && data.hallsvrPort != undefined) {
                    this.hallsvrPort = data.hallsvrPort;
                }
                if ("accessToken" in data && data.accessToken != undefined) {
                    this.accessToken = data.accessToken;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get userId() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set userId(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get hallsvrIp() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set hallsvrIp(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get hallsvrPort() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set hallsvrPort(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get accessToken() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set accessToken(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }): SetLoginPasswordA {
            const message = new SetLoginPasswordA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.userId != null) {
                message.userId = data.userId;
            }
            if (data.hallsvrIp != null) {
                message.hallsvrIp = data.hallsvrIp;
            }
            if (data.hallsvrPort != null) {
                message.hallsvrPort = data.hallsvrPort;
            }
            if (data.accessToken != null) {
                message.accessToken = data.accessToken;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                userId?: number;
                hallsvrIp?: string;
                hallsvrPort?: number;
                accessToken?: string;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.userId != null) {
                data.userId = this.userId;
            }
            if (this.hallsvrIp != null) {
                data.hallsvrIp = this.hallsvrIp;
            }
            if (this.hallsvrPort != null) {
                data.hallsvrPort = this.hallsvrPort;
            }
            if (this.accessToken != null) {
                data.accessToken = this.accessToken;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.userId != 0)
                writer.writeUint64(2, this.userId);
            if (this.hallsvrIp.length)
                writer.writeString(3, this.hallsvrIp);
            if (this.hallsvrPort != 0)
                writer.writeInt32(4, this.hallsvrPort);
            if (this.accessToken.length)
                writer.writeString(5, this.accessToken);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SetLoginPasswordA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SetLoginPasswordA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.userId = reader.readUint64();
                        break;
                    case 3:
                        message.hallsvrIp = reader.readString();
                        break;
                    case 4:
                        message.hallsvrPort = reader.readInt32();
                        break;
                    case 5:
                        message.accessToken = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SetLoginPasswordA {
            return SetLoginPasswordA.deserialize(bytes);
        }
    }
    export class LoginR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            email?: string;
            phone?: string;
            password?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("password" in data && data.password != undefined) {
                    this.password = data.password;
                }
            }
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get password() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set password(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            email?: string;
            phone?: string;
            password?: string;
        }): LoginR {
            const message = new LoginR({});
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.password != null) {
                message.password = data.password;
            }
            return message;
        }
        toObject() {
            const data: {
                email?: string;
                phone?: string;
                password?: string;
            } = {};
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.password != null) {
                data.password = this.password;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.email.length)
                writer.writeString(1, this.email);
            if (this.phone.length)
                writer.writeString(2, this.phone);
            if (this.password.length)
                writer.writeString(3, this.password);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LoginR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LoginR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.email = reader.readString();
                        break;
                    case 2:
                        message.phone = reader.readString();
                        break;
                    case 3:
                        message.password = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): LoginR {
            return LoginR.deserialize(bytes);
        }
    }
    export class LoginA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("userId" in data && data.userId != undefined) {
                    this.userId = data.userId;
                }
                if ("hallsvrIp" in data && data.hallsvrIp != undefined) {
                    this.hallsvrIp = data.hallsvrIp;
                }
                if ("hallsvrPort" in data && data.hallsvrPort != undefined) {
                    this.hallsvrPort = data.hallsvrPort;
                }
                if ("accessToken" in data && data.accessToken != undefined) {
                    this.accessToken = data.accessToken;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get userId() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set userId(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get hallsvrIp() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set hallsvrIp(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get hallsvrPort() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set hallsvrPort(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get accessToken() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set accessToken(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }): LoginA {
            const message = new LoginA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.userId != null) {
                message.userId = data.userId;
            }
            if (data.hallsvrIp != null) {
                message.hallsvrIp = data.hallsvrIp;
            }
            if (data.hallsvrPort != null) {
                message.hallsvrPort = data.hallsvrPort;
            }
            if (data.accessToken != null) {
                message.accessToken = data.accessToken;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                userId?: number;
                hallsvrIp?: string;
                hallsvrPort?: number;
                accessToken?: string;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.userId != null) {
                data.userId = this.userId;
            }
            if (this.hallsvrIp != null) {
                data.hallsvrIp = this.hallsvrIp;
            }
            if (this.hallsvrPort != null) {
                data.hallsvrPort = this.hallsvrPort;
            }
            if (this.accessToken != null) {
                data.accessToken = this.accessToken;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.userId != 0)
                writer.writeUint64(2, this.userId);
            if (this.hallsvrIp.length)
                writer.writeString(3, this.hallsvrIp);
            if (this.hallsvrPort != 0)
                writer.writeInt32(4, this.hallsvrPort);
            if (this.accessToken.length)
                writer.writeString(5, this.accessToken);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LoginA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LoginA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.userId = reader.readUint64();
                        break;
                    case 3:
                        message.hallsvrIp = reader.readString();
                        break;
                    case 4:
                        message.hallsvrPort = reader.readInt32();
                        break;
                    case 5:
                        message.accessToken = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): LoginA {
            return LoginA.deserialize(bytes);
        }
    }
    export class AutoLoginR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            accessToken?: string;
            loginReal?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("accessToken" in data && data.accessToken != undefined) {
                    this.accessToken = data.accessToken;
                }
                if ("loginReal" in data && data.loginReal != undefined) {
                    this.loginReal = data.loginReal;
                }
            }
        }
        get accessToken() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set accessToken(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get loginReal() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set loginReal(value: boolean) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            accessToken?: string;
            loginReal?: boolean;
        }): AutoLoginR {
            const message = new AutoLoginR({});
            if (data.accessToken != null) {
                message.accessToken = data.accessToken;
            }
            if (data.loginReal != null) {
                message.loginReal = data.loginReal;
            }
            return message;
        }
        toObject() {
            const data: {
                accessToken?: string;
                loginReal?: boolean;
            } = {};
            if (this.accessToken != null) {
                data.accessToken = this.accessToken;
            }
            if (this.loginReal != null) {
                data.loginReal = this.loginReal;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.accessToken.length)
                writer.writeString(1, this.accessToken);
            if (this.loginReal != false)
                writer.writeBool(2, this.loginReal);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AutoLoginR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AutoLoginR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.accessToken = reader.readString();
                        break;
                    case 2:
                        message.loginReal = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): AutoLoginR {
            return AutoLoginR.deserialize(bytes);
        }
    }
    export class AutoLoginA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("userId" in data && data.userId != undefined) {
                    this.userId = data.userId;
                }
                if ("hallsvrIp" in data && data.hallsvrIp != undefined) {
                    this.hallsvrIp = data.hallsvrIp;
                }
                if ("hallsvrPort" in data && data.hallsvrPort != undefined) {
                    this.hallsvrPort = data.hallsvrPort;
                }
                if ("accessToken" in data && data.accessToken != undefined) {
                    this.accessToken = data.accessToken;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get userId() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set userId(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get hallsvrIp() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set hallsvrIp(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get hallsvrPort() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set hallsvrPort(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get accessToken() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set accessToken(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }): AutoLoginA {
            const message = new AutoLoginA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.userId != null) {
                message.userId = data.userId;
            }
            if (data.hallsvrIp != null) {
                message.hallsvrIp = data.hallsvrIp;
            }
            if (data.hallsvrPort != null) {
                message.hallsvrPort = data.hallsvrPort;
            }
            if (data.accessToken != null) {
                message.accessToken = data.accessToken;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                userId?: number;
                hallsvrIp?: string;
                hallsvrPort?: number;
                accessToken?: string;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.userId != null) {
                data.userId = this.userId;
            }
            if (this.hallsvrIp != null) {
                data.hallsvrIp = this.hallsvrIp;
            }
            if (this.hallsvrPort != null) {
                data.hallsvrPort = this.hallsvrPort;
            }
            if (this.accessToken != null) {
                data.accessToken = this.accessToken;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.userId != 0)
                writer.writeUint64(2, this.userId);
            if (this.hallsvrIp.length)
                writer.writeString(3, this.hallsvrIp);
            if (this.hallsvrPort != 0)
                writer.writeInt32(4, this.hallsvrPort);
            if (this.accessToken.length)
                writer.writeString(5, this.accessToken);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AutoLoginA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AutoLoginA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.userId = reader.readUint64();
                        break;
                    case 3:
                        message.hallsvrIp = reader.readString();
                        break;
                    case 4:
                        message.hallsvrPort = reader.readInt32();
                        break;
                    case 5:
                        message.accessToken = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): AutoLoginA {
            return AutoLoginA.deserialize(bytes);
        }
    }
    export class ResetLoginPasswordR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            email?: string;
            phone?: string;
            newPassword?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("email" in data && data.email != undefined) {
                    this.email = data.email;
                }
                if ("phone" in data && data.phone != undefined) {
                    this.phone = data.phone;
                }
                if ("newPassword" in data && data.newPassword != undefined) {
                    this.newPassword = data.newPassword;
                }
            }
        }
        get email() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set email(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get phone() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set phone(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get newPassword() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set newPassword(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            email?: string;
            phone?: string;
            newPassword?: string;
        }): ResetLoginPasswordR {
            const message = new ResetLoginPasswordR({});
            if (data.email != null) {
                message.email = data.email;
            }
            if (data.phone != null) {
                message.phone = data.phone;
            }
            if (data.newPassword != null) {
                message.newPassword = data.newPassword;
            }
            return message;
        }
        toObject() {
            const data: {
                email?: string;
                phone?: string;
                newPassword?: string;
            } = {};
            if (this.email != null) {
                data.email = this.email;
            }
            if (this.phone != null) {
                data.phone = this.phone;
            }
            if (this.newPassword != null) {
                data.newPassword = this.newPassword;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.email.length)
                writer.writeString(1, this.email);
            if (this.phone.length)
                writer.writeString(2, this.phone);
            if (this.newPassword.length)
                writer.writeString(3, this.newPassword);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResetLoginPasswordR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResetLoginPasswordR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.email = reader.readString();
                        break;
                    case 2:
                        message.phone = reader.readString();
                        break;
                    case 3:
                        message.newPassword = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ResetLoginPasswordR {
            return ResetLoginPasswordR.deserialize(bytes);
        }
    }
    export class ResetLoginPasswordA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
        }): ResetLoginPasswordA {
            const message = new ResetLoginPasswordA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ResetLoginPasswordA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ResetLoginPasswordA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ResetLoginPasswordA {
            return ResetLoginPasswordA.deserialize(bytes);
        }
    }
    export class GuestLoginR extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            deviceId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("deviceId" in data && data.deviceId != undefined) {
                    this.deviceId = data.deviceId;
                }
            }
        }
        get deviceId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set deviceId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            deviceId?: string;
        }): GuestLoginR {
            const message = new GuestLoginR({});
            if (data.deviceId != null) {
                message.deviceId = data.deviceId;
            }
            return message;
        }
        toObject() {
            const data: {
                deviceId?: string;
            } = {};
            if (this.deviceId != null) {
                data.deviceId = this.deviceId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.deviceId.length)
                writer.writeString(1, this.deviceId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GuestLoginR {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GuestLoginR();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.deviceId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GuestLoginR {
            return GuestLoginR.deserialize(bytes);
        }
    }
    export class GuestLoginA extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("errCode" in data && data.errCode != undefined) {
                    this.errCode = data.errCode;
                }
                if ("userId" in data && data.userId != undefined) {
                    this.userId = data.userId;
                }
                if ("hallsvrIp" in data && data.hallsvrIp != undefined) {
                    this.hallsvrIp = data.hallsvrIp;
                }
                if ("hallsvrPort" in data && data.hallsvrPort != undefined) {
                    this.hallsvrPort = data.hallsvrPort;
                }
                if ("accessToken" in data && data.accessToken != undefined) {
                    this.accessToken = data.accessToken;
                }
            }
        }
        get errCode() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_1.msg.ErrCode.NoErr) as dependency_1.msg.ErrCode;
        }
        set errCode(value: dependency_1.msg.ErrCode) {
            pb_1.Message.setField(this, 1, value);
        }
        get userId() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set userId(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get hallsvrIp() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set hallsvrIp(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get hallsvrPort() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set hallsvrPort(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get accessToken() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set accessToken(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            errCode?: dependency_1.msg.ErrCode;
            userId?: number;
            hallsvrIp?: string;
            hallsvrPort?: number;
            accessToken?: string;
        }): GuestLoginA {
            const message = new GuestLoginA({});
            if (data.errCode != null) {
                message.errCode = data.errCode;
            }
            if (data.userId != null) {
                message.userId = data.userId;
            }
            if (data.hallsvrIp != null) {
                message.hallsvrIp = data.hallsvrIp;
            }
            if (data.hallsvrPort != null) {
                message.hallsvrPort = data.hallsvrPort;
            }
            if (data.accessToken != null) {
                message.accessToken = data.accessToken;
            }
            return message;
        }
        toObject() {
            const data: {
                errCode?: dependency_1.msg.ErrCode;
                userId?: number;
                hallsvrIp?: string;
                hallsvrPort?: number;
                accessToken?: string;
            } = {};
            if (this.errCode != null) {
                data.errCode = this.errCode;
            }
            if (this.userId != null) {
                data.userId = this.userId;
            }
            if (this.hallsvrIp != null) {
                data.hallsvrIp = this.hallsvrIp;
            }
            if (this.hallsvrPort != null) {
                data.hallsvrPort = this.hallsvrPort;
            }
            if (this.accessToken != null) {
                data.accessToken = this.accessToken;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.errCode != dependency_1.msg.ErrCode.NoErr)
                writer.writeEnum(1, this.errCode);
            if (this.userId != 0)
                writer.writeUint64(2, this.userId);
            if (this.hallsvrIp.length)
                writer.writeString(3, this.hallsvrIp);
            if (this.hallsvrPort != 0)
                writer.writeInt32(4, this.hallsvrPort);
            if (this.accessToken.length)
                writer.writeString(5, this.accessToken);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GuestLoginA {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GuestLoginA();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.errCode = reader.readEnum();
                        break;
                    case 2:
                        message.userId = reader.readUint64();
                        break;
                    case 3:
                        message.hallsvrIp = reader.readString();
                        break;
                    case 4:
                        message.hallsvrPort = reader.readInt32();
                        break;
                    case 5:
                        message.accessToken = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GuestLoginA {
            return GuestLoginA.deserialize(bytes);
        }
    }
}
