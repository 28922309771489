import {decrypt, encrypt} from './xxtea'
import { XXTEA_KEY } from '../../config/constant';

// 混淆方法定义
const c1 = 3 << 6; // 03000000
const c2 = 3 << 4; // 00030000
const c3 = 3 << 2; // 00000300
const c4 = 3; // 00000003

export const protoCodeToBytes = (code: number) => {
    // if (!code ||) {
    //     return new Uint8Array(0);
    // }
    const bytes = new ArrayBuffer(2);
    const dataViews = new DataView(bytes);
    dataViews.setUint16(0, code, true);
    const u8bytes = new Uint8Array(bytes);
    return u8bytes
}

export const bytesToProtoCode = (uint8Array: Uint8Array, len: number = 4) => {
    if (!uint8Array.length) {
      throw new Error('Uint8Array must have');
    }
    const dataView = new DataView(uint8Array.buffer);
    // 从 ArrayBuffer 读取十进制数字
    const code = len === 4 ? dataView.getInt32(0, true) : dataView.getInt16(0, true);
    return code;
}

export const numberToByteArray = (value: number): Uint8Array => {
    if (!value) {
        return new Uint8Array(4);
    }
    const bytes = new Uint8Array(4);
    bytes[0] = value & 0xff;
    bytes[1] = (value >> 8) & 0xff;
    bytes[2] = (value >> 16) & 0xff;
    bytes[3] = (value >> 24) & 0xff;
    return bytes;
};

export const formatSendBufHead = (pbbuf: Uint8Array): Uint8Array => {
    if (!pbbuf) {
        return new Uint8Array(8);
    }
    const bufLen = pbbuf.length;
    const arrayBuf: Uint8Array = new Uint8Array(8);
    const headbuf: Uint8Array = numberToByteArray(bufLen);
    // console.log('----->:', headbuf);
    headbuf.forEach((el, index) => {
        arrayBuf[index + 2] = el;
    });

    // 混淆简单算法
    arrayBuf[0] =
        (arrayBuf[2] & c1) +
        (arrayBuf[3] & c2) +
        (arrayBuf[4] & c3) +
        (arrayBuf[5] & c4);
    arrayBuf[1] =
        (arrayBuf[3] & c1) +
        (arrayBuf[4] & c2) +
        (arrayBuf[5] & c3) +
        (arrayBuf[2] & c4);
    arrayBuf[6] =
        (arrayBuf[4] & c1) +
        (arrayBuf[5] & c2) +
        (arrayBuf[2] & c3) +
        (arrayBuf[3] & c4);
    arrayBuf[7] =
        (arrayBuf[5] & c1) +
        (arrayBuf[2] & c2) +
        (arrayBuf[3] & c3) +
        (arrayBuf[4] & c4);
    return arrayBuf;
};

export const parseGetData = (pbbuf: Uint8Array) => {
    if (!pbbuf || !pbbuf.length) {
        return {};
    }

    const bytes = new Uint8Array(pbbuf);

    const head0 =
        (bytes[2] & c1) + (bytes[3] & c2) + (bytes[4] & c3) + (bytes[5] & c4);
    const head1 =
        (bytes[3] & c1) + (bytes[4] & c2) + (bytes[5] & c3) + (bytes[2] & c4);
    const head6 =
        (bytes[4] & c1) + (bytes[5] & c2) + (bytes[2] & c3) + (bytes[3] & c4);
    const head7 =
        (bytes[5] & c1) + (bytes[2] & c2) + (bytes[3] & c3) + (bytes[4] & c4);

    if (
        bytes[0] !== head0 ||
        bytes[1] !== head1 ||
        bytes[6] !== head6 ||
        bytes[7] !== head7
    ) {
        return;
    }
    const encoder = new TextEncoder()
    const bodybufs = decrypt(
        bytes.subarray(8),
        encoder.encode(XXTEA_KEY),
    );
    if (!bodybufs || !bodybufs.length) {
        return {};
    }
    const nocodebuf = new Uint8Array(bodybufs.subarray(0, 2))
    const nocode = bytesToProtoCode(nocodebuf, 2)
    // const apikey = enumValToKey(msgType.MsgType, nocode)
    const data = new Uint8Array(bodybufs?.subarray(2))
    return {
        // key: apikey,
        code: nocode,
        data: data,
    };
};

export const formatSendData = (message: any): Uint8Array => {
    if (!message) {
        return new Uint8Array(0);
    }
    const {bodybufs, code} = message
    const codeBufs = protoCodeToBytes(code)
    const newBodyBufs = new Uint8Array(codeBufs.length + bodybufs.length)
    newBodyBufs.set(codeBufs, 0)
    newBodyBufs.set(bodybufs, codeBufs.length)
    const encoder = new TextEncoder()
    const encrypted = encrypt(
        newBodyBufs,
        encoder.encode(XXTEA_KEY)
    )
    const headerBuf = formatSendBufHead(encrypted)
    const sendBufs = new Uint8Array(headerBuf.length + encrypted.length);
    sendBufs.set(headerBuf, 0);
    sendBufs.set(encrypted, headerBuf.length);
    return sendBufs
}