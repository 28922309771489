import rootStores from "../../stores";
import {msg as msg_type} from '../proto/msg_type'

const setReceivedData = (data: any) => {
    // console.log('收到数据:', data)
    if (!data)
        return
    switch (data.code) {
        case msg_type.MsgType.TheHeartBeat:
            console.log('-----------心跳')
            // rootStores.userStore.setUserInfo(data.data)
            break;
        case msg_type.MsgType.TheGuestLoginA:
            console.log('----------游客登录')
            rootStores.userStores.getGuestUserInfo(data.data)
            break;
        case msg_type.MsgType.TheUserInfoA:
            console.log('----------获取用户信息')
            rootStores.userStores.setUserReceivedData(data.data)
            break;
        case msg_type.MsgType.TheFindBtcxHistoryA:
            console.log('----------历史记录-图-trend')
            rootStores.tradeStores.getTrendRecordData(data.data);
            break;
        case msg_type.MsgType.TheGetTradeConfigA:
            console.log('----------交易界面配置信息');
            rootStores.userStores.getTradeConfigInfo(data.data);
            break;
        case msg_type.MsgType.TheSendBitxInfoN:
            // console.log('----------趋势图bitx实时数据');
            rootStores.tradeStores.getBitxInfoData(data.data);
            break;
        case msg_type.MsgType.TheSendInstrumentN:
            // console.log('----------趋势图huilv实时数据');
            rootStores.tradeStores.getInstrumentData(data.data);
            break;
        case msg_type.MsgType.TheFindInstrumentHistoryA: // 历史记录-图-7个产品
            console.log('.........历史记录-图-7个产品')
            rootStores.tradeStores.getInstrumentRecordData(data.data);
            break;
        case msg_type.MsgType.TheTradeBtxA:
            console.log('.........下单交易');
            rootStores.tradeStores.getTradeBtxData(data.data);
            break;
        case msg_type.MsgType.TheTradeBtcxStartN:
            console.log('.........下单交易点数据');
            rootStores.tradeStores.getTradeBtcxStartData(data.data);
            break;
        case msg_type.MsgType.TheTradeBtcxSettlementN:
            console.log('.........下单交易结果');
            rootStores.tradeStores.getTradeBtcxSettlementData(data.data);
            break;
        case msg_type.MsgType.TheFindBtcxCandleInfosA:
            console.log('.........k线图-历史记录');
            rootStores.tradeStores.getBitxCandleRecordData(data.data);
            break;
        case msg_type.MsgType.TheGetLatestBtcxCandleInfoA:
            // console.log('.........k线图实时记录');
            rootStores.tradeStores.getBitxCandleInfoData(data.data);
            break;
        case msg_type.MsgType.TheSwitchLiveDemoA:
            console.log('.........切换账户状态');
            rootStores.userStores.getUesrIdData(data.data);
            break;
        case msg_type.MsgType.TheFindInstrumentCandleInfosA:
            console.log('.........K线汇率历史数据');
            rootStores.tradeStores.getInstrumentCandleRecordData(data.data);
            break;
        case msg_type.MsgType.TheEnableUserN:
            console.log('.........控制不能下单');
            rootStores.userStores.isUserEnableTrade(data.data);
            break;
        case msg_type.MsgType.TheTestDepositA:
            console.log('.........demo充值');
            rootStores.userStores.demoDeposit(data.data);
            break;
    }
}

export default setReceivedData;