import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const addThousandSeprator = (num: string) => {
    return parseFloat(num)
        .toFixed(2)
        .toString()
        .split(".")
        .map((x, idx) => {
            if (!idx) {
                return x
                    .split("")
                    .reverse()
                    .map((xx, idxx) => (idxx && !(idxx % 3) ? xx + "," : xx))
                    .reverse()
                    .join("");
            } else {
                return x;
            }
        })
        .join(".");
};

export const formatTwoDecimal = (num: string | number) => {
    const numStr = num.toString();
    if (numStr.indexOf(".") !== -1) {
        const decimalPart = numStr.split(".")[1].slice(0, 2);
        return numStr.split(".")[0] + "." + decimalPart;
    }
    return Number(numStr).toFixed(2);
};

export const formatDataBySecond = (hisData: any) => {
    if (!hisData || !hisData.length) {
        return []
    }
    const hislists = hisData.reduce((cur: any, next: any) => {
        const time = Math.floor(next.timestamp / 1000) * 1000;
        // const time = Math.floor(next.timestamp / 1000);
        const index = cur.findIndex((item: any) => item.time === time);
        if (index === -1) {
            cur.push({
                time,
                value: next.price
            });
        } else {
            cur[index].value = next.price;
        }
        return cur;
    }, [])
    return hislists
}

export const formatCandleHisTimeInterval = (interval: number) => {
    console.log('----------:', interval)
    if (!interval) return
    let tt = 0, startTime = '', endTime = '';
    // console.log('--====------:', dayjs().startOf('minute').format('YYYY-MM-DD HH:mm:00'))
    switch (interval) {
        case 1:
            tt = Date.now()
            endTime = `${dayjs.utc(tt).format('YYYY-MM-DD HH:mm')}:00`
            startTime = `${dayjs.utc(tt - 7000 * 1000 * 60).format('YYYY-MM-DD HH')}:00:00`
            break
        case 5:
            tt = Date.now()
            endTime = dayjs.utc(tt).format('YYYY-MM-DD HH:mm:ss')
            startTime = dayjs.utc(tt - 7000 * 1000 * 5 * 60).format('YYYY-MM-DD HH:mm:ss')
            break
        case 15:
            tt = Date.now()
            endTime = dayjs.utc(tt).format('YYYY-MM-DD HH:mm:ss')
            startTime = dayjs.utc(tt - 7000 * 1000 * 15 * 60).format('YYYY-MM-DD HH:mm:ss')
            break
        case 60:
            tt = Date.now()
            endTime = dayjs.utc(tt).format('YYYY-MM-DD HH:mm:ss')
            startTime = dayjs.utc(tt - 7000 * 1000 * 60 * 60).format('YYYY-MM-DD HH:mm:ss')
            break
        case 240:
            tt = Date.now()
            endTime = dayjs.utc(tt).format('YYYY-MM-DD HH:mm:ss')
            startTime = dayjs.utc(tt - 7000 * 1000 * 60 * 60 * 4).format('YYYY-MM-DD HH:mm:ss')
            break
        case 1440:
            tt = Date.now()
            endTime = dayjs.utc(tt).format('YYYY-MM-DD HH:mm:ss')
            startTime = dayjs.utc(tt - 7000 * 1000 * 60 * 60 * 24).format('YYYY-MM-DD HH:mm:ss')
            break
        default:
            tt = Date.now()
            endTime = dayjs.utc(tt).format('YYYY-MM-DD HH:mm:ss')
            startTime = dayjs.utc(tt - 7000 * 1000 * 60).format('YYYY-MM-DD HH:mm:ss')
            break
    }
    console.log({startTime, endTime, interval})
    return {startTime, endTime, interval}
}

export const formatNumberToSixDigits = (number: number): string => {
    const formattedNumber = number.toString();
    const [integerPart, decimalPart] = formattedNumber.split('.');
    const len = 6 - integerPart.length;
    // const formattedDecimalPart = decimalPart.padEnd(
    //     6 - integerPart.length,
    //     '0',
    // );
    return number.toFixed(len);
    // return `${integerPart}.${formattedDecimalPart}`;
};