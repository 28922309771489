import { protoCodeToBytes, formatSendBufHead, bytesToProtoCode, parseGetData, formatSendData } from "./protoTools";
import { encrypt } from './xxtea'
import { XXTEA_KEY } from "../../config/constant";
import { AccurateInterval } from "./timer/customInterval";
import setReceivedData from "./receivedData";
import { msg as msg_hall } from "../proto/msg_hall_client";
import {msg as msg_type} from "../proto/msg_type";

interface WebSocketWrapperOptions {
    reconnectInterval?: number;
    heartbeatInterval?: number;
    maxReconnectAttempts?: number;
    [key: string]: any;
}

type WebSocketMessage = {
    type?: string;
    [key: string]: any;
};

class WebSocketWrapper {
    private url: string = "";
    private options: Required<WebSocketWrapperOptions>;
    private ws: WebSocket | null = null;
    private isConnected: boolean = false;
    private reconnectTimeout: number | undefined = undefined;
    private heartbeatInterval: number | undefined = undefined;
    private messageListeners: Set<(message: WebSocketMessage) => void> =
        new Set();
    private reconnectAttempts: number = 0;
    private incompleteBuffer: Uint8Array = new Uint8Array(0);
    private HEADER_LENGTH: number = 8;
    private isHandStop: boolean = false
    constructor(options: any = {}) {
        this.options = {
            reconnectInterval: 5000,
            heartbeatInterval: 30000,
            maxReconnectAttempts: 3,
            ...options,
        };
    }

    public connect = (wsurl: string) => {
        return new Promise((resolve, reject) => {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                this.disConnect()
                console.log('WebSocket connectioned')
            }
            this.url = wsurl;

            this.ws = new WebSocket(wsurl);
            this.ws.binaryType = 'arraybuffer';
            // this.startHeartbeat();
            // const type = this.ws.binaryType;
            // console.log('00==000====:',{type})

            this.ws.onopen = () => {
                console.log("WebSocket connection established.");
                this.isConnected = true;
                this.reconnectAttempts = 0;
                // this.startHeartbeat();
                resolve("ok");
            };

            // this.ws.binaryType = 'arraybuffer';
            this.ws.onmessage = (event) => {
                // console.log("WebSocket message received:", event.data);
                const data = event.data;
                const resData = new Uint8Array(data);
                const resBufs = new Uint8Array(
                    this.incompleteBuffer.length + resData.length
                );
                resBufs.set(this.incompleteBuffer, 0);
                resBufs.set(resData, this.incompleteBuffer.length);
                this.incompleteBuffer = resBufs
                while (this.incompleteBuffer.length >= this.HEADER_LENGTH) {
                    const header = this.incompleteBuffer.subarray(2, 6);
                    const headerPacket = new Uint8Array(header);
                    const packetLen = bytesToProtoCode(headerPacket);
                    if ( this.incompleteBuffer.length >= this.HEADER_LENGTH + packetLen ) {
                        const packet = new Uint8Array(
                            this.incompleteBuffer.subarray(
                                0,
                                this.HEADER_LENGTH + packetLen,
                            ),
                        );
                        const resData: any = parseGetData(packet);
                        setReceivedData(resData);
                        this.messageListeners.forEach(listener => listener(resData));
                        this.incompleteBuffer = new Uint8Array(
                            this.incompleteBuffer.subarray(
                                this.HEADER_LENGTH + packetLen,
                            )
                        );
                    } else {
                        console.log('数据不足以解包，等待更多数据')
                        break;
                    }
                }
            };
            this.ws.onclose = () => {
                console.log("WebSocket disconnected");
                this.isConnected = false;
                if (!this.isHandStop) {
                    this.reconnect();
                }
                // reject('closed')
            };
            this.ws.onerror = (error) => {
                console.error("WebSocket error:", error);
                reject(error);
            };
        });
    };

    public wsInstance = () => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN && this.isConnected) {
            return this.ws;
        }
    };
    private reconnect = () => {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }
        if (this.reconnectAttempts < this.options.maxReconnectAttempts) {
            this.reconnectAttempts++;
            this.reconnectTimeout = setTimeout(() => {
                console.log("Attempting to reconnect...");
                this.connect(this.url).catch(() => this.reconnect());
            }, this.options.reconnectInterval) as unknown as number;
        } else {
            console.log("Max reconnect attempts reached.");
            this.disConnect()
        }
    };
    public startHeartbeat = () => {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
        }
        this.heartbeatInterval = setInterval(() => {
            const heartParams = {
                bodybufs: new msg_hall.HeartBeat({}).serialize(),
                code: msg_type?.MsgType?.TheHeartBeat,
            };
            this.send(heartParams)
        }, this.options.heartbeatInterval) as unknown as number;
    };

    public closeHeartbeat = () => {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
        }
    };

    public send = (message: WebSocketMessage) => {
        return new Promise((resolve, reject) => {
            if (!this.isConnected || !this.ws) {
                reject(new Error("WebSocket is not connected"));
                return;
            }
            const sendBufs = formatSendData(message);
            this.ws.send(sendBufs.buffer);
            resolve('ok');
        });
    };

    public connectedState = () => {
        return !!this.ws && this.isConnected
    }
    private onMessage(
        callback: (message: WebSocketMessage) => void
    ): () => void {
        this.messageListeners.add(callback);
        return () => this.messageListeners.delete(callback);
    }

    public disConnect = () => {
        return new Promise((resolve, reject) => {
            if (this.ws) {
                clearTimeout(this.reconnectTimeout);
                clearInterval(this.heartbeatInterval);
                this.ws.close();
                this.isHandStop = true;
                this.ws.onclose = () => {
                    this.isConnected = false;
                    console.log("WebSocket manually disconnected");
                    resolve("close");
                };
                
            } else {
                resolve("close");
            }
        });
    };
}

const webSocketWrapper = new WebSocketWrapper();
export type { WebSocketWrapperOptions, WebSocketMessage };
export default webSocketWrapper;