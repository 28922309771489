import { makeAutoObservable } from "mobx";
import webSocketWrapper from "../tools/utils/pipe";
import { sendHeartbeatToServer } from "../api/requst";
import { baseUrl } from "../config/constant";

export interface IWebSocketStoresType {}
class WebSocketStores implements IWebSocketStoresType {
    // private wsInts: any = null
    // private hbTimeout: any = null
    // private hbInterval: any = null
    constructor() {
        makeAutoObservable(this)
    }

    public initWs = async () => {
        const res = await webSocketWrapper.connect(baseUrl.host)
        if (res === 'ok') {
            console.log('ws已连接')
            // this.hbTimeout = setTimeout(() => {
            //     this.hbInterval =setInterval(() => {
            //         sendHeartbeatToServer()
            //     }, 2000)
                
            // }, 2000)
        }
        return res
    }
    public resetWsProtocolAddress = async (url: string) => {
        const res = await webSocketWrapper.disConnect()
        if (res === 'close') {
            const res = await webSocketWrapper.connect(url)
            if (res === 'ok') {
                console.log('reset connect ok')
            }
            return res
        }
    }
    public sendMessage = async (mes: any) => {
        const res = await webSocketWrapper.send(mes)
        if (res === 'ok') {
            // console.log('send message ok')
        }
        return res
    }

    // public handleMessage = (mes: string) => {}
    public closeWs = async () => {
        const res = await webSocketWrapper.disConnect()
        // if (this.hbTimeout) {
        //     clearTimeout(this.hbTimeout)
        // }
        if (res === 'close') {
            console.log('ws id close')
        }
        return res
    }

    public startHeartbeat = () => {
        webSocketWrapper.startHeartbeat()
    }

    public closeHeartbeat = () => {
        webSocketWrapper.closeHeartbeat()
    }
}

const wsStores = new WebSocketStores()
export default wsStores